import React, { useCallback, useState } from 'react';
import { ApiUrl, Defines } from '../../../project/Defines';
import InputCont from '../../../Shared/Form/InputCont';
import { T } from '../../../components/Translations';
import { PopupClasses, PopupTypes } from '../../../components/Popup';
import { closeAllPopups, openPopup } from '../../../store/actions';
import { connect, useDispatch } from 'react-redux';
import { dispatchCustomEvent, EVENT_NAME } from '../../../project/utilities';
import { Ajax } from '../../../components/Ajax';
import { message } from 'antd';

import { hideLoader, showLoader } from '../../../Shared/Loader';

function Product(props) {
    const { dataItem, disabled, onChangeData, setDataItem } = props;
    const listUpdateEvent = EVENT_NAME.SERVICE_CALL_LIST_UPDATE;
    const dispatch = useDispatch();

    const [detailProps] = useState({
        windowKey: 'wndEquipmentCardCreate',
        popupType: PopupTypes.EquipmentCardEdit,
        fullScreen: false,
        listUpdateEvent,
        internal_serial_number: dataItem?.internal_serial_number
    });
    const _data = {
        service_call_id: dataItem.ID ? dataItem.ID : "",
        City: dataItem.ville,
        CodeCom: "", //dataItem.code_product,
        Country: dataItem.pays,
        EndDate: dataItem.warranty_end_date,
        ExtensionDate: dataItem.warranty_extension_date,
        InsId: dataItem.ins_id,
        InternalSN: dataItem.internal_serial_number,
        ItemCode: dataItem.item_code,
        ItemName: dataItem.item_name,
        OwnerCode: '', // dataItem.code_client, // customer_code
        OwnerEmail: dataItem.email,
        OwnerName: dataItem.customer_name,
        OwnerPhone: dataItem.phone,
        OwnerPhone2: dataItem.phone2,
        StartDate: dataItem.warranty_start_date,
        Street: dataItem.adresse, // street + street number
        StreetNo: dataItem.street_number,
        Block: dataItem.door_number,
        Zip: dataItem.code_postal,
        Key: dataItem.ins_id,
        IsProduct: true
    } 

    const openEquipmentCard = useCallback(() => {
        // debugger;
        props.dispatch(openPopup({
          windowKey: "wndEquipmentCardCreate",
          title: <T>text.edit</T>,
          type: PopupTypes.EquipmentCardEdit,
          className: PopupClasses.Large,
          fullScreen: false,
          bodyProps: {detailProps, onClose: props.onClose, data: _data }
        }));
    }, [setDataItem, openPopup]);
    //setDataItem(item => ({ ...item, product_code: record.CodeCom, item_code: record.ItemCode, internal_serial_number: record.InternalSN, customer_code: record.OwnerCode }));

    const openFictiveSerialNumberPopup = useCallback((e) => {
        const confirmLoaderName = 'FictiveSerialNumber';
        dispatch(openPopup({
            windowKey: 'wndFictiveSerialNumberConfirm',
            type: PopupTypes.ConfirmFictiveSerialNumber,
            title: <T>message.confirm_fictive_serial_number</T>,
            //text: <T>message.delete_confirm</T>,
            buttonYesText: <T>text.yes</T>,
            buttonNoText: <T>text.no</T>,
            //noCallback: function () { alert("no") },
            yesCallback: function () {
                showLoader(confirmLoaderName);
                //onChangeData({ target: { name: 'fictive_serial_number', checked: e?.target.checked } })
                Ajax.post({
                    url: ApiUrl.ServiceCallUpdate,
                    data: { ...dataItem, fictive_serial_number: false},
                    success: function (response) {
                        if (response) {
                            dispatchCustomEvent(listUpdateEvent);
                            document.location.reload();
                        }
                        hideLoader(confirmLoaderName);
                    },
                    error: function (response) {
                        if (response.message) {
                            message.error(response.message);
                        }
                        hideLoader(confirmLoaderName);
                    }
                })

            }
        }));
    }, [openPopup, listUpdateEvent]);

    return (
        <div className='section_group'>
            <div className='sections'>
                <form>
                    <InputCont
                        label='text.serial_number'
                        inputProps={{
                            disabled,
                            name: 'internal_serial_number',
                            value: dataItem.internal_serial_number,
                            placeholder: "",
                            onChange: onChangeData
                        }}
                    />
                    <InputCont
                        label='text.product_code'
                        inputProps={{
                            disabled: true,
                            name: 'code_product',
                            value: dataItem.code_product,
                            placeholder: "",
                            //onChange: onChangeData
                        }}
                        icon={<div effect="material"
                            className="button mini " 
                            onClick={openEquipmentCard}
                        ><icon >edit</icon></div>}
                    />
                    <InputCont
                        label='text.product_name'
                        inputProps={{
                            disabled: true,
                            name: 'item_name',
                            value: dataItem.item_name,
                            placeholder: ""
                        }}
                    />
                    <div className='="sections'>
                        <label className="checkbox" classic="" style={{margin: '20px 11px', top: "50%", transform: "translateY(-50%)"}}>
                        <input
                            type="checkbox"
                            name="fictive_serial_number"
                            checked={dataItem.fictive_serial_number}
                            onChange={!dataItem.fictive_serial_number ? onChangeData : () => openFictiveSerialNumberPopup()}
                            //onChange={onChangeData} // onChangeData({ target: { name: 'fictive_serial_number', checked: e.target.checked } })
                        />
                        {/* {!dataItem.fictive_serial_number ? <input
                            type="checkbox"
                            name="fictive_serial_number"
                            checked={dataItem.fictive_serial_number}
                            onChange={onChangeData}
                            //onChange={(e) => !dataItem.fictive_serial_number && onChangeData({ target: { name: 'fictive_serial_number', checked: e } })}
                        /> : <input
                            type="checkbox"
                            name="fictive_serial_number"
                            checked={dataItem.fictive_serial_number}
                        />
                        } */}
                        <box><check></check></box><text><T>text.fictive_serial_number</T></text>
                        </label>
                    </div>
                </form>

            </div>
            <div className='sections'>
                <form>

                    <InputCont
                        label='text.warranty_start_date'
                        inputProps={{
                            disabled,
                            name: 'warranty_start_date',
                            type: 'date',
                            dataItem,
                            dataField: 'warranty_start_date',
                            format: Defines.Format.MomentDate,
                            placeholder: "", setDataItem
                        }}
                    />
                    <InputCont
                        label='text.warranty_end_date'
                        inputProps={{
                            disabled,
                            name: 'warranty_end_date',
                            type: 'date',
                            dataItem,
                            dataField: 'warranty_end_date',
                            format: Defines.Format.MomentDate,
                            placeholder: "", setDataItem
                        }}
                    />
                    <InputCont
                        label='text.warranty_extension_date'
                        inputProps={{
                            disabled,
                            name: 'warranty_extension_date',
                            type: 'date',
                            dataItem,
                            dataField: 'warranty_extension_date',
                            format: Defines.Format.MomentDate,
                            placeholder: "",
                            setDataItem
                        }}
                    />
                </form>
            </div>
        </div>
    );
}

export default connect(state => ({
    //isDispatcher: state.role === UserRoles.DISPATCHER,
    //isSat: state.role === UserRoles.USER,
    //culture: state.culture
  }), dispatch => ({ dispatch, openPopup: (props) => dispatch(openPopup(props)), closeAllPopups: () => dispatch(closeAllPopups()) }))(Product);