import { DatePicker } from "../DatePicker";
import React from "react";
import { useTranslation } from "react-i18next";
import { T } from '../../components/Translations';
import { DropDownValueList, MultiSelectValueList } from "../DropDown";
import { Input, Textarea } from "../Input";
import { ValidationDropDownValueList, ValidationInput, ValidationUpload } from "./validation";
import CustomUpload from "../CustomUpload";
// import moment from 'moment';

/**
 * template to put in forms
 * @param {any} props contains: label, labelIcon, icon, inputProps
 * @exports FormField
 */
export default function InputCont(props) {
  const { t } = useTranslation();
  // debugger
  const { label, skipTranslate, labelIcon, icon, iconClick, inputProps: { validation, /*allowClear,*/ type, placeholder, showTime, ...restInputProps } = {} } = props;
  let inputElement;
  if (placeholder) {
    restInputProps.placeholder = t(placeholder);
  }
  if (!restInputProps.onChange) {
    restInputProps.onChange = () => { };
  }

  switch (type) {
    case 'textarea':
      inputElement = validation ? <ValidationInput validation={validation} inputType='textarea' {...restInputProps} /> : <Textarea type={'textarea'}{...restInputProps} />;
      break;
    case 'select':
      inputElement = validation ? <ValidationDropDownValueList validation={validation}  {...restInputProps} /> : <DropDownValueList {...restInputProps} />;
      break;
    case 'multiselect':
      inputElement = validation ? <ValidationDropDownValueList validation={validation} mode={'multiple'} {...restInputProps} /> : <MultiSelectValueList {...restInputProps} />;
      break;
    case 'date':
      inputElement = validation ? <ValidationInput validation={validation} inputType={'date'}{...restInputProps} /> : <DatePicker {...restInputProps} />;
      break;
    case 'datetime':
      inputElement = validation ? <ValidationInput validation={validation} name={restInputProps.name || restInputProps.dataField} showTime={showTime || true} inputType={'date'}{...restInputProps} /> : <DatePicker showTime={showTime || true} {...restInputProps} />;
      break;
    case 'upload':
      inputElement = validation ? <ValidationUpload validation={validation} name={restInputProps.name} {...restInputProps} /> : <CustomUpload {...restInputProps} />;
      break;
    // case 'time':
    //     inputElement = <TimePicker name="tpAntd" value='' format=''/>
    //     break;

    default:
      inputElement = validation ? <ValidationInput validation={validation} inputType={type || 'text'} type={type || 'text'} {...restInputProps} /> : <Input type={type || 'text'}{...restInputProps} />;
      break;
  }

  return <cont>
    {inputElement}
    <decor>
      <label>
        <text>
          <line></line>
          <wrap>{labelIcon && <icon>{labelIcon}</icon>}{label && <span>{skipTranslate ? label : <T>{label}</T>}</span>}</wrap>
        </text>
        <line></line>
      </label>
      <border></border>
    </decor>
    {icon && (typeof icon === 'string' ? <div effect="material" className="button mini " onClick={iconClick}><icon>{icon}</icon></div> : icon)}

  </cont>;

}