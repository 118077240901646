import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuidv4 from 'uuid/v4';
import { Ajax } from '../../../components/Ajax';
import { PopupTypes } from '../../../components/Popup';
import { T } from '../../../components/Translations';
import { ApiUrl, UserRoles } from '../../../project/Defines';
import { Project } from '../../../project/Project';
import { dispatchCustomEvent, EVENT_NAME } from '../../../project/utilities';
import InputCont from '../../../Shared/Form/InputCont';
import { ValidationDropDownValueList, ValidationInput } from '../../../Shared/Form/validation';
import ListView from '../../../Shared/ListView';
import { COLUMN_TYPE } from '../../../Shared/ListView/helper';
import useDetail from '../../../Shared/useDetail';
import { openPopup } from '../../../store/actions';
import { hasValidCallData, isDocumentReadOnly, isLineReadOnly } from '../commonFunctions';

const listUpdateEvent = EVENT_NAME.FEE_LIST_UPDATE;
const reloadEvent = EVENT_NAME.FEE_DATA_RELOAD;

/**
 * the FeeDetail
 * @exports FeeDetail
 */
export default function FeeDetail(props) {
  const dispatch = useDispatch();
  const isDispatcher = useSelector(s => s.role) === UserRoles.DISPATCHER;
  const { data } = props;
  const { windowKey, readOnly } = props.detailProps;
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [distanceInfo, setDistanceInfo] = useState(null);
  const [distanceInfoSet, setDistanceInfoSet] = useState(false);
  const [_quantity, setQuantity] = useState();
  const [editingLine, setEditingLine] = useState();
  const isCreateNewMode = !data.DocNum;
  const isReadOnlyMode = readOnly || isDocumentReadOnly(data);
  const { callData } = hasValidCallData(data) ? data : { callData: { call_id: data.SCOrigin } };


  useEffect(() => {
    if (callData && callData.societe_uuid) {
      Ajax.get({
        url: ApiUrl.GetSatDistance,
        data: {
          service_call_id: callData.ID
        },
        success: function (response) {
          setDistanceInfo(response);
          setDistanceInfoSet(true);
        }
      });
    }
  }, []);//eslint-disable-line  react-hooks/exhaustive-deps

  const onLoadData = useCallback((response, setDataItem) => {
    Array.isArray(response) && setDataItem({
      ...data, orderLines: response.map((m, i) => ({ ...m, key: uuidv4(), ID: uuidv4() }))
    });
    return true;
  }, [data]);

  const { dataItem, setDataItem, updateData, detailRef, closePopup } = useDetail({
    data: data.DocNum ? data : { ...data, orderLines: [] },
    updateUrl: ApiUrl.FeeUpdate,
    dataLoadUrl: data.DocNum && ApiUrl.FeeLineLoad,
    dataLoadParams: { docNum: data.DocNum, callId: callData.ID },
    additionalData: { callID: callData.ID },
    onLoadData,
    listUpdateEvent,
    windowKey,
    reloadEvent
  });

  const updateSuccessCallback = useCallback((response) => {
    if (detailRef.current) {
      closePopup(windowKey);
    }
    dispatchCustomEvent(listUpdateEvent);
  }, [detailRef, closePopup, windowKey]);

  const onCustomSave = useCallback(({ editingRecord, record, setEditingRecord, validation }) => {
    if (!(validation && !validation.isValid)) {
      setDataItem({
        ...dataItem, orderLines: dataItem.orderLines.map(ol => ol.key === editingRecord.key ? { ...editingRecord, ID: editingRecord.key } : ol)
      });
      setEditingRecord(null);
    }
  }, [dataItem, setDataItem]);

  const onCustomCancel = useCallback((setEditingRecord) => {
    setDataItem({
      ...dataItem, orderLines: [...dataItem.orderLines.filter(ol => ol.ID !== 'new')]
    });
    setEditingRecord(null);
  }, [dataItem, setDataItem]);

  const onRemove = useCallback((record) => {
    setDataItem({
      ...dataItem, orderLines: [...dataItem.orderLines.filter(ol => ol.ID !== record.ID)]
    });
  }, [dataItem, setDataItem]);

  const onNewClick = useCallback((event, onEdit) => {
    const newData = { key: uuidv4(), ID: 'new', Quantity: 1 };
    setDataItem({ ...dataItem, orderLines: [newData, ...dataItem.orderLines.filter(ol => ol.ID !== 'new')] });
    onEdit(newData, null, event, false);
  }, [dataItem, setDataItem]);

  const onCancelLine = line => {
    dispatch(
      openPopup({
        type: PopupTypes.Confirm,
        title: <T>text.cancel</T>,
        text: <T> message.delete_confirm</T>,
        buttonYesText: <T>text.yes</T>,
        buttonNoText: <T>text.no</T>,
        yesCallback: () => {
          updateData((response) => {
            dispatchCustomEvent(reloadEvent);
            dispatchCustomEvent(listUpdateEvent);
          }, null, { additionalData: { canceledLine: line.LineNum } });
        }

      }));
  };

  const commands = [];
  !isReadOnlyMode && commands.push({ command: 'edit', skipCommandForRecord: isLineReadOnly });
  !isReadOnlyMode && !isCreateNewMode && commands.push({ command: 'remove', tooltip: 'text.cancel', skipCommandForRecord: isLineReadOnly, onClick: onCancelLine });
  isCreateNewMode && commands.push({ command: 'delete', onClick: onRemove });


  // const distance = distanceInfo?.text.slice(0, -3);
  // const _additionalData = { ID: callData.ID, distance: distance };

  const onChangeFee = (e, dataSource, editingRecord, setEditingRecord,) => {

    const item = dataSource?.find(x => x.value === e);
    // debugger;
    const distance = parseFloat(distanceInfo?.text);
    let quantity = null;

    if (typeof item.distance != "object" && distance > item.distance) {
      quantity = 2 * (10 * distance - 10 * item.distance) / 10;
    }

    setQuantity(quantity);
    setEditingRecord({ ...editingRecord, ItemCode: item.value, Quantity: quantity, Price: item.price, Description: item.description, Distance: item.distance, LineTotal: Project.round2Decimals(quantity * item.price) });
  };

  const onLineEdit = useCallback(editingRecord => {
    if (!isCreateNewMode && editingRecord !== null) {
      if (!editingLine) {
        setEditingLine(editingRecord);
        const distance = parseFloat(distanceInfo?.text);
        let quantity = null;

        if (editingRecord.Distance != null && distance > editingRecord.Distance) {
          quantity = 2 * (10 * distance - 10 * editingRecord.Distance) / 10;
        }
        setQuantity(quantity);
      }
    } else if (editingRecord) {
      setEditingLine(null);
    }
    setDisableSaveButton(editingRecord !== null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateNewMode, callData, distanceInfo, editingLine]);
  const columns = [
    {
      text: 'text.fee',
      noSort: 'true',
      field: 'ItemCode',
      type: COLUMN_TYPE.TEXT,
      render: (t, record) => {
        let text = [record.ItemCode, record.Price, typeof record.Description == "object" ? "" : record.Description, typeof record.Distance == "object" ? "" : record.Distance].filter(v => v).join(' - ');
        return <wrap title={text}>
          <text>{text}</text>
        </wrap>;
      },
      readOnly: !isCreateNewMode,
      editor: {
        customRenderer: ({ editingRecord, setEditingRecord, validation }) => {
          return <ValidationDropDownValueList
            validation={validation}
            name='ItemCode'
            value={editingRecord.ItemCode}
            valuelist='call_fees'
            parentValue={callData.ID}
            additionalData={distanceInfo ? `${distanceInfo?.value / 1000}` : ""}
            disabled={!distanceInfoSet}
            disableCach={true}
            onChange={(e, dataSource) => {
              onChangeFee(e, dataSource, editingRecord, setEditingRecord);
            }}
          />;
        }
      }
    },
    {
      text: 'text.quantity',
      noSort: 'true',
      field: 'Quantity',
      width: 90,
      type: COLUMN_TYPE.TEXT,
      editor: {
        customRenderer: ({ editingRecord, setEditingRecord, validation }) => {
          return <ValidationInput
            validation={validation}
            name='Quantity'
            type='number'
            inputType='number'
            min={0}
            max={_quantity}
            className='ant-input'
            value={editingRecord.Quantity}
            onChange={(e) => {
              let quantity = e || 1;
              setEditingRecord({ ...editingRecord, Quantity: quantity, LineTotal: Project.round2Decimals(quantity * editingRecord.Price) });
            }}
          />;
        }
      }
    },
    {
      readOnly: true,
      text: 'text.status',
      noSort: 'true',
      width: 120,
      field: 'LineStatus',
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.line_total',
      noSort: 'true',
      width: 90,
      field: 'LineTotal',
      type: COLUMN_TYPE.TEXT,
      editor: {
        customRenderer: ({ editingRecord }) => {
          return <wrap> <text>{editingRecord.LineTotal || ""}</text></wrap>;
        }
      }
    },
    {
      skip: !isDispatcher,
      readOnly: true,
      text: 'text.account',
      noSort: 'true',
      width: 120,
      field: 'Account',
      type: COLUMN_TYPE.TEXT
    },
    {
      skip: !isDispatcher,
      readOnly: true,
      text: 'text.business_unit',
      noSort: 'true',
      width: 120,
      field: 'BusinessUnit',
      type: COLUMN_TYPE.TEXT
    },
    {
      skip: !isDispatcher,
      readOnly: true,
      text: 'text.cost_center',
      noSort: 'true',
      width: 120,
      field: 'CostCenter',
      type: COLUMN_TYPE.TEXT
    },
    {
      skip: !isDispatcher,
      readOnly: true,
      text: 'text.destination',
      noSort: 'true',
      width: 120,
      field: 'Destination',
      type: COLUMN_TYPE.TEXT
    },
    {
      type: COLUMN_TYPE.ACTION,
      width: 100,
      commands,
      onCustomSave,
      onCustomCancel
    }
  ].filter(col => !col.skip);

  return (dataItem && dataItem.orderLines && < div className="form_container" >
    <view  >
      <form>
        <toolbar >
          <InputCont
            label='text.call_id'
            inputProps={{ disabled: true, name: 'call_id', value: callData.call_id }}
          />
          <InputCont
            label='text.supplier_code'
            inputProps={{ disabled: true, name: 'supplier_code', value: dataItem.CardCode || callData.supplier_code }}
          />
          <InputCont
            label='text.order_number'
            inputProps={{ disabled: true, name: 'DocNum', value: dataItem.DocNum }}
          />
          <InputCont
            label='text.distance'
            inputProps={{ disabled: true, name: 'distance', value: (distanceInfo && distanceInfo.text) || "n/a" }}
          />
        </toolbar>
        <ListView
          {...props}
          columnConfig={columns}
          keyField={isCreateNewMode ? "ID" : "LineNum"}
          noPaging
          dataSource={dataItem.orderLines}
          validationFields={['ItemCode', { name: 'Quantity', rules: [{ type: "number" }] }]}
          editable={!isReadOnlyMode}
          isChieldView
          onNewClick={onNewClick}
          onEditingRecordChange={onLineEdit}
          skipNewButton={!isCreateNewMode || isReadOnlyMode}
          skipEdit={isReadOnlyMode}
          isRecordReadOnly={isLineReadOnly}
        />
        {!isReadOnlyMode ? <toolbar>
          <wrap>
            <action right=''>
              {<div disabled={disableSaveButton || !(dataItem.orderLines && dataItem.orderLines.some(l => !isLineReadOnly(l)))} onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>}
            </action>
          </wrap>
        </toolbar> : <></>}

      </form>
    </view >

  </div >) || <></>;
}