import React, { useState, useEffect, useCallback } from 'react';
import { T } from '../../components/Translations';
import { COLUMN_FORMAT } from '../ListView/helper';

/**
 * the list view footer component, generates the footer for ListView based on props
 * @param {obj} props contains : dataSource, columnConfig, 
 * */
export default function FooterView(props) {
    const { items, columnConfig } = props;
    const [footerTotals, setFooterTotals] = useState(null);

    const setFooterTotalData = useCallback((data) => {
        let totals = {};
        data.forEach((element) => {
            for (var i = 0; i < columnConfig.length; i++) {
                let currentColumn = columnConfig[i];
                if (currentColumn.footerTitle && currentColumn.footerTitle !== 'total') {
                    let calc = element[currentColumn.field];
                    if (currentColumn.calculate) {
                        calc = currentColumn.calculate(element);
                    }
                    if (totals[currentColumn.field]) {
                        totals[currentColumn.field] += calc;
                    } else {
                        totals[currentColumn.field] = calc;
                    }
                }
            }
        });
        setFooterTotals(totals);
    }, [columnConfig]);

    useEffect(() => {
        setFooterTotalData(items);
    }, [items, setFooterTotalData])

    return <div className="ant-table ant-table-default">
        <table>
            <thead className="ant-table-thead">
                <tr>
                    {
                     columnConfig.map(item => (
                            <th key={item.field} style={{ width: item.width }}>
                                {
                                    !item.footerTitle ? ''
                                        :
                                        item.footerTitle === 'total'
                                            ?
                                            <wrap><text><T>text.total</T></text></wrap>
                                            :
                                            item.format === COLUMN_FORMAT.CURRENCY
                                                ?
                                                <wrap> <text> {footerTotals && footerTotals[item.field] ?
                                                 parseFloat(footerTotals[item.field]).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', }) : ""}
                                                </text></wrap>
                                                :
                                             <wrap> <text> {footerTotals && footerTotals[item.field] ? footerTotals[item.field] : ""} </text></wrap>
                                }
                            </th>
                        ))
                    }
                </tr>
            </thead>
        </table>
        <style>{`.ant-table-footer{padding: 0px;}`}</style>
    </div>;
}