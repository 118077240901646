import React from 'react';

export const EditableContext = React.createContext();
/**
 * used as the template of row in list view during inline edit
 * @param {any} props contains: index
 */
const EditableRow = ({ index, ...props }) => {
  //  const [form] = Form.useForm();
    return (
        //<Form form={form} component={false}>
        //    <EditableContext.Provider value={form}>
                <tr {...props} />
        //    </EditableContext.Provider>
        //</Form>
    );
};

export default EditableRow;