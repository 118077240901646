import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { ApiUrl, AppPages, Defines } from '../../../project/Defines';
import { EVENT_NAME } from '../../../project/utilities';
import ListView from '../../../Shared/ListView';
import { COLUMN_TYPE } from '../../../Shared/ListView/helper';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

/**
 * @exports History
 */
function History(props) {
  const { dataItem } = props;
  const { t } = useTranslation();
  // const isDispatcher = role === UserRoles.DISPATCHER;
  // const isCallCenter = role === UserRoles.CALL_CENTER;

  const columns = [
    {
      text: 'text.user',
      noSort: 'true',
      field: 'user_name',
      width: 300,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.call_id',
      noSort: 'true',
      //field: 'call_id',
      render: (text, record) => {
        let call_id = "";
        if (record.change_data !== "") {
          call_id = JSON.parse(record.change_data).call_id;
        }
        return (
          <wrap><text>{call_id}</text></wrap>
        );
      },
      width: 300,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.type',
      noSort: 'true',
      //field: 'what',
      width: 200,
      render: (text, record) => {
        let type = "";
        if (record.change_data !== "") {
          type = JSON.parse(record.change_data).type;
        }
        return (
          <wrap><text>{type}</text></wrap>
        );
      },
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.when',
      noSort: 'true',
      field: 'created_at',
      render: (text, record) => {
        let created_at = moment.utc(text).local().format(Defines.Format.MomentDateTime);
        //let created_at = moment(text, Defines.Format.MomentDateTime).format(Defines.Format.MomentDateTime);
        return (
          <wrap><text>{created_at}</text></wrap>
        );
      },
      //format: COLUMN_FORMAT.DATETIME,
      type: COLUMN_TYPE.TEXT
    }
  ];


  const expandedColumnConfig = [
    {
      text: 'text.field',
      noSort: 'true',
      //field: 'what',
      width: 250,
      render: (text, record) => {
        let fieldName = record?.field;
        return (
          <wrap>
            <text>{t('text.' + fieldName)}</text>
          </wrap>
        );
      },
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.old_value',
      noSort: 'true',
      //field: 'new_value',
      render: (text, record) => {
        return (
          <wrap>
            <text>{record?.old_value}</text>
          </wrap>
        );
      },
      width: 250,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.new_value',
      noSort: 'true',
      //field: 'new_value',
      render: (text, record) => {
        return (
          <wrap>
            <text>{record?.new_value}</text>
          </wrap>
        );
      },
      type: COLUMN_TYPE.TEXT
    }
  ];

  const onExpandedRowRender = useCallback((record) => {
    // if (!record.) return null;

    let dataSource = JSON.parse(record?.change_data)?.changedItem;
    return record.change_data && <wrap><ListView
      isSimple
      isChieldView
      noPaging
      skipEdit
      skipNewButton={true}
      expandedTable={true}
      columnConfig={expandedColumnConfig}
      pagination={false}
      dataSource={dataSource}
      // showHeader={false}
      style={{ "height": "auto", "paddingBottom": "20px", "paddingLeft": "40px" }}

    /></wrap>;
  }, [expandedColumnConfig]);// eslint-disable-line react-hooks/exhaustive-deps


  return (
    <ListView
      {...props}
      columnConfig={columns}
      apiUrl={ApiUrl.HistoryList}
      // filters={[]}
      // staticFilter={staticFilter}
      noSort
      noPaging
      skipNewButton
      noOverflow
      listUpdateEvent={EVENT_NAME.HISTORY_LIST_UPDATE}
      listName={AppPages.History}
      staticFilter={{ service_job_uuid: dataItem.ID }}
      //onLoaded={(data) => {
      //  return data.filter(d => {
      //    const _call_id = JSON.parse(d.change_data).call_id;
      //    return _call_id == dataItem.call_id;
      //  });
      //}}
      expandable={{
        defaultExpandAllRows: false,
        expandedRowRender: record => onExpandedRowRender(record)
        // rowExpandable: (record) => {
        //     return JSON.parse(record.change_data).changedItem.length > 1;
        // },
      }}
    />
  );
}


export default connect(state => ({
  role: state.role,
  canCreateCall: state.userData.canCreateCall
}))(History);