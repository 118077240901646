import React, { useCallback, useState } from 'react';
import { T } from '../../../components/Translations';
//import { Defines } from '../../../project/Defines';
import { ApiUrl } from '../../../project/Defines';
import InputCont from '../../../Shared/Form/InputCont';
import useDetail from '../../../Shared/useDetail';

const DefaultvalidationFields = ['defect_code2']; //, 'defect_code3', 'defect_code4'];

export default function DefectCodes(props) {
  const [validationFields, setValidationFields] = useState(DefaultvalidationFields);
  const { disabled } = props;
  const { dataItem, setDataItem, updateData, detailRef, validation } = useDetail({
    data: { ID: props.dataItem.ID, defect_code1: '', defect_code2: '', defect_code3: '', defect_code4: '' },
    dataLoadUrl: ApiUrl.DefectCodeDetail,
    dataLoadParams: { callID: props.dataItem.ID },
    updateUrl: ApiUrl.DefectCodeUpdate,
    additionalData: { callID: props.dataItem.ID },
    validationFields: validationFields,
    onLoadData: (response) => {
      if (!response.defect_code1)
        setValidationFields([]);
    }
  });

  const updateSuccessCallback = useCallback((response) => {
    if (detailRef.current) {
      setDataItem(response);
    }

  }, [setDataItem, detailRef]);

  const onCodeChanged = useCallback((field, value) => {
    switch (field) {
      case 'defect_code1':
        setDataItem({ ...dataItem, defect_code1: value ?? '', defect_code2: '', defect_code3: '', defect_code4: '' });
        if (value) {
          setValidationFields(DefaultvalidationFields);
        } else {
          setValidationFields([]);
        }
        break;
      case 'defect_code2':
        setDataItem({ ...dataItem, defect_code2: value ?? '', defect_code3: '', defect_code4: '' });
        break;
      case 'defect_code3':
        setDataItem({ ...dataItem, defect_code3: value ?? '', defect_code4: '' });
        break;
      case 'defect_code4':
        setDataItem({ ...dataItem, defect_code4: value ?? '' });
        break;
      default:
        break;
    }
  }, [dataItem, setDataItem]);
  return (
    <>
      {dataItem &&
        <>
          <div className='section_group'>
            <div className='sections '>

              <InputCont
                label='text.level_1'
                inputProps={{
                  disabled,
                  name: 'defect_code1',
                  type: 'select',
                  valuelist: 'defect_codes',
                  additionalData: '1',
                  parentValue: props?.dataItem?.business_unit||'',
                  disableCach: true,
                  value: dataItem.defect_code1,
                  onChange: e => onCodeChanged('defect_code1', e),
                  allowClear: true,
                }}
              />

            </div>
            <div className='sections '>

              <InputCont
                label='text.level_2'
                inputProps={{
                  name: 'defect_code2',
                  type: 'select',
                  valuelist: 'defect_codes',
                  additionalData: '2',
                  parentValue: dataItem.defect_code1,
                  disableCach: true,
                  disabled: disabled || !dataItem.defect_code1,
                  value: dataItem.defect_code2,
                  onChange: e => onCodeChanged('defect_code2', e),
                  allowClear: true,
                  validation: validation
                }}
              />

            </div>

            <div className='sections '>

              <InputCont
                label='text.level_3'
                inputProps={{
                  name: 'defect_code3',
                  type: 'select',
                  valuelist: 'defect_codes',
                  additionalData: '3',
                  parentValue: dataItem.defect_code2,
                  disableCach: true,
                  disabled: disabled || !dataItem.defect_code2,
                  value: dataItem.defect_code3,
                  onChange: e => onCodeChanged('defect_code3', e),
                  allowClear: true,
                  validation: validation
                }}
              />

            </div>

            <div className='sections '>

              <InputCont
                label='text.level_4'
                inputProps={{
                  name: 'defect_code4',
                  type: 'select',
                  valuelist: 'defect_codes',
                  additionalData: '4',
                  parentValue: dataItem.defect_code3,
                  disableCach: true,
                  disabled: disabled || !dataItem.defect_code3,
                  value: dataItem.defect_code4,
                  onChange: e => onCodeChanged('defect_code4', e),
                  allowClear: true,
                  validation: validation
                }}
              />

            </div>
          </div>
          {
            !disabled && <toolbar>
              <wrap>
                <action right=''>
                  <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                </action>
              </wrap>
            </toolbar>
          }
        </>
      }
    </>
  );
}