
import { message } from 'antd';
import React, { useState } from 'react';
import { Ajax } from '../../components/Ajax';
import { T } from '../../components/Translations';
import { ApiUrl } from '../../project/Defines';
import { dispatchCustomEvent } from '../../project/utilities';
import InputCont from '../../Shared/Form/InputCont';
import { hideLoader, showLoader } from '../../Shared/Loader';

const SupplierInvoice = (props) => {
    const { onClose, selectedRows, listUpdateEvent } = props;
    const [supplierInvoice, setSupplierInvoice] = useState('');

    const generate = () => {
        showLoader('generate');
        Ajax.post({
            url: ApiUrl.GenerateInvoice,
            data: {
                supplierInvoice,
                fees: selectedRows.map(f => ({
                    DocEntry: f.DocEntry,
                    DocNum: f.DocNum,
                    SCOrigin: f.SCOrigin, DocTotal: f.DocTotal, DocDate: f.DocDate
                }))
            },
            success: function (response) {
                dispatchCustomEvent(listUpdateEvent);
                onClose();
                hideLoader('generate');
                response.Message && message[response.HasError ? 'error' : 'success'](response.Message);
            },
            error: function (response) {
                message.error(response.message);
                hideLoader('generate');
            }
        });
    };
    return <>
        <div className='form_container'>
            <div className='section_group'>
                <div className='sections'>
                    <form>
                        <InputCont
                            label="text.supplier_invoice"
                            inputProps={{ name: 'supplier_code', maxLength: 10, value: supplierInvoice, onChange: e => setSupplierInvoice(e.target.value) }} />
                    </form>
                </div>
            </div>
        </div>
        <toolbar>
            <wrap>
                <action right=''>
                    <div disabled={!supplierInvoice} onClick={generate} className='button'><icon>download</icon><T>{'text.generate'}</T></div>
                    <div onClick={onClose} className='button'><icon>close</icon><T>text.cancel</T></div>
                </action>
            </wrap>
        </toolbar>
    </>;

};


export default SupplierInvoice;