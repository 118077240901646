import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { T } from '~/components/Translations';
import { Ajax } from '../../components/Ajax.js';
import { ApiUrl } from '../../project/Defines.js';
import ListView from '../../Shared/ListView';
import { COLUMN_FORMAT, COLUMN_TYPE } from '../../Shared/ListView/helper';
import { hideLoader, showLoader } from '../../Shared/Loader/index.js';




/**
 * the societe List
 * @exports SocietePicker
 */
export default function SocietePicker(props) {
    const { t } = useTranslation("defines");
    const { onClose } = props;
    const windowKey = 'invoiceHistory';
    // const [supplierInvoice, setSupplierInvoice] = useState('');
    const [satName, setSatName] = useState('');

    useEffect(() => {
        showLoader(windowKey);
        Ajax.get({
            url: ApiUrl.GetSatName,
            data: {},
            success: function (response) {
                setSatName(response);
                hideLoader(windowKey);
            },
            error: function (response) {
                hideLoader(windowKey);
            }
        });
    }, []);

    const generate = (supplierInvoice) => {
        showLoader(windowKey);
        Ajax.download({
            url: ApiUrl.DownloadInvoice,
            autoDownload: true,
            fileName: `invoice_${satName}_${supplierInvoice}.pdf`,
            data: {
                supplierInvoice
            },
            success: function (response) {
                onClose();
                hideLoader(windowKey);
                response.Message && message[response.HasError ? 'error' : 'success'](response.Message);
            },
            error: function (response) {
                message.error(response.message);
                hideLoader(windowKey);
            }
        });
    };

    const columnConfig = [
        {
            text: 'text.name',
            field: 'supplier_invoice',
            noSort: 'true',

            type: COLUMN_TYPE.TEXT,
        }, {
            text: 'text.created_date',
            field: 'created_at',

            type: COLUMN_TYPE.TEXT,
            format: COLUMN_FORMAT.DATE
        },
        {
            type: COLUMN_TYPE.ACTION,
            width: t('width.action_select'),
            render: (text, record) => <elastic>
                <action hover="">
                    <div
                        effect="material"
                        className="button accent"
                        onClick={() => generate(record.supplier_invoice)}
                    >
                        <text><T>text.select</T></text>
                    </div>
                </action>
            </elastic>
        },
    ];

    return (
        <ListView
            {...props}
            isSimple
            search={{ field: 'search' }}
            skipNewButton
            columnConfig={columnConfig}
            apiUrl={ApiUrl.SocieteInvoiceGenerationDataList}
            noPaging
        />
    );
}