import React, { useState } from 'react';
import { useCallback } from 'react';
import { T } from '../../components/Translations';

/**
 *an area of data collapsing into a panel
 * @param {object} props  children, name, left, rounded
 */
export function Switchable({
    children,
    name,
    left,
    rounded,
    hide,
    className,
    icon,
    switchContent,
    onShowStateChanged,
    ...restProps
}) {
    const [show, setShow] = useState(!hide);

    const onSwitchClick = useCallback(() => {
        const newState = !show;
        setShow(newState);
        onShowStateChanged && onShowStateChanged(newState);
    }, [show, onShowStateChanged])
    return <>
        <switchable rounded={rounded && ""} mini="" className={`${show ? "" : "hide"} ${className || ''}`} {...restProps} >
            {!left && children}
            <switch onClick={onSwitchClick}>
                {switchContent || <><wrap><text><T>{name || 'text.filters'}</T></text></wrap>{icon}</>}
            </switch>
            {left && children}
        </switchable>
    </>
}