import React, { useCallback, useEffect, useState } from 'react';
import { DataTip, T } from '../../../components/Translations';
import { ApiUrl } from '../../../project/Defines';
import { dispatchCustomEvent, EVENT_NAME } from '../../../project/utilities';
import InputCont from '../../../Shared/Form/InputCont';
import { ValidationUpload } from '../../../Shared/Form/validation';
import useDetail from '../../../Shared/useDetail';
import uuidv4 from 'uuid/v4';

/**
 * Attachment detail of serviceCall
 * @exports AttachmentDetails
 */
export default function AttachmentDetails(props) {
  // const { data } = props;
  const { windowKey, parentData, isNewCall, setDataSource } = props.detailProps;
  const listUpdateEvent = EVENT_NAME.ATTACHMENTS_LIST_UPDATE;
  const [validationFields, setValidationFields] = useState([]);
  //const onLoadData = useCallback((response, setDataItem) => {
  //    response && setDataItem({
  //        ...data, service_job_uuid: parentData.ID
  //    });
  //    return true;
  //}, [data, parentData]);

  const { dataItem, setDataItem, updateData, closePopup, validation } = useDetail({
    data: { ID: "", images: [], ImageItems: [], service_job_uuid: parentData?.ID },
    updateUrl: !isNewCall && ApiUrl.AttachmentsUpdate,
    windowKey,
    //dataLoadUrl: !isNewCall && ApiUrl.AttachmentsLoad,
    updateLocally: isNewCall,
    // deleteUrl: ApiUrl.AttachmentsDelete,
    //  onLoadData,
    listUpdateEvent,
    validationFields,
    changingValidation: true,
    // additionalData: { service_job_uuid: parentData.ID }
  });
  useEffect(() => {
    setValidationFields([...(dataItem?.images.map((image, i) => `images[${i}].name`) || [])]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataItem?.images]);

  const updateSuccessCallback = useCallback((response) => {
    dispatchCustomEvent(listUpdateEvent, { action: 'update' });
    closePopup();
  }, [closePopup, listUpdateEvent]);

  const updateForNewCall = () => {
    setDataSource(d => ([...d.map((item, index) => ({ ...item })), ...dataItem.images.map((i, index) => ({ attachment_name: i.name, images: [i], ID: uuidv4()}))]));
    closePopup();
  };

  //useEffect(() => {
  //    if (dataItem) {
  //        const name = dataItem && dataItem.images && dataItem.images[0] && dataItem.images[0].name;
  //        allowAutoNameChange && setDataItem({ ...dataItem, attachment_name: name });
  //    }
  //}, [dataItem && dataItem.images]);//eslint-disable-line  react-hooks/exhaustive-deps

  const onNameChange = useCallback((e, i) => {
    const newValue = e.target.value;

    const newData = { ...dataItem };
    newData.images[i].name = newValue;
    setDataItem(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataItem]);

  return (<>
    {dataItem &&
      <form>
        <div className='form_container'>
          <div className='section_group'>
            <div className='sections '>

              <ValidationUpload
                validation={validation}
                hideLink
                listType='text'
                className='simple'
                anyFile
                multiple
                fileNameField='attachment_name'
                imageUrl='image_url'
                imageField='images'
                dataItem={dataItem}
                setDataItem={setDataItem}
                type={'service_job_attachments'}
              />
              {/* <FormField label="text.attachment_name">
                                <Input name='attachment_name' value={dataItem.attachment_name} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.file">
                                <CustomUpload
                                    // keepName
                                    listType='text'
                                    className='simple'
                                    anyFile
                                    fileNameField='attachment_name'
                                    imageUrl='image_url'
                                    imageField='images'
                                    dataItem={dataItem}
                                    setDataItem={setDataItem}
                                    type={'service_job_attachments'}
                                />
                            </FormField> */}

              {dataItem.images?.map((image, i) => <InputCont key={dataItem.images[i].image_guid}
                inputProps={{ name: `images[${i}].name`, validation: validation, value: dataItem.images[i].name, placeholder: "", onChange: (e) => onNameChange(e, i) }}
                icon={<> {dataItem.images[i].image_url && <a href={dataItem.images[i].image_url} target='_blank'><DataTip title={dataItem.images[i].name}><icon>eye</icon></DataTip></a>} { /*eslint-disable-line react/jsx-no-target-blank*/}
                </>}
              />)}
              {/* <cont>
                                <CustomUpload
                                    imageUrl='image_url'
                                    imageField='images'
                                    dataItem={dataItem}
                                    setDataItem={setDataItem}
                                    type={'normes'}
                                />
                                <decor><label><text><line></line><wrap><span>Logo</span></wrap></text><line></line></label><border></border></decor>
                            </cont> */}
            </div>
          </div>
        </div>

        <toolbar>
          <wrap>
            <action right=''>
              <div onClick={(e) => updateData(isNewCall ? updateForNewCall : updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
              {/* <div className='button' onClick={(e) => { onDetailDelete(dataItem)}}>
                                <icon>delete</icon><T>text.delete</T>
                            </div> */}
            </action>
          </wrap>
        </toolbar>
      </form>
    }
  </>);
}

