import { Badge, Spin } from 'antd';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import Layout from './components/Layout';
import { menuItems } from './components/Navigation/Menu';
import Login from './pages/Login';
import LoginRedirect from './pages/LoginRedirect';
import LoginCallback from './pages/LoginCallback';
import Logout from './pages/Logout';
import LogoutRedirect from './pages/LogoutRedirect';
import ConnectAs from './pages/ConnectAs/ConnectAs';
import NotFound from './pages/NotFound';
import { Ajax } from './components/Ajax';
import { Template, Template2 } from './pages/Template';
import { AppPages, StoreKeys } from './project/Defines';
import { Project } from './project/Project';
import { storageChange } from './store/actions';
import Planning from './pages/Planning';
import ServiceCalls from './pages/ServiceCall/index';
import UnassignedServiceCalls from './pages/ServiceCall/UnassignedServiceCalls';
import ServiceCallsDetails from './pages/ServiceCall/detail';
import ServiceCallCreate from './pages/ServiceCall/Create/CreateCall';
import Fees from './pages/Documents/Fee';
import SpareParts from './pages/Documents/SparePart';
import DPC from './pages/Documents/DPC';
import InvoiceGeneration from './pages/Invoice/index';
import ServiceContract from './pages/ServiceContract';
import ServiceContractDetails from './pages/ServiceContract/detail';
import ServiceContractCreate from './pages/ServiceContract/Create/CreateContract';

const routings = [
  { page: AppPages.Login, component: Login },
  { page: AppPages.Logout, component: Logout },
  { page: AppPages.Template, component: Template },
  { page: AppPages.Planning, component: Planning },
  { page: AppPages.ServiceCallsDetails, component: ServiceCallCreate, routeParams: "create" },
  { page: AppPages.ServiceCallsDetails, component: ServiceCallsDetails, routeParams: ':id' },
  { page: AppPages.ServiceCalls, component: ServiceCalls },
  { page: AppPages.ServiceCallsUnassigned, component: UnassignedServiceCalls },
  // { page: AppPages.Documents, component: Documents },
  { page: AppPages.Fees, component: Fees },
  { page: AppPages.SpareParts, component: SpareParts },
  { page: AppPages.DPC, component: DPC },
  { page: AppPages.InvoiceGeneration, component: InvoiceGeneration },
  // { page: AppPages.Attachments, component: Attachments },
  // { page: AppPages.AttachmentDetails, component: AttachmentDetails, routeParams: ':id' },
  { page: AppPages.ServiceContractDetails, component: ServiceContractCreate, routeParams: "create" },
  { page: AppPages.ServiceContractDetails, component: ServiceContractDetails, routeParams: ':id' },
  { page: AppPages.ServiceContract, component: ServiceContract }
];

/**
*  the Base component that also manages routing
 * @module App
 */
function App(props) {
  const { role, isLoggedIn, storageChange, isMaintenanceModeEnabled, maintenanceModeStart, maintenanceModeEnd, enableServiceContract } = props;
  const loading = <div style={{ "margin": "25% auto" }}><Spin size="large" /></div>;
  const homeRedirect = Project.getPageUrl(menuItems.find(i => Project.hasRoleAccess(role, i.to))?.to);
  const [showPages, setShowPages] = useState(false);
  const [isMaintainance, setIsMaintainance] = useState(false);
  const appRef = useRef(false);
  useEffect(() => {
    appRef.current = true;
    const handler = e => {
      const trackingKeys = [StoreKeys.UserToken, StoreKeys.UserName, StoreKeys.LoggedIn, StoreKeys.ListStates, StoreKeys.Culture, StoreKeys.Role, StoreKeys.ExpireTime];
      if (trackingKeys.includes(e.key)) {
        storageChange();
      }
    };

    setTimeout(() => {
      if (isLoggedIn) {
        Ajax.post({
          url: "/account/check",
          data: {},
          success: function (response) {
            if (response) {
              setShowPages(true);
            }
          }
        });
      } else {
        setShowPages(true);
      }
    }, 100);

    if (isMaintenanceModeEnabled) {
      let now = new Date();
      if (maintenanceModeEnd >= now) {

        if (maintenanceModeStart <= now) {
          setIsMaintainance(true);
          setTimeout(() => { appRef.current && setIsMaintainance(false); }, maintenanceModeEnd - new Date());
        } else {
          setTimeout(() => {
            appRef.current && setIsMaintainance(true);
            setTimeout(() => { appRef.current && setIsMaintainance(false); }, maintenanceModeEnd - new Date());
          }, maintenanceModeStart - now);
        }
      }
    }
    window.addEventListener('storage', handler);
    return () => {
      appRef.current = false;
      window.removeEventListener('storage', handler);
    };
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  if (isMaintainance) {
    return <h1> Maintenance <Badge>{maintenanceModeStart.toLocaleString()}-{maintenanceModeEnd.toLocaleString()}</Badge> </h1>;
  }
  if (!isLoggedIn) {
    return <Suspense fallback={loading}>
      <Layout>
        <Switch>
          <Route
            path="/loginredirect/:code"
            component={LoginRedirect}
          />
          <Route
            path="/connectas/:token"
            component={ConnectAs}
          />
          <Route
            path="/login"
            component={Login}
          />
          <Route
            path="/wso/callback"
            component={LoginCallback}
          />
          <Route path='/template' component={Template} />
          <Route path='/templatedetails' component={Template2} />
          <Route path="*" >
            <Redirect to={Project.getPageUrl(AppPages.Login, "", { redirectUrl: window.location.pathname })} />
          </Route>
        </Switch>
      </Layout>
    </Suspense>;
  }

  if (!showPages)
    return <>{loading}</>;

  return <Suspense fallback={loading}>
    <Layout>
      <Switch>
        <Route exact path='/' >
          {(homeRedirect && <Redirect to={homeRedirect} />) || (<Redirect to={Project.getPageUrl(AppPages.Login)} />)}
        </Route>
        <Route
          path="/logoutredirect"
          component={LogoutRedirect}
        />
        <Route path="/login">
          {(homeRedirect && <Redirect to={homeRedirect} />) || (<Redirect to={Project.getPageUrl(AppPages.Login)} />)}
        </Route>
        <Route
          path="/wso/callback"
          component={LoginCallback}
        />
        {routings.filter(r => (r.pages ? r.pages.some(p => Project.hasRoleAccess(role, p)) : Project.hasRoleAccess(role, r.page))
          && (enableServiceContract || ![AppPages.ServiceContract, AppPages.ServiceContractDetails].includes(r.page)))
          .map(r =>
            <Route key={r.pages ? r.pages.join() : r.page} path={r.pages ? r.pages.map(p => Project.getPageUrl(p.page || p, p.routeParams)) : Project.getPageUrl(r.page, r.routeParams)} component={r.component} />)}

        <Route path="*" component={NotFound} />
      </Switch>
    </Layout>
  </Suspense>;
}

export default connect(state => ({
  isLoggedIn: state.isLoggedIn,
  role: state.role,
  isMaintenanceModeEnabled: state.isMaintenanceModeEnabled,
  maintenanceModeStart: state.maintenanceModeStart,
  maintenanceModeEnd: state.maintenanceModeEnd,
  enableServiceContract: state.enableServiceContract
}),
  dispatch => ({
    storageChange: (key) => dispatch(storageChange())
  }))(App);
