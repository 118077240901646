import React, { useState, useCallback, useEffect } from "react";
import { T, Input } from '../../components/Translations';
import { Switchable } from '../../Shared/Switchable';
import DropDownValueList from '../../Shared/DropDown';
import { DatePicker } from '../../Shared/DatePicker';
//import { DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import moment from 'moment';

/**
 * the layout for all filters
 * @param {object} props  onClearAll, children, onApply
 */
function FilterContainer(props) {
    const { onClearAll, children, onApply } = props;
    return (<>
        <view className="filter_container">
            <div className="filter_content">
                <wrap>
                    {children}
                </wrap>
            </div>
            <toolbar>
                <action>
                    <a className="button link right" disabled="" onClick={() => onClearAll()}><text><T>text.clear_all</T></text></a>{ /*eslint-disable-line jsx-a11y/anchor-is-valid*/}
                </action>

                <action right="">
                    <button command="Apply" className="button action" type="button" onClick={() => onApply()}><text><T>text.filter_apply</T></text></button>
                </action>
            </toolbar>
        </view>
    </>);
}

/**
 * the control generating filter fields for list views and responsible for inner logic of filter values
 * @param {any} props contains: filters, appliedFilter, setAppliedFilter, pagination, setPaginatio
 * @exports Filters
 */
export default function Filters(props) {
    const { t } = useTranslation();
    const {
        filters,
        appliedFilter,
        setAppliedFilter,
        pagination,
        setPagination } = props;
    const [filter, setFilter] = useState(null);
    const [clearedFilter, setClearedFilter] = useState(null);
    const clearFilter = useCallback(() => {
        setFilter({ ...clearedFilter });
        setAppliedFilter({ ...clearedFilter });
        setPagination({ ...pagination, current: 1 });

    }, [setAppliedFilter, clearedFilter, setPagination, pagination]);


    const apply = useCallback((event) => {
        setAppliedFilter({ ...appliedFilter, ...filter });
        setPagination({ ...pagination, current: 1 });
    }, [appliedFilter, filter, pagination, setPagination]);// eslint-disable-line react-hooks/exhaustive-deps

    const onKeyPress = useCallback((event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            apply();
        }
    }, [apply]);

    useEffect(() => {
        const flatFilters = filters && filters.reduce((flat,f) => {
            if (f.type !== 'group') {
                flat.push(f);
            } else {
                flat.push(...f.items);
            }
            return flat;
        },[]);
        setClearedFilter((flatFilters && {
            ...flatFilters.reduce((result, f) => {
                if (f.defaultValue) {
                    result[f.name] = f.defaultValue;
                } else {
                    switch (f.type) {
                        case 'text':
                        case 'select':
                        case 'date':
                            result[f.name] = '';
                            if (f.includeEmpty) {
                                result[`${f.name}-empty`] = false;
                            }
                            break;
                        case 'checkbox':
                            result[f.name] = false;
                            break;
                        default:
                            break;
                    }
                }
                return result;
            }, {})
        }) || {});
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        !appliedFilter && clearedFilter ? clearFilter() : setFilter(appliedFilter);
    }, [clearedFilter]);// eslint-disable-line react-hooks/exhaustive-deps

    const filterFieldChange = useCallback((event, applyChanges) => {
        if (event) {
            const newFilter = { ...filter };
            const target = event.target;
            newFilter[target.name] = target.type !== 'checkbox' ? target.value : target.checked;
            setFilter(newFilter);
            if (applyChanges) {
                setAppliedFilter({ ...appliedFilter, ...filter });
            }
        }
    }, [filter]);// eslint-disable-line react-hooks/exhaustive-deps

    const filterControl = useCallback((f, index) => {
        switch (f.type) {
            case 'text':
                return <div className="data_cell" key={f.name + index}>
                        <wrap block="">
                            <cont>
                                <Input type="text"
                                    placeholder={f.text}
                                    name={f.name}
                                    value={filter[f.name]}
                                    onChange={filterFieldChange}
                                    onKeyPress={onKeyPress} />
                                <decor>
                                    <label>
                                        <text>
                                            <line></line>
                                            <wrap>
                                                {/* {labelIcon && <icon>{labelIcon}</icon>} */}
                                                {<span><T>{f.text}</T></span>}
                                            </wrap>
                                        </text>
                                        <line></line>
                                    </label>
                                    <border></border>
                                </decor>
                                {/* {icon && <div effect="material" className="button mini "><icon>{icon}</icon></div>} */}
                            </cont>
                            {/* <label className="input_cont">
                                                {f.includeEmpty && <>
                                                    <separator vertical=""></separator>
                                                    <label simple="" checkbox=""><name><T>text.empty</T></name><input type="checkbox" name={`${f.name}-empty`} onChange={filterFieldChange} checked={filter[`${f.name}-empty`]} /><text light=''></text></label>
                                                </>}
                                                <i></i>
                                            </label> */}
                        </wrap>
                    </div>
            case 'select':
                return <div className="data_cell" key={f.name + index}>
                        <wrap block="">
                            <cont>
                                <DropDownValueList
                                    searchOptions={f}
                                    showSearch
                                    style={{ width: '100%' }}
                                    type="text"
                                    autoComplete={f.autoComplete || "off"}
                                    valuelist={f.valuelist}
                                    textAsValue={f.textAsValue}
                                    name={f.name}
                                    {...(f.disableCach ? { disableCach: true } : {})}
                                    {...(f.mode ? { mode: 'multiple' } : {})}
                                    {...(filter[f.name] ? { value: filter[f.name] } : { placeholder: <T>{f.text}</T> })}
                                    onChange={(e) => filterFieldChange({ target: { name: f.name, value: e } }, f.applyOnChange)}
                                    allowClear
                                    additionalData={f.additionalData}
                                />
                                <decor>
                                    <label>
                                        <text>
                                            <line></line>
                                            <wrap>
                                                {/* {labelIcon && <icon>{labelIcon}</icon>} */}
                                                {<span><T>{f.text}</T></span>}
                                            </wrap>
                                        </text>
                                        <line></line>
                                    </label>
                                    <border></border>
                                </decor>
                            </cont>
                        </wrap>
                    </div>
            case 'date':

                return <div className="data_cell" key={f.name + index}>
                        <wrap block="">
                            <cont>
                                <DatePicker
                                    placeholder={t('text.from')}
                                    name={f.name + '_from'}
                                    onKeyPress={onKeyPress}
                                    //value={(filter[f.name + '_from'] && moment(filter[f.name + '_from']) )|| ''}
                                    //onChange={(moment, stringVal) => filterFieldChange({ target: { name: f.name + '_from', value: moment } }, f.applyOnChange)}
                                    dataField={f.name + '_from'}
                                    dataItem={filter}
                                    setDataItem={(d) => filterFieldChange({ target: { name: f.name + '_from', value: d[f.name + '_from'] } }, f.applyOnChange)}
                                    max={(filter[f.name + '_to'] && moment(filter[f.name + '_to'])) || ''}
                                />
                                <decor>
                                    <label>
                                        <text>
                                            <line></line>
                                            <wrap>
                                                {/* {labelIcon && <icon>{labelIcon}</icon>} */}
                                                {<span>{t('text.from')}</span>}
                                            </wrap>
                                        </text>
                                        <line></line>
                                    </label>
                                    <border></border>
                                </decor>
                            </cont>
                            {/* <icon>dash</icon> */}
                            <cont>
                                <DatePicker placeholder={t('text.to')}
                                    name={f.name + '_to'}
                                    onKeyPress={onKeyPress}
                                    //value={(filter[f.name + '_to'] && moment(filter[f.name + '_to']) )|| ''}
                                    //onChange={(moment, stringVal) => filterFieldChange({ target: { name: f.name + '_to', value: moment } }, f.applyOnChange)}
                                    dataField={f.name + '_to'}
                                    dataItem={filter}
                                    setDataItem={(d) => filterFieldChange({ target: { name: f.name + '_to', value: d[f.name + '_to'] } }, f.applyOnChange)}
                                    min={(filter[f.name + '_from'] && moment(filter[f.name + '_from'])) || ''}
                                />
                                <decor>
                                    <label>
                                        <text>
                                            <line></line>
                                            <wrap>
                                                {/* {labelIcon && <icon>{labelIcon}</icon>} */}
                                                {<span>{t('text.to')}</span>}
                                            </wrap>
                                        </text>
                                        <line></line>
                                    </label>
                                    <border></border>
                                </decor>
                            </cont>
                        </wrap>
                    </div>
            case 'checkbox':
                return <div className="data_cell" key={f.name + index}>
                        <wrap block="">
                            <label className="input_cont">
                                <label simple="" checkbox=""><name><T>{f.text}</T></name><input type="checkbox" name={f.name} onChange={filterFieldChange} checked={filter[f.name]} /><text light=''></text></label>
                                <i></i>
                            </label>
                        </wrap>
                    </div>
            default:
                return null;
        }
    }, [filterFieldChange, onKeyPress, filter, t]);

    return (< >
        {filters && filter && <>
            <Switchable left icon={<icon>collapse</icon>}>
                <FilterContainer onApply={apply} onClearAll={() => clearFilter()}>
                    {
                        filters.map((f, index) => {
                        switch (f.type) {
                            case 'group':
                                var items = [];
                                f.items.map((f_i, index_i) => items.push(filterControl(f_i, index_i)));
                                return <div className="filter_block" key={'group_' + index}>
                                    {f.text && <span className="title"><span><T>{f.text}</T></span></span>}
                                    {items}
                                </div>;
                            default:
                                return <div className="filter_block" key={'group_' + index}>
                                    {f.text && f.type ==='date' && <span className="title"><span><T>{f.text}</T></span></span>}
                                    {filterControl(f, index)}
                                </div>;
                        }
                    })}
                </FilterContainer>
            </Switchable>
            <gap></gap>
        </>}
    </>);


    // return (< >
    //     {filters && filter && <>
    //         <Switchable>
    //             <FilterContainer onApply={apply} onClearAll={() => clearFilter()}>
    //                 {filters.map((f, index) => {
    //                     switch (f.type) {
    //                         case 'text':
    //                             return <div className="filter_block" key={f.name + index}>
    //                                 <div className="data_cell">
    //                                     <wrap block="">
    //                                         <label className="input_cont">
    //                                             <Input type="text"
    //                                                 placeholder={f.text}
    //                                                 name={f.name}
    //                                                 value={filter[f.name]}
    //                                                 onChange={filterFieldChange}
    //                                                 onKeyPress={onKeyPress} />
    //                                             {f.includeEmpty && <>
    //                                                 <separator vertical=""></separator>
    //                                                 <label simple="" checkbox=""><name><T>text.empty</T></name><input type="checkbox" name={`${f.name}-empty`} onChange={filterFieldChange} checked={filter[`${f.name}-empty`]} /><text light=''></text></label>
    //                                             </>}
    //                                             <i></i>
    //                                         </label>
    //                                     </wrap>
    //                                 </div>
    //                             </div>
    //                         case 'select':
    //                             return <div className="filter_block" key={f.name + index}>
    //                                 <div className="data_cell">
    //                                     <wrap block="">
    //                                         <DropDownValueList
    //                                             searchOptions={f}
    //                                             showSearch
    //                                             style={{ width: '100%' }}
    //                                             type="text"
    //                                             autoComplete={f.autoComplete || "off"}
    //                                             valuelist={f.valuelist}
    //                                             textAsValue={f.textAsValue}
    //                                             name={f.name}
    //                                             {...(f.disableCach ? { disableCach: true } : {})}
    //                                             {...(filter[f.name] ? { value: filter[f.name] } : { placeholder: <T>{f.text}</T> })}
    //                                             onChange={(e) => filterFieldChange({ target: { name: f.name, value: e } }, f.applyOnChange)}
    //                                             allowClear
    //                                         />
    //                                         <i></i>
    //                                     </wrap>
    //                                 </div>
    //                             </div>
    //                         case 'date':

    //                             return <div className="filter_block" key={f.name + index}>
    //                                 <FormField label={f.text}>
    //                                     <wrap block="">
    //                                         <DatePicker
    //                                             placeholder={t('text.from')}
    //                                             name={f.name + '_from'}
    //                                             onKeyPress={onKeyPress}
    //                                             value={(filter[f.name + '_from'] && moment(filter[f.name + '_from']) )|| ''}
    //                                             onChange={(moment, stringVal) => filterFieldChange({ target: { name: f.name + '_from', value: moment } }, f.applyOnChange)}
    //                                         />
    //                                     </wrap> <icon>minus</icon>
    //                                     <wrap block="">
    //                                         <DatePicker placeholder={t('text.to')}
    //                                             name={f.name + '_to'}
    //                                             value={(filter[f.name + '_to'] && moment(filter[f.name + '_to']) )|| ''}
    //                                             onChange={(moment, stringVal) => filterFieldChange({ target: { name: f.name + '_to', value: moment } }, f.applyOnChange)}
    //                                         />
    //                                     </wrap>
    //                                 </FormField>
    //                             </div>
    //                         case 'checkbox':
    //                             return <div className="filter_block" key={f.name + index}>
    //                                 <div className="data_cell">
    //                                     <wrap block="">
    //                                         <label className="input_cont">
    //                                             <label simple="" checkbox=""><name><T>{f.text}</T></name><input type="checkbox" name={f.name} onChange={filterFieldChange} checked={filter[f.name]} /><text light=''></text></label>
    //                                             <i></i>
    //                                         </label>
    //                                     </wrap>
    //                                 </div>
    //                             </div>
    //                         default:
    //                             return null;
    //                     }
    //                 })}

    //             </FilterContainer>
    //         </Switchable>
    //         <gap></gap>
    //     </>}
    // </>);
}