
import { Link } from 'react-router-dom';
import React from 'react';
import { T } from '../../components/Translations';
import { AppPages, priorities } from '../../project/Defines';
import { Project } from '../../project/Project';
import InputCont from '../../Shared/Form/InputCont';
import { PriorityBlock } from '../../Shared/Local/PriorityBlock';


const CallDetail = (props) => {
    const { data } = props;
    const priority = data && priorities.find(x => x.id === data.priority);

    return data ?
        <>
            <toolbar transparent="">
                <Link to={Project.getPageUrl(AppPages.ServiceCallsDetails, data.ID)}>
                    <text code=""
                        priority={priority && priority.text.toLowerCase()}
                        data-size={data.call_id && data.call_id.length <= 11 ? "x-large" : "large"}
                        data_weight="500">
                        {data.call_id}</text></Link>
                <action right="">
                    <PriorityBlock priority={data.priority} />
                </action>
            </toolbar>
            <view scroll="" space="" mini="">
                <form>
                    <InputCont
                        label="text.type"
                        inputProps={{ disabled: true, value: data.call_type }} />

                    <InputCont
                        label="text.product_code"
                        inputProps={{ disabled: true, value: data.code_product, code: "" }} />

                    <InputCont
                        label="text.item_name "
                        inputProps={{ disabled: true, value: data.item_name }} />


                    <InputCont
                        label="text.subject"
                        inputProps={{ disabled: true, value: data.subject }} />
                    <separator horizontal=""></separator>

                    <InputCont
                        label="text.subcontractor"
                        inputProps={{ disabled: true, value: data.subcontractor_name }} />
                    <separator horizontal=""></separator>

                    <InputCont
                        label="text.customer_name"
                        inputProps={{ disabled: true, value: data.customer_name }} />

                    <InputCont
                        label="text.street"
                        inputProps={{ disabled: true, value: data.adresse }} />


                    <InputCont
                        label="text.code_postal"
                        inputProps={{ disabled: true, value: data.code_postal, code: "" }} />


                    <InputCont
                        label="text.city"
                        inputProps={{ disabled: true, value: data.ville }} />


                    <InputCont
                        label="text.country"
                        inputProps={{ disabled: true, value: data.pays }} />

                    <InputCont
                        label="text.phone_1"
                        inputProps={{ disabled: true, value: data.phone }} />

                    <InputCont
                        label="text.phone_2"
                        inputProps={{ disabled: true, value: data.phone2 }} />

                </form>
            </view>
            {/* <toolbar transparent="">
                    <cont>
                        <button type="submit" className="button large primary"><text>Submit</text></button>
                    </cont>
                </toolbar>*/}
        </> : <wrap>
            <demospace tip="">
                <icon large="" light="">info</icon> <text light="" wrap=""><T>text.no_appointment_selected</T></text>
                <separator horizontal=""></separator>
            </demospace>
        </wrap>

}


export default CallDetail;

