import { message } from 'antd';
import React, { useState } from 'react';
import { Ajax } from '../../../components/Ajax';
import { PopupTypes } from '../../../components/Popup';
import { DataTip, T } from '../../../components/Translations';
import { ApiUrl } from '../../../project/Defines';
import { AppPages } from '../../../project/Defines.js';
import { dispatchCustomEvent, EVENT_NAME } from '../../../project/utilities';
import ListView from '../../../Shared/ListView';
import { COLUMN_TYPE } from '../../../Shared/ListView/helper';
import { hideLoader, showLoader } from '../../../Shared/Loader';


/**
 * Attachments list of serviceCall
 * @exports Attachments
 */
export default function Attachments(props) {
    const listUpdateEvent = EVENT_NAME.ATTACHMENTS_LIST_UPDATE;
    const { dataSource, setDataSource, disabled } = props;
    const isNewCall = !!dataSource;
    const service_job_uuid = props.dataItem && props.dataItem.ID;
    const [staticFilter] = useState({ service_job_uuid });
    const [detailProps] = useState({
        newTitle: 'text.create_new_attachment',
        editTitle: 'text.edit_attachment',
        fullScreen: false,
        popupType: PopupTypes.AttachmentDetails,
        windowKey: 'wndAttachmentDetails',
        parentData: props.dataItem || {},
        isNewCall,
        setDataSource
    });

    const columnConfig = [
        {
            field: 'attachment_name',
            width: 50,
            render: (text, record) => {
                const url = record && record.images && record.images[0] && record.images[0].image_url;
                const imageName = record && record.images && record.images[0] && (record.images[0].name && record.images[0].image_guid);
                return (
                    <wrap>
                        <a href={url} target='_blank' style={{ display: 'flex' }}><DataTip title={imageName}><icon>eye</icon></DataTip></a> {/*eslint-disable-line react/jsx-no-target-blank*/}
                    </wrap>
                );
            },

            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.attachment_name',
            field: 'attachment_name',
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
        }
        // {
        //     text: 'text.file',
        //     key: 'file',
        //     width: 160,
        //     noSort: true,
        //     type: COLUMN_TYPE.TEXT,
        //     render: (text, record) => (
        //         // console.log(record)
        //         <>
        //             <wrap>
        //                 <div style={{
        //                     backgroundImage: "Url(" + record.image_url + ")",
        //                     backgroundRepeat: "no-repeat",
        //                     backgroundSize: "contain",
        //                     backgroundPosition: "center",
        //                     height: "35px"
        //                 }} />
        //             </wrap>
        //         </>
        //     )
        // },

    ];

    const generateWarrantyCertificate = () => {
        const loaderName = 'generateWarrantyCertificate';
        showLoader(loaderName);
        Ajax.post({
            url: ApiUrl.GenerateWarrantyCertificate,
            data: { service_job_uuid },
            success: function (response) {
                if (response) {
                    dispatchCustomEvent(listUpdateEvent);
                }
                hideLoader(loaderName);
            },
            error: function (response) {
                if (response.message) {
                    message.error(response.message);
                }
                hideLoader(loaderName);
            }
        });
    };

    const generateAssignmentReport = () => {
        const loaderName = 'generateAssignmentReport';
        showLoader(loaderName);
        Ajax.post({
            url: ApiUrl.GenerateAssignmentReport,
            data: { service_job_uuid },
            success: function (response) {
                if (response) {
                    dispatchCustomEvent(listUpdateEvent);
                }
                hideLoader(loaderName);
            },
            error: function (response) {
                if (response.message) {
                    message.error(response.message);
                }
                hideLoader(loaderName);
            }
        });
    };

    return (<>
        {!isNewCall && < toolbar >
            <div className="button accent" onClick={generateWarrantyCertificate}>
                <text><T>text.warranty_certificate</T></text>
            </div>
            <div className="button accent" onClick={generateAssignmentReport}>
                <text><T>text.assignment_report</T></text>
            </div>
        </toolbar>}
        <ListView
            {...props}
            columnConfig={columnConfig}
            staticFilter={staticFilter}
            apiUrl={ApiUrl.AttachmentsList}
            dataSource={dataSource}
            skipEdit
            listUpdateEvent={listUpdateEvent}
            detailProps={detailProps}
            deleteUrl={!disabled && ApiUrl.AttachmentsDelete}
            skipNewButton={disabled}
            onCustomDelete={isNewCall && (rec => setDataSource(d => d.filter(at => at.ID !== rec.ID)))}
            newButtonTip={<T>text.create_new_attachment</T>}
            nameField='attachment_name'
            showRefreshButton={false}
            isSimple
            isChieldView
            listName={AppPages.Attachments}
        /></>

    );
}
