import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { logout } from '../store/actions/index.js';
import { AppPages } from '../project/Defines';
import { Project } from '../project/Project.js';

/**
 * the Logout Component
 * @exports Logout
 */
function LogoutRedirect(props) {
    const { history } = props;

    useEffect(() => {
        props.dispatch(logout());
        history.push(Project.getPageUrl(AppPages.Login));
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return <><div preloader=""><Spin size="large" /></div></>;
}

export default connect(state => ({
    userToken: state.userToken,
    isLoggedIn: state.isLoggedIn,
    userData: state.userData
}))(LogoutRedirect);