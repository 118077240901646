import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuidv4 from 'uuid/v4';
import { PopupTypes } from '../../../components/Popup';
import { T } from '../../../components/Translations';
import { ApiUrl, UserRoles } from '../../../project/Defines';
import { dispatchCustomEvent, EVENT_NAME } from '../../../project/utilities';
import InputCont from '../../../Shared/Form/InputCont';
import { ValidationDropDownValueList, ValidationInput } from '../../../Shared/Form/validation';
import ListView from '../../../Shared/ListView';
import { COLUMN_TYPE } from '../../../Shared/ListView/helper';
import useDetail from '../../../Shared/useDetail';
import { openPopup } from '../../../store/actions';
import { hasValidCallData, isDocumentReadOnly, isLineReadOnly } from '../commonFunctions';

const listUpdateEvent = EVENT_NAME.SPARE_PART_LIST_UPDATE;
const reloadEvent = EVENT_NAME.SPARE_PART_DATA_RELOAD;

/**
 * the SparePartDetail
 * @exports SparePartDetail
 */
export default function SparePartDetail(props) {
    const dispatch = useDispatch();
    const isDispatcher = useSelector(s => s.role) === UserRoles.DISPATCHER;
    const { data } = props;
    const { windowKey } = props.detailProps;
    const [disableSaveButton, setDisableSaveButton] = useState(false);
    const isCreateNewMode = !data.DocNum;
    const isReadOnlyMode = isDocumentReadOnly(data);
    const { callData } = hasValidCallData(data) ? data : { callData: { call_id: data.SCOrigin } };

    const onLoadData = useCallback((response, setDataItem) => {
        Array.isArray(response) && setDataItem({
            ...data, orderLines: response.map((m, i) => ({ ...m, key: uuidv4(), ID: uuidv4() }))
        });
        return true;
    }, [data]);

    const { dataItem, setDataItem, updateData, detailRef, closePopup } = useDetail({
        data: data.DocNum ? data : { ...data, orderLines: [] },
        updateUrl: ApiUrl.SparePartUpdate,
        dataLoadUrl: data.DocNum && ApiUrl.SparePartLineLoad,
        dataLoadParams: { docNum: data.DocNum, call_id: data.SCOrigin },
        additionalData: { callID: callData.ID },
        onLoadData,
        listUpdateEvent,
        windowKey,
        reloadEvent
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            closePopup(windowKey);
        }
        dispatchCustomEvent(listUpdateEvent);
    }, [detailRef, closePopup, windowKey]);

    const onCustomSave = useCallback(({ editingRecord, record, setEditingRecord, validation }) => {
        if (!(validation && !validation.isValid)) {
            setDataItem({
                ...dataItem, orderLines: dataItem.orderLines.map(ol => ol.key === editingRecord.key ? { ...editingRecord, ID: editingRecord.key } : ol)
            });
            setEditingRecord(null);
        }
    }, [dataItem, setDataItem]);

    const onCustomCancel = useCallback((setEditingRecord) => {
        setDataItem({
            ...dataItem, orderLines: [...dataItem.orderLines.filter(ol => ol.ID !== 'new')]
        });
        setEditingRecord(null);
    }, [dataItem, setDataItem]);

    const onRemove = useCallback((record) => {
        setDataItem({
            ...dataItem, orderLines: [...dataItem.orderLines.filter(ol => ol.ID !== record.ID)]
        });
    }, [dataItem, setDataItem]);

    const onNewClick = useCallback((event, onEdit) => {
        const newData = { key: uuidv4(), ID: 'new', Quantity: 1 };
        setDataItem({ ...dataItem, orderLines: [newData, ...dataItem.orderLines.filter(ol => ol.ID !== 'new')] });
        onEdit(newData, null, event, false);
    }, [dataItem, setDataItem]);

    const onCancelLine = line => {
        dispatch(
            openPopup({
                type: PopupTypes.Confirm,
                title: <T>text.cancel</T>,
                text: <T> message.delete_confirm</T>,
                buttonYesText: <T>text.yes</T>,
                buttonNoText: <T>text.no</T>,
                yesCallback: () => {
                    updateData((response) => {
                        dispatchCustomEvent(reloadEvent);
                        dispatchCustomEvent(listUpdateEvent);
                    }, null, { additionalData: { canceledLine: line.LineNum } });
                }

            }));
    }

    const commands = [];
    !isReadOnlyMode && commands.push({ command: 'edit', skipCommandForRecord: isLineReadOnly })
    !isReadOnlyMode && !isCreateNewMode && commands.push({ command: 'remove', tooltip: 'text.cancel', skipCommandForRecord: isLineReadOnly, onClick: onCancelLine })
    isCreateNewMode && commands.push({ command: 'delete', onClick: onRemove });

    const columns = [
        {
            text: 'text.spare_part',
            noSort: 'true',
            field: 'CodeCom',
            type: COLUMN_TYPE.TEXT,
            render: (t, record) => {
                let text = [record.CodeCom, record.Description].filter(v => v).join(' - ');
                return <wrap title={text} >
                    <text>{text}</text>
                </wrap>
            },
            readOnly: !isCreateNewMode,
            editor: {
                customRenderer: ({ editingRecord, setEditingRecord, validation }) => {
                    return <ValidationDropDownValueList
                        validation={validation}
                        name='CodeCom'
                        value={editingRecord.CodeCom}
                        valuelist='product_articles'
                        additionalData={callData.produit_uuid}
                        disableCach={true}
                        searchOptions={{ contains: true }}
                        onChange={(e, dataSource) => {
                            const item = dataSource.find(x => x.value === e);
                            setEditingRecord({ ...editingRecord, CodeCom: item.value, Description: item.description });
                        }}
                    />
                }
            }
        },
        {
            text: 'text.quantity',
            noSort: 'true',
            field: 'Quantity',
            width: 90,
            type: COLUMN_TYPE.TEXT,
            editor: {
                customRenderer: ({ editingRecord, setEditingRecord, validation }) => {
                    return <ValidationInput
                        validation={validation}
                        name='Quantity'
                        type='number'
                        inputType='number'
                        min={1}
                        className='ant-input'
                        value={editingRecord.Quantity}
                        onChange={(e) => {
                            let quantity = e || 1; //e.target.value;
                            setEditingRecord({ ...editingRecord, Quantity: quantity });
                        }}
                    />
                }
            }
        },
        {
            readOnly: true,
            text: 'text.status',
            noSort: 'true',
            width: 120,
            field: 'LineStatus',
            type: COLUMN_TYPE.TEXT
        },
        {
            skip: !isDispatcher,
            readOnly: true,
            text: 'text.account',
            noSort: 'true',
            width: 120,
            field: 'Account',
            type: COLUMN_TYPE.TEXT
        },
        {
            skip: !isDispatcher,
            readOnly: true,
            text: 'text.business_unit',
            noSort: 'true',
            width: 120,
            field: 'BusinessUnit',
            type: COLUMN_TYPE.TEXT
        },
        {
            skip: !isDispatcher,
            readOnly: true,
            text: 'text.cost_center',
            noSort: 'true',
            width: 120,
            field: 'CostCenter',
            type: COLUMN_TYPE.TEXT
        },
        {
            skip: !isDispatcher,
            readOnly: true,
            text: 'text.destination',
            noSort: 'true',
            field: 'Destination',
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
            width: 100,
            commands,
            onCustomSave,
            onCustomCancel
        }
    ].filter(c => !c.skip);

    return (dataItem && dataItem.orderLines && < div className="form_container" >
        <view  >
            <form>
                <toolbar >
                    <InputCont
                        label='text.call_id'
                        inputProps={{ disabled: true, name: 'call_id', value: callData.call_id }}
                    />
                    <InputCont
                        label='text.customer_code'
                        inputProps={{ disabled: true, name: 'code_client', value: dataItem.CardCode || callData.code_client }}
                    />
                    <InputCont
                        label='text.request_id'
                        inputProps={{ disabled: true, name: 'DocNum', value: dataItem.DocNum }} />
                </toolbar>

                <ListView
                    {...props}
                    columnConfig={columns}
                    noPaging
                    keyField={isCreateNewMode ? "ID" : "LineNum"}
                    dataSource={dataItem.orderLines}
                    validationFields={['CodeCom', { name: 'Quantity', rules: [{ type: "number" }] }]}
                    editable={!isReadOnlyMode}
                    isChieldView
                    onNewClick={onNewClick}
                    onEditingRecordChange={editingRecord => { setDisableSaveButton(editingRecord !== null); }}
                    skipNewButton={!isCreateNewMode || isReadOnlyMode}
                    skipEdit={isReadOnlyMode}
                    isRecordReadOnly={isLineReadOnly}
                />
                {!isReadOnlyMode ? <toolbar>
                    <wrap>
                        <action right=''>
                            {<div disabled={disableSaveButton || !(dataItem.orderLines && dataItem.orderLines.some(l => !isLineReadOnly(l)))} onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>}
                        </action>
                    </wrap>
                </toolbar> : <></>}

            </form>
        </view >

    </div >) || <></>;
}