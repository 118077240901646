import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PopupClasses, PopupTypes } from '../../../components/Popup';
import { T } from '../../../components/Translations';
import { ApiUrl, AppPages, Defines, UserRoles } from '../../../project/Defines';
import { Project } from '../../../project/Project';
import { EVENT_NAME } from '../../../project/utilities';
import DetailView from '../../../Shared/DetailView';
import InputCont from '../../../Shared/Form/InputCont';
import useDetail from '../../../Shared/useDetail';
import { closeAllPopups, openPopup } from '../../../store/actions';

import moment from 'moment';
import Attachments from '../../ServiceContract/Attachment';

const listUpdateEvent = EVENT_NAME.SERVICE_CONTRACT_LIST_UPDATE;
/**
 * @exports CreateContract
 */
function CreateContract(props) {
  const [validationFields] = useState([]);//['product_code', 'internal_serial_number']);
  const [attachments, setAttchments] = useState([]);
  const [currentDate] = useState(moment(new Date(), Defines.Format.MomentDateTime).format(Defines.Format.MomentDateTime));
  const { windowKey, openPopup, closeAllPopups, record, isDuplicate, isSat } = props;
  const history = useHistory();

  const useDetailResult = useDetail({
    data: { ID: record?.ID || null },
    updateUrl: ApiUrl.CreateServiceContract,
    windowKey,
    dataLoadUrl: ApiUrl.ServiceContractLoad,
    nameField: 'code',
    backPage: AppPages.ServiceContract,
    listUpdateEvent,
    validationFields,
    changingValidation: true,
    onLoadData: (response, setDataItem) => {
      setDataItem({ ...response, wish_date_from: currentDate });
      return true;
    },
    // detailUrl: Project.getPageUrl(AppPages.ServiceCallsDetails)
  });

  const { dataItem, setDataItem, updateData, onChangeData, validation, detailRef } = useDetailResult;

  useEffect(() => {
    if (isDuplicate && dataItem) {
      setDataItem({ ...dataItem, product_code: dataItem.code_product });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [setDataItem, dataItem?.ID]);


  const updateSuccessCallback = (response) => {
    // debugger;
    if (detailRef.current) {
      // if (isSat) {
      updateCallback(response);
      // } else {
      //   openPopup({
      //     type: PopupTypes.Confirm,
      //     title: <T>text.assign_subcontractor</T>,
      //     text: <T> message.assign_manually</T>,
      //     description: <T> message.assign_manually_description</T>,
      //     buttonYesText: <T>text.assign_manually</T>,
      //     buttonNoText: <T>text.assign_with_algorithm</T>,
      //     ...(!response?.hide_assign_to_previous_button && {
      //       buttons: [{
      //         text: <T>text.assign_to_the_previous_SAT</T>, callback: (onClose) => {
      //           autoAssign(response, 'assignToPreviousSat');
      //           onClose();
      //         }
      //       }]
      //     }),
      //     yesCallback: function () { openPicker(response); },
      //     noCallback: function () { autoAssign(response, 'assign'); }
      //   });
      // }

    }

  };

  const updateCallback = (data) => {
    closeAllPopups();
    history.push(Project.getPageUrl(AppPages.ServiceContractDetails, data.ID));
    window.location.reload();
  };

  const internal_serial_number = dataItem && dataItem.internal_serial_number;
  const openEquipmentCards = useCallback(() => {
    openPopup({
      windowKey: 'wndEquipmentCardForContract',
      title: <T>text.pick_equipment_card</T>,
      type: PopupTypes.EquipmentCardPickerForContract,
      className: PopupClasses.Large,
      fullScreen: false,
      bodyProps: { internal_serial_number, setDataItem },
    });
  }, [internal_serial_number, setDataItem, openPopup]);

  useEffect(() => {
    // if (!dataItem?.type_id) {
      setDataItem({ ...dataItem, type_id: 'S' });
    //}
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [dataItem?.type_id]);

  let serviceContractData = dataItem && <>
    <div className="form_container">
      <view auto_height=''>
        <div className='section_group'>
          <div className='sections'>
            <InputCont
              label='text.contract_type'
              inputProps={{
                disabled: true,
                type: 'select',
                valuelist: 'contract_type',
                name: 'type_id',
                value: dataItem.type_id,
                //value: "S",
                disableCach: true,
                placeholder: "",
                onChange: (e) => onChangeData({ target: { name: 'type_id', value: e } })
              }}
            />
          </div>

          <div className='sections '>
            <InputCont
              label='text.serial_number'
              inputProps={{
                name: 'internal_serial_number',
                value: dataItem.internal_serial_number,
                maxLength: 250,
                onChange: onChangeData,
                validation
              }}
              icon={<div effect="material" className="button mini " disabled={!dataItem.internal_serial_number} onClick={openEquipmentCards}><icon >search</icon></div>}

            />
          </div>
          <div className='sections '>
            <InputCont
              label='text.product_code'
              inputProps={{
                name: 'product_code',
                value: dataItem.product_code,
                maxLength: 250,
                onChange: onChangeData,
                validation,
                disabled: true
              }}
            />
          </div>
        </div>
        <div className='section_group'>
          <div className='sections wide'>
            <InputCont
              label='text.contract_template'
              inputProps={{
                type: 'select',
                valuelist: 'contract_templates',
                name: 'contract_template',
                restrictCustomValue: true,
                value: dataItem.contract_template || "",
                disableCach: true,
                parentValue: dataItem.product_code,
                placeholder: "",
                onChange: (e) => onChangeData({ target: { name: 'contract_template', value: e } })
              }}
            />
          </div>
          <div className='sections '>
            <InputCont
              label='text.subcontractor_contract_number'
              inputProps={{
                name: 'subcontractor_contract_number',
                value: dataItem.subcontractor_contract_number,
                maxLength: 250,
                onChange: onChangeData,
                validation,
              }}
            />
          </div>
        </div>
        <div className='section_group'>
          <div className='sections'>
            <InputCont
              label='text.start_date'
              inputProps={{
                validation,
                allowClear: false,
                type: 'date',
                name: 'start_date',
                dataItem: dataItem,
                setDataItem,
                dataField: 'start_date'
              }}
            />
          </div>
          <div className='sections'>
            <InputCont
              label='text.end_date'
              inputProps={{
                validation,
                allowClear: false,
                type: 'date',
                name: 'end_date',
                dataItem: dataItem,
                setDataItem,
                dataField: 'end_date'
              }}
            />
          </div>
          {!isSat && <div className='sections'>
            <InputCont
              label='text.subcontractor'
              inputProps={{
                type: "select",
                valuelist: 'filter_subconstractors',
                disableCach: true,
                name: 'societe_uuid',
                onChange: (e) => onChangeData({ target: { name: 'societe_uuid', value: e } }),
                value: dataItem.societe_uuid
              }} />
          </div>
          }
        </div>
        <div className='section_group'>
          <div className='sections wide'>
            <InputCont
              label='text.subject'
              inputProps={{
                name: 'contract_description',
                value: dataItem.contract_description,
                placeholder: "",
                maxLength: 250,
                onChange: onChangeData
              }}
            />
          </div>
        </div>
        <div className='section_group' style={{ padding: '20px' }}>
          <Attachments dataSource={attachments} setDataSource={setAttchments} />
        </div>
      </view>
    </div>
    <toolbar>
      <wrap>
        <action right=''>
          <div onClick={(e) => updateData(updateSuccessCallback, null, { additionalData: { attachments } })} className='button'><icon>save</icon><T>text.save</T></div>
        </action>
      </wrap>
    </toolbar>
  </>;

  return (<>
    {isDuplicate ?
      serviceContractData
      : <DetailView
        backUrl={Project.getPageUrl(AppPages.ServiceContract)}
        title={<T>text.create_new_contract</T>}
        sare>
        {
          serviceContractData
        }
      </DetailView>
    }
  </>);
}

export default connect(state => ({
  isDispatcher: state.role === UserRoles.DISPATCHER,
  isSat: state.role === UserRoles.USER
}), dispatch => ({ dispatch, openPopup: (props) => dispatch(openPopup(props)), closeAllPopups: () => dispatch(closeAllPopups()) }))(CreateContract);