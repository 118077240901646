import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PopupClasses, PopupTypes } from '../../components/Popup';
import { T } from '../../components/Translations';
import { ApiUrl, AppPages } from '../../project/Defines';
import { Project } from '../../project/Project';
import { EVENT_NAME } from '../../project/utilities';
import ListView from '../../Shared/ListView';
import { COLUMN_FORMAT, COLUMN_TYPE } from '../../Shared/ListView/helper';
import { openPopup } from '../../store/actions';
const listUpdateEvent = EVENT_NAME.FEE_LIST_UPDATE;
/**
 * @exports Invoice generation
used in Invoice generation menu for SAT */
function InvoiceGeneration(props) {
    const { openPopup } = props;
    const [detailProps] = useState({
        editTitle: 'text.fee_detail',
        popupType: PopupTypes.FeeDetail,
        windowKey: 'wndFeeDetail',
        readOnly: true
    });
    const [selectedRows, setSelectedRows] = useState([]);

    const openSupplierCodePopup = () => {
        openPopup({
            windowKey: 'wndSupplierInvoice',
            type: PopupTypes.SupplierInvoice,
            fullScreen: false,
            bodyProps: { selectedRows, listUpdateEvent }
        });
    };

    const columns = [
        {
            text: 'text.purchase_order_id',
            noSort: 'true',
            field: 'DocNum',
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.call_id',
            render: (text, record) => {
                return <wrap>
                    {(record.callData && <Link to={Project.getPageUrl(AppPages.ServiceCallsDetails, record.callData.ID)} style={{ color: "var(--main-color)" }}>
                        <text>{record.SCOrigin}</text>
                    </Link>) || <text>{record.SCOrigin}</text>}
                </wrap>;
            },
            noSort: 'true',
            field: 'SCOrigin',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.date',
            noSort: 'true',
            field: 'DocDate',
            type: COLUMN_TYPE.TEXT,
            format: COLUMN_FORMAT.DATE
        },
        {
            text: 'text.total',
            noSort: 'true',
            field: 'DocTotal',
            type: COLUMN_TYPE.TEXT
        }
    ];

    const openPicker = record => {
        openPopup({
            windowKey: "invoiceHistory",
            title: <T>text.download_generated_invoice</T>,
            type: PopupTypes.InvoiceHistory,

            className: PopupClasses.Large,
            fullScreen: false
        });
    };

    return <>
        <ListView
            {...props}
            columnConfig={columns}
            staticFilter={{ confirmed: true, status: 'O' }}
            apiUrl={ApiUrl.FeeList}
            noPaging
            keyField="DocNum"
            skipNewButton
            rowSelection={{
                selectedRowKeys: selectedRows.map(r => r.DocNum),
                onChange: (selectedRowKeys, selectedRecords) => {
                    setSelectedRows(selectedRecords);
                }
            }}
            detailProps={detailProps}
            listUpdateEvent={listUpdateEvent}
        />
        <toolbar>
            <wrap>
                <action right=''>
                    <div className="button accent" disabled={!selectedRows.length} onClick={() => openSupplierCodePopup()}>
                        <icon>download</icon><text>PDF</text></div>
                    <div className="button accent" onClick={openPicker}>
                        <icon>file</icon><text><T>text.download_generated_invoice</T></text></div>
                </action>
            </wrap>
        </toolbar>
    </>;
}


export default connect(null, dispatch => ({ openPopup: props => dispatch(openPopup(props)) }))(InvoiceGeneration);