import React from 'react';
import { connect } from 'react-redux';
import { DataTip } from '../../components/Translations';
import { PriorityBlock } from '../../Shared/Local/PriorityBlock';
import { ACCEPT, onAcceptRejectCall, REJECT } from '../ServiceCall/AcceptRejectCall';


function PlanningBlock(props) {
    const { data, listUpdateEvent, dispatch, ...restProps } = props;
    const onAccept = (e) => {
        onAcceptRejectCall({ type: ACCEPT, data, listUpdateEvent, dispatch });
    };

    const onReject = (e) => {
        onAcceptRejectCall({ type: REJECT, data, listUpdateEvent, dispatch });
    };

    return (
        <block horizontal="" {...restProps}>
            <item>
                <section horizontal="" space="">
                    <wrap>
                        <detail>
                            <text marquee="" code="" data-weight="500" data-size="medium">{data.call_id}</text>
                            <text marquee={data.subject}>{data.subject}</text>
                            <text marquee="" data-weight="700">{data.call_center}</text>
                        </detail>
                        <action right="">
                            <PriorityBlock priority={data.priority} />
                            {!data.accepted_at && !data.rejected_at && <><DataTip title='text.accept_service_job'><div onClick={onAccept} effect="material" className="button mini">
                                <icon>done</icon>
                            </div> </DataTip>
                                <DataTip title='text.reject_service_job'><div onClick={onReject} effect="material" className="button mini">

                                    <icon>close</icon>
                                </div>
                                </DataTip></>}
                        </action>
                    </wrap>
                </section>
            </item>
        </block>
    );
}


export default connect(null,
    dispatch => ({
        dispatch
    }))(PlanningBlock);