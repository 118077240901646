
import React from 'react';
import { PopupTypes } from '../../components/Popup';
import { T } from '../../components/Translations';
import { ApiUrl, priorities } from '../../project/Defines';
import { dispatchCustomEvent, EVENT_NAME } from '../../project/utilities';
import InputCont from '../../Shared/Form/InputCont';
import { PriorityBlock } from '../../Shared/Local/PriorityBlock';
import useDetail from '../../Shared/useDetail';
import { openPopup } from '../../store/actions';
import { connect } from 'react-redux';

export const ACCEPT = 'accept';
export const REJECT = 'reject';

export const onAcceptRejectCall = (props) => {
    const { type, listUpdateEvent, data, dispatch } = props;
    dispatch(openPopup({
        windowKey: "wndAcceptRejectCall",
        title: type === ACCEPT ? 'text.accept_service_job' : 'text.reject_service_job',
        type: PopupTypes.AcceptRejectCall,
        bodyProps: { type, data, listUpdateEvent },
        fullScreen: false //todo review
    }));
}


function AcceptRejectCall(props) {
    const { data, type, onClose, role } = props;
    const priority = data && priorities.find(x => x.id === data.priority);
    const { dataItem, setDataItem, updateData, onChangeData, validation } = useDetail({
        data: { ...data, appointment_date_at: data.appointment_date_at || (data.wish_date_from > new Date() && data.wish_date_from) },
        updateUrl: ApiUrl.ServiceCallUpdate,
        nameField: 'call_id',
        validationFields: type === ACCEPT ? ['appointment_date_at'] : null
    });

    console.log(role);

    return dataItem &&
        <> <toolbar transparent="">
            <text code=""
                priority={priority && priority.text.toLowerCase()}
                data-size="large"
                data_weight="500">
                {data.call_id}</text>
            <action right="">
                <PriorityBlock priority={data.priority} />
            </action>
        </toolbar>
            <view>
                <form>
                    {type === ACCEPT ? <>
                        <InputCont
                            label="text.appointment_date"
                            inputProps={{
                                type: 'datetime',
                                dataField: "appointment_date_at",
                                dataItem,
                                setDataItem,
                                //min: new Date(),
                                validation,
                                showTime: {
                                    hideDisabledOptions: true,
                                    showNow: false
                                    //disabledHours: () => {
                                    //    let arr = [...Array(24).keys()];
                                    //    arr.splice(9, 11);
                                    //    return arr;
                                    //}
                                },

                                //disabledTime: (d) => ({
                                //    disabledHours: () => {
                                //        let arr = [...Array(24).keys()];
                                //        arr.splice(9, 11);
                                //        return arr;
                                //    }
                                //})
                            }} />

                        <InputCont
                            label="text.duration_minutes"
                            inputProps={{ name: 'duration_minutes', value: dataItem.duration_minutes, onChange: onChangeData }} />

                        {role == 'sat' && <InputCont
                                label='text.technician'
                                inputProps={{
                                    name: 'technician',
                                    type: 'select',
                                    valuelist: 'technicians',
                                    additionalData: dataItem.societe_uuid,
                                    disableCach: true,
                                    value: dataItem.technician,
                                    placeholder: "",
                                    //validation,
                                    //disabled: ,
                                    onChange: (e) => onChangeData({ target: { name: 'technician', value: e } })
                                }}
                            />
                        }
                    </> :
                        <InputCont
                            label="text.reason"
                            inputProps={{ name: 'reject_reason', validation, type: 'textarea', value: dataItem.reject_reason, onChange: onChangeData }} />}

                    <toolbar>
                        <wrap>
                            <action right=''>
                                <div onClick={(e) => updateData((response) => {
                                    dispatchCustomEvent(EVENT_NAME.SERVICE_CALL_UPDATED, { type, data: response });
                                    onClose();
                                }, null, { overrideData: { ...dataItem, update_type: type } })} className='button'>{type === ACCEPT ? <> <icon>done</icon><T>text.accept</T></> : <> <icon>close</icon><T>text.reject</T></>}</div>
                                <div onClick={onClose} className='button'><icon>revert</icon><T>text.cancel</T></div>
                            </action>
                        </wrap>
                    </toolbar>
                </form>
            </view>
        </>

}


export default connect(state => ({
    role: state.role,
    culture: state.culture,
    canCreateCall: state.userData.canCreateCall
  }))(AcceptRejectCall);
