import React from 'react';
import { priorities } from '../../project/Defines';


export const PriorityBlock = ({ priority }) => {
    const priorityItem = priorities.find(p => p.id === priority);

    return priorityItem ? <status data="" priority={priorityItem.text.toLowerCase()}> {/* medium high' low*/}
        <text data-weight="500" light="">{priorityItem.text}</text>
        <icon>{priorityItem.arrow}</icon>
    </status> : <></>;

}