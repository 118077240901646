import React, { useEffect, useState } from 'react';
import { ApiUrl } from '../../../project/Defines';
import { COLUMN_TYPE } from '../../../Shared/ListView/helper';
import ListView from '../../../Shared/ListView';
import { hideLoader, showLoader } from '../../../Shared/Loader/index.js';
import { Ajax } from '../../../components/Ajax.js';

/**
 * ContractCoverage list of ServiceContract
 * @exports ContractCoverage
*/
export default function ContractCoverage(props) {
    const { dataItem } = props;
    const [ data, setData ] = useState([]);
    const windowKey = 'coverage';

    useEffect(() => {
        showLoader(windowKey);
        Ajax.get({
            url: ApiUrl.GetCoverageList,
            data: {},
            success: function (response) {
                setData(response.Data);
                hideLoader(windowKey);
            },
            error: function (response) {
                hideLoader(windowKey);
            }
        });
    }, []);
   

    const columnConfig = [
        {
            text: 'text.coverage',
            //field: 'coverage',
            noSort: 'true',
            render: (text, record) => {
                return (
                <wrap>
                    <text>{record?.coverage}</text>
                </wrap>
                );
            },
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.value',
            //field: 'coverage_value',
            noSort: 'true',
            render: (text, record) => {
                return (
                <wrap>
                    <text>{record?.coverage_value}</text>
                </wrap>
                );
            },
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
        }
    ];

    return (<>
        <ListView
            {...props}
            columnConfig={columnConfig}
            //apiUrl={ApiUrl.CoverageList}
            dataSource={data.filter( d => d.reseau_uuid == dataItem.reseau_uuid && d.template_code == dataItem.contract_template)}
            skipEdit
            skipNewButton={true}
        /></>

    );
}

