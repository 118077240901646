import React, { useEffect } from 'react';
import InputCont from '../../../Shared/Form/InputCont';

export default function Remark(props) {
    const { dataItem, onChangeData, setDataItem } = props;

    useEffect(() => {
      if (dataItem.status_id === "T") {
            setDataItem({...dataItem, termination_date: new Date()});
        }
        else {
            setDataItem({...dataItem, termination_date: ""});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataItem?.status_id])

    return (
        <div className='section_group'>
            <div className='sections wide'>
                <form>
                    <InputCont
                        label='text.remark'
                        inputProps={{
                            name: 'remark',
                            type: 'textarea',
                            value: dataItem.remark,
                            placeholder: "",
                            onChange: onChangeData
                        }}
                    />
                </form>
            </div>
        </div>
    );
}