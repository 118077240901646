import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { T } from '../../components/Translations';
import { ApiUrl, AppPages, Defines, UserRoles } from '../../project/Defines';
import { Project } from '../../project/Project';
import { dispatchCustomEvent, EVENT_NAME } from '../../project/utilities';
import DetailView from '../../Shared/DetailView';
import InputCont from '../../Shared/Form/InputCont';
import { TabStrip } from '../../Shared/TabStrip';
import useDetail from '../../Shared/useDetail';
import { openPopup } from '../../store/actions';
import Attachments from './Attachment';
import Customer from './Customer';
import Product from './Product';
import ServiceCallHistory from './ServiceCallHistory';
import Remark from './Remark';
import ContractCoverage from './Coverage';

const defaultValidationFields = [{ name: 'email', rules: [{ type: 'email' }] }];
const listUpdateEvent = EVENT_NAME.SERVICE_CONTRACT_LIST_UPDATE;

/**
 * @exports ServiceContractDetails
 */
function ServiceContractDetails(props) {
  const validationFields = defaultValidationFields;
  const [tabIndex, setTabIndex] = useState(0);
  const { id } = useParams();
  const { windowKey, data, role, canCreateCall } = props;
  //const isCallCenter = role === UserRoles.CALL_CENTER;
  //const isDispatcher = role === UserRoles.DISPATCHER;
  //const isAdmin = isCallCenter || isDispatcher;
  const isSat = role === UserRoles.USER;
  const disabled = false;

  const useDetailResult = useDetail({
    data: data || { ID: id !== "create" ? id : null },
    updateUrl: ApiUrl.ServiceContractUpdate,
    windowKey,
    dataLoadUrl: ApiUrl.ServiceContractLoad,
    nameField: 'code',
    deleteUrl: ApiUrl.ServiceContractDelete,
    backPage: AppPages.ServiceContract,
    listUpdateEvent,
    reloadEvent: EVENT_NAME.SERVICE_CONTRACT_RELOAD,
    validationFields,
    // onLoadData: (response, setDataItem) => {
    //   setDataItem({ ...response, created_at_local: moment.utc(response.created_at).local().format() });
    //   return true;
    // },
    changingValidation: true,

    // // detailUrl: Project.getPageUrl(AppPages.ServiceCallsDetails)
  });
  const { isNew, dataItem, setDataItem, updateData, detailRef, onChangeData, validation } = useDetailResult;

  //   const onCallResponded = (e) => {
  //     if (e.detail.type === ACCEPT) {
  //       dispatchCustomEvent(EVENT_NAME.SERVICE_CALL_RELOAD);
  //     } else if (e.detail.type === REJECT) {
  //       history.replace(Project.getPageUrl(AppPages.ServiceCalls));
  //     }
  //   };

  //   useCustomEvent(EVENT_NAME.SERVICE_CALL_UPDATED, onCallResponded);


  const updateSuccessCallback = (response) => {
    if (detailRef.current) {
      setDataItem(response);
    }

    dispatchCustomEvent([EVENT_NAME.SERVICE_CONTRACT_LIST_UPDATE, EVENT_NAME.SERVICE_CALL_LIST_UPDATE].join(" "), { action: 'update' });
  };

  return (<>
    {dataItem && <DetailView
      backUrl={Project.getPageUrl(AppPages.ServiceContract)}
      title={<>
        <text code="" data-weight="500" data-size="small">{dataItem.service_contract_id}</text>
        <action right=''>
          <div className='="sections'>
              <label className="checkbox" classic="">
                <input
                  type="checkbox"
                  name="commission_invoiced"
                  checked={dataItem.commission_invoiced}
                  onChange={onChangeData}
                />
                <text><T>text.contract_commission_invoiced</T></text>
                <box><check></check></box>
              </label>
          </div>
        </action>
      </>}
    >
      <div className="form_container">
        <view vertical="">
          <view mini="" scroll_wrapper=''>
            <div className='section_group'>
              <div className='sections'>
                <form>
                  <InputCont
                    label='text.contract_template'
                    inputProps={{
                      disabled: true,
                      name: 'contract_template_description',
                      value: dataItem.contract_template_description,
                      placeholder: "",
                      type:'textarea'
                    }}
                  />
                  <InputCont
                    label='text.status'
                    inputProps={{
                      //disabled: disabled || !isAdmin,
                      //type: !disabled && isAdmin ? 'select' : "text",
                      type: "select",
                      valuelist: 'contract_status',
                      name: 'status_id',
                      value: dataItem.status_id,
                      disableCach: true,
                      placeholder: "",
                      onChange: (e) => onChangeData({ target: { name: 'status_id', value: e } })
                    }}
                  />
                  <InputCont
                    label='text.type'
                    inputProps={{
                      disabled: dataItem?.type_id === "S",
                      //type: isAdmin ? 'select' : "text",
                      type: "select",
                      valuelist: 'contract_type',
                      name: 'type_id',
                      value: dataItem.type_id,
                      disableCach: true,
                      placeholder: "",
                      onChange: (e) => onChangeData({ target: { name: 'type_id', value: e } })
                    }}
                  />
                  {!isSat && <InputCont
                    label='text.subcontractor'
                    inputProps={{
                      type: "select",
                      valuelist: 'filter_subconstractors',
                      disableCach: true,
                      name: 'societe_uuid',
                      onChange: (e) => onChangeData({ target: { name: 'societe_uuid', value: e } }),
                      value: dataItem.societe_uuid
                    }} />
                  }
                  <InputCont
                    label='text.subcontractor_contract_number'
                    inputProps={{
                      name: 'subcontractor_contract_number',
                      value: dataItem.subcontractor_contract_number,
                      maxLength: 250,
                      onChange: onChangeData,
                      validation
                    }}
                  />
                  <InputCont
                    label='text.start_date'
                    inputProps={{
                      validation,
                      //disabled: disabled
                      allowClear: false,
                      type: 'date',
                      name: 'start_date',
                      dataItem,
                      setDataItem,
                      dataField: 'start_date',
                      // min: new Date()
                    }}
                  />
                  <InputCont
                    label='text.end_date'
                    inputProps={{
                      validation,
                      //disabled: disabled,
                      allowClear: false,
                      type: 'date',
                      name: 'end_date',
                      dataItem,
                      setDataItem,
                      dataField: 'end_date',
                      // min: new Date()
                    }}
                  />
                  <InputCont
                    label='text.created_date'
                    inputProps={{
                      disabled: true,
                      name: 'created_at',
                      type: "datetime",
                      dataField: 'created_at',
                      dataItem,
                    }}
                  />
                  <InputCont
                    label='text.termination_date'
                    inputProps={{
                      disabled: true,
                      name: 'termination_date',
                      type: "datetime",
                      dataField: 'termination_date',
                      format: Defines.Format.MomentDate,
                      dataItem,
                      setDataItem
                    }}
                  />
                  <InputCont
                    label='text.creator'
                    inputProps={{
                      disabled: true,
                      name: 'creator_user_name',
                      value: dataItem.creator_user_name,
                      placeholder: ""
                    }}
                  />
                </form>
              </div>
            </div>
          </view>
          <view>
            <TabStrip onChange={(selected) => setTabIndex(selected)} skipSecondaryTabs={isNew} selectedIndex={tabIndex}>
              <div title='text.customer' >
                <Customer {...useDetailResult} disabled={disabled} />
              </div>
              <div title='text.product'>
                <Product {...useDetailResult} disabled={disabled} />
              </div>
              <div title='text.remark'>
                <Remark {...useDetailResult} disabled={disabled} />
              </div>
              <div title='text.service_call_history'>
                <ServiceCallHistory {...useDetailResult} disabled={disabled} />
              </div>
              <div title='text.attachments' >
                <Attachments {...useDetailResult} disabled={disabled} />
              </div>
              <div title='text.coverage' skip={!canCreateCall || isSat}>
                <ContractCoverage {...useDetailResult} disabled={disabled} />
              </div>
            </TabStrip>
          </view>
        </view>

      </div>
      {!disabled && tabIndex < 3 &&
        <toolbar>
          <wrap>
            <action right=''>
              <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
            </action>
          </wrap>
        </toolbar>
      }
    </DetailView>
    }
  </>);
}

export default connect(state => ({
  role: state.role,
  canCreateCall: state.userData.canCreateCall
}), dispatch => ({
  dispatch,
  openPopup: (props) => dispatch(openPopup(props))
}))(ServiceContractDetails);