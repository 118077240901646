import React, { useState } from 'react';
import { PopupTypes } from '../../../components/Popup';
import { DataTip, T } from '../../../components/Translations';
import { ApiUrl } from '../../../project/Defines';
import { AppPages } from '../../../project/Defines.js';
import { EVENT_NAME } from '../../../project/utilities';
import { COLUMN_TYPE } from '../../../Shared/ListView/helper';
import ListView from '../../../Shared/ListView';


/**
 * ContractAttachments list of ServiceContract
 * @exports ContractAttachments
 */
export default function ContractAttachments(props) {
    const listUpdateEvent = EVENT_NAME.ATTACHMENTS_CONTRACT_LIST_UPDATE;
    const { dataSource, setDataSource, disabled } = props;
    const isNewCall = !!dataSource;
    const service_contract_uuid = props.dataItem && props.dataItem.ID;
    const [staticFilter] = useState({ service_contract_uuid });
    const [detailProps] = useState({
        newTitle: 'text.create_new_attachment',
        editTitle: 'text.edit_attachment',
        fullScreen: false,
        popupType: PopupTypes.ContractAttachmentDetails,
        windowKey: 'wndContractAttachmentDetails',
        parentData: props.dataItem || {},
        isNewCall,
        setDataSource
    });

    const columnConfig = [
        {
            field: 'attachment_name',
            width: 50,
            render: (text, record) => {
                const url = record && record.images && record.images[0] && record.images[0].image_url;
                const imageName = record && record.images && record.images[0] && (record.images[0].name && record.images[0].image_guid);
                return (
                    <wrap>
                        <a href={url} target='_blank' style={{ display: 'flex' }}><DataTip title={imageName}><icon>eye</icon></DataTip></a> {/*eslint-disable-line react/jsx-no-target-blank*/}
                    </wrap>
                );
            },

            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.attachment_name',
            field: 'attachment_name',
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
        }
    ];

    // const generateWarrantyCertificate = () => {
    //     const loaderName = 'generateWarrantyCertificate';
    //     showLoader(loaderName);
    //     Ajax.post({
    //         url: ApiUrl.GenerateWarrantyCertificate,
    //         data: { service_contract_uuid },
    //         success: function (response) {
    //             if (response) {
    //                 dispatchCustomEvent(listUpdateEvent);
    //             }
    //             hideLoader(loaderName);
    //         },
    //         error: function (response) {
    //             if (response.message) {
    //                 message.error(response.message);
    //             }
    //             hideLoader(loaderName);
    //         }
    //     });
    // };

    // const generateAssignmentReport = () => {
    //     const loaderName = 'generateAssignmentReport';
    //     showLoader(loaderName);
    //     Ajax.post({
    //         url: ApiUrl.GenerateAssignmentReport,
    //         data: { service_contract_uuid },
    //         success: function (response) {
    //             if (response) {
    //                 dispatchCustomEvent(listUpdateEvent);
    //             }
    //             hideLoader(loaderName);
    //         },
    //         error: function (response) {
    //             if (response.message) {
    //                 message.error(response.message);
    //             }
    //             hideLoader(loaderName);
    //         }
    //     });
    // };

    return (<>
        {/* {!isNewCall && < toolbar >
            <div className="button accent" onClick={generateWarrantyCertificate}>
                <text><T>text.warranty_certificate</T></text>
            </div>
            <div className="button accent" onClick={generateAssignmentReport}>
                <text><T>text.assignment_report</T></text>
            </div>
        </toolbar>} */}
        <ListView
            {...props}
            columnConfig={columnConfig}
            staticFilter={staticFilter}
            apiUrl={ApiUrl.ContractAttachmentsList}
            dataSource={dataSource}
            skipEdit
            listUpdateEvent={listUpdateEvent}
            detailProps={detailProps}
            deleteUrl={!disabled && ApiUrl.ContractAttachmentsDelete}
            skipNewButton={disabled}
            onCustomDelete={isNewCall && (rec => setDataSource(d => d.filter(at => at.ID !== rec.ID)))}
            newButtonTip={<T>text.create_new_attachment</T>}
            nameField='attachment_name'
            showRefreshButton={false}
            isSimple
            isChieldView
            listName={AppPages.ContractAttachments}
        /></>

    );
}

