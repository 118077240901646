/**
 * the formats for different types of data
 */
export const Defines = {
  Format: {
    MomentDate: "YYYY/MM/DD",
    MomentDateTime: "YYYY/MM/DD HH:mm",
    MomentTime: "HH:mm",
    MomentFullTime: "HH:mm:ss",

    PrintDate: "MM/DD/YYYY", //"MM/dd/yyyy",
    PrintDateTime: "MM/dd/yyyy HH:mm",
    FileNameDate: "yyyyMMdd",
    FileNameDateTime: "yyyy_MM_dd_HH-mm",

    ExpirationDate: "MMddyy",

    InputDate: "MM/dd/yyyy",
    InputTime: "h:mm tt",

    Time: "HH:mm",

    MonthDate: "MMMM, yyyy",

    Number0: "n0"
  },
  Messages: {
    //confirmDelete: "Delete",
    //cancelDelete: "Cancel",
    Yes: "Yes",
    No: "No",
  }
};

/**
 * shorthands to get page urls
 */
export const UserRoles = {
  USER: "sat",
  DISPATCHER: "dispatcher",
  CALL_CENTER: "callcenter"
};

export const AppPages = {
  Home: "home",

  Login: "login",
  Logout: "logout",
  Register: "register",
  Forgot: "forgot",
  NotFound: "notfound",
  Planning: "planning",
  ServiceCalls: "servicecalls",
  ServiceCallsDetails: "servicecallsdetails",
  ServiceCallsUnassigned: "servicecallsunassigned",
  // Documents: "documents",
  Fees: "fees",
  SpareParts: "spareparts",
  DPC: "dpc",
  Attachments: "attachments",
  AttachmentDetails: "attachmentdetails",
  InvoiceGeneration: "invoicegeneration",
  History: "history",
  Template: "template",
  TemplateDetail: "templatedetail",
  ServiceContract: "servicecontract",
  ServiceContractDetails: "servicecontractdetails",
  ContractAttachments: "contractattachments",
  ContractAttachmentDetails: "contractattachmentdetails"
};

export const StoreKeys = {
  UserToken: "USER-TOKEN",
  UserData: "userData",
  LoggedIn: "loggedIn",
  ListStates: "listStates",
  Culture: "culture",
  Role: "role",
  NetworkCode: "networkCode",
  ExpireTime: "exptime",
  EnableServiceContract: "enableServiceContract",
  DataMaintenance: "dataMaintenance"
};

/**
 * the url endings for each action of api
 */
export const ApiUrl = {
  Login: "/account/login",
  LoginByCode: "/account/loginCode",
  Logout: "/account/logout",
  Connect: "/wso/SSOConnect",
  ConnectAs: "/account/ConnectAs",
  ConnectAsUser: "/account/ConnectAsUser",
  Register: "/account/register",
  Forgot: "/account/forgot",

  ValueList: "/api/valuelist/getlist",
  ServiceCallList: "/api/ServiceCall/GetListData",
  GetUnassignedServicaCallsCount: "/api/ServiceCall/GetUnassignedServicaCallsCount",
  ResendAssignationEmail: "/api/ServiceCall/ResendAssignationEmail",
  ServiceCallLoad: "/api/ServiceCall/ResetFormData",
  ServiceCallUpdate: "/api/ServiceCall/UpdateFormData",
  CreateServiceCall: "/api/ServiceCall/CreateServiceCall",
  ServiceCallDelete: "/api/ServiceCall/DeleteFormData",

  ServiceCallExport: "/api/ServiceCall/ExportData",

  EquipmentCardList: "/api/ServiceCall/GetEquipmentCards",
  UpdateEquipmentCard: "/api/ServiceCall/UpdateEquipmentCard",
  FeeList: "/api/ServiceCall/GetFeeData",
  FeeLineLoad: "/api/ServiceCall/GetFeeLines",
  FeeUpdate: "/api/ServiceCall/UpdateFee",
  GenerateInvoice: "/api/ServiceCall/GenerateInvoice",
  DownloadInvoice: "/api/ServiceCall/DownloadInvoice",
  SparePartList: "/api/ServiceCall/GetSparePartData",
  SparePartLineLoad: "/api/ServiceCall/GetSparePartLines",
  SparePartUpdate: "/api/ServiceCall/UpdateSparePart",
  GetItemCodesBySerialNumber: "/api/ServiceCall/GetItemCodesBySerialNumber",

  DPCList: "/api/ServiceCall/GetDPCData",
  DPCDetail: "/api/ServiceCall/GetDPCDetail",
  UpdateDPC: "/api/ServiceCall/UpdateDPC",

  ConfirmDocument: "/api/ServiceCall/ConfirmDocument",
  RejectDocument: "/api/ServiceCall/RejectDocument",


  DefectCodeDetail: "/api/ServiceCall/GetDefectCode",
  DefectCodeUpdate: "/api/ServiceCall/UpdateDefectCode",

  SocieteList: "/api/Societe/GetListData",
  SocieteInvoiceGenerationDataList: "/api/SocieteInvoiceGenerationData/GetListData",
  SocieteUpdate: "/api/Societe/UpdateFormData",
  SocieteLoad: "/api/Societe/ResetFormData",
  SocieteDelete: "/api/Societe/DeleteFormData",
  GetSatAddress: "/api/Societe/GetSatAddress",
  GetSatName: "/api/Societe/GetSatName",
  GetSatEmail: "/api/Societe/GetSatEmail",
  GetSatDistance: "/api/Societe/GetSatDistance",

  AttachmentsList: "/api/Attachments/GetListData",
  AttachmentsLoad: "/api/Attachments/ResetFormData",
  AttachmentsUpdate: "/api/Attachments/UpdateAttachments",
  AttachmentsDelete: "/api/Attachments/DeleteFormData",
  GenerateWarrantyCertificate: "/api/Attachments/GenerateWarrantyCertificate",
  GenerateAssignmentReport: "/api/Attachments/GenerateAssignmentReport",

  HistoryList: "/api/History/GetListData",

  ServiceContractList: "/api/ServiceContract/GetListData",
  ServiceContractLoad: "/api/ServiceContract/ResetFormData",
  ServiceContractUpdate: "/api/ServiceContract/UpdateFormData",
  CreateServiceContract: "/api/ServiceContract/CreateServiceContract",
  ServiceContractDelete: "/api/ServiceContract/DeleteFormData",

  ServiceContractExport: "/api/ServiceContract/ExportData",

  ContractAttachmentsList: "/api/ContractAttachments/GetListData",
  ContractAttachmentsLoad: "/api/ContractAttachments/ResetFormData",
  ContractAttachmentsUpdate: "/api/ContractAttachments/UpdateAttachments",
  ContractAttachmentsDelete: "/api/ContractAttachments/DeleteFormData",

  GetCoverageList: "/api/Coverage/GetCoverageList",
  CoverageList: "/api/Coverage/GetListData",
  // CoverageLoad: "/api/Coverage/ResetFormData",
  // CoverageUpdate: "/api/Coverage/UpdateFormData",
  // CoverageDelete: "/api/Coverage/DeleteFormData",

  EquipmentCardListForContract: "/api/ServiceContract/GetEquipmentCards",
  UpdateEquipmentCardForContract: "/api/ServiceContract/UpdateEquipmentCard"
};

export const priorities = [
  { id: 'L', colorT: 'transparent', color: 'var(--low-color)', text: 'Low', arrow: 'arrow_down' },
  { id: 'M', colorT: 'transparent', color: 'var(--medium-color)', text: 'Medium', arrow: 'arrow_up' },
  { id: 'H', colorT: 'transparent', color: 'var(--high-color)', text: 'High', arrow: 'arrow_up' },
];
