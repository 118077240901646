import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { PopupTypes } from '../../components/Popup';
import { DataTip, T } from '../../components/Translations';
import { ApiUrl, AppPages, UserRoles } from '../../project/Defines';
import { Project } from '../../project/Project';
import { dispatchCustomEvent, EVENT_NAME, useCustomEvent } from '../../project/utilities';
import Badge from '../../Shared/Badge';
import DetailView from '../../Shared/DetailView';
import { FieldGroup } from '../../Shared/Form/formFields';
import InputCont from '../../Shared/Form/InputCont';
import { TabStrip } from '../../Shared/TabStrip';
import useDetail from '../../Shared/useDetail';
import { openPopup } from '../../store/actions';
import DPC from '../Documents/DPC';
import Fees from '../Documents/Fee';
import SpareParts from '../Documents/SparePart';
import { ACCEPT, onAcceptRejectCall, REJECT } from './AcceptRejectCall';
import Attachments from './Attachment';
import Customer from './Customer';
import DefectCodes from './DefectCodes';
import Product from './Product';
import Remark from './Remark';
import moment from 'moment';
import History from './History';
import { skipDocumenConfirmingActions } from '../Documents/commonFunctions';
import { Ajax } from '../../components/Ajax';
import { message } from 'antd';

const defaultValidationFields = [{ name: 'email', rules: [{ type: 'email' }] }];
const listUpdateEvent = EVENT_NAME.SERVICE_CALL_LIST_UPDATE;
const NETWORKCODE = "networkCode";
/**
 * @exports ServiceCallsDetails
 */
function ServiceCallsDetails(props) {
  const history = useHistory();
  const [validationFields, setValidationFields] = useState([...defaultValidationFields]);
  const [tabIndex, setTabIndex] = useState(0);
  const [feeData, setFeeData] = useState({});
  const [sparePart, setSparePart] = useState({});
  const { id } = useParams();
  const { windowKey, data, openPopup, dispatch, role, canCreateCall, enableServiceContract, culture } = props;
  const isCallCenter = role === UserRoles.CALL_CENTER;
  const isDispatcher = role === UserRoles.DISPATCHER;
  const isAdmin = isCallCenter || isDispatcher;
  const isSat = role === UserRoles.USER;
  const disabled = false;
  const networkCode = JSON.parse(localStorage.getItem(NETWORKCODE));

  const useDetailResult = useDetail({
    data: data || { ID: id !== "create" ? id : null },
    updateUrl: ApiUrl.ServiceCallUpdate,
    windowKey,
    dataLoadUrl: ApiUrl.ServiceCallLoad,
    nameField: 'code',
    deleteUrl: ApiUrl.ServiceCallDelete,
    backPage: AppPages.ServiceCalls,
    listUpdateEvent,
    reloadEvent: EVENT_NAME.SERVICE_CALL_RELOAD,
    validationFields,
    onLoadData: (response, setDataItem) => {
      setDataItem({ ...response, created_at_local: moment.utc(response.created_at).local().format() });
      return true;
    },
    changingValidation: true,

    // detailUrl: Project.getPageUrl(AppPages.ServiceCallsDetails)
  });
  const { isNew, dataItem, setDataItem, updateData, detailRef, onChangeData, validation } = useDetailResult;

  const onCallResponded = (e) => {
    if (e?.detail?.type === ACCEPT) {
      dispatchCustomEvent(EVENT_NAME.SERVICE_CALL_RELOAD);
    } else if (e?.detail?.type === REJECT) {
      history.replace(Project.getPageUrl(AppPages.ServiceCalls));
    }
  };

  useCustomEvent(EVENT_NAME.SERVICE_CALL_UPDATED, onCallResponded);

  const updateSuccessCallback = (response) => {
    if (detailRef.current) {
      setDataItem({ ...response, created_at_local: moment.utc(response.created_at).local().format() });
    }

    dispatchCustomEvent(listUpdateEvent, { action: 'update' });
    dispatchCustomEvent([EVENT_NAME.ATTACHMENTS_LIST_UPDATE, EVENT_NAME.FEE_LIST_UPDATE, EVENT_NAME.SPARE_PART_LIST_UPDATE, EVENT_NAME.DPC_LIST_UPDATE, EVENT_NAME.HISTORY_LIST_UPDATE].join(" "), { action: 'update' });
  };

  const unassignSubcontractor = e => {
    openPopup({
      windowKey: 'wndConfirmUnassign',
      type: PopupTypes.Confirm,
      title: <T >{'text.unassign_subcontractor'}</T>,
      text: <T> message.delete_confirm </T>,
      buttonYesText: <T>{"text.unassign"}</T>,
      buttonNoText: <T>{"text.cancel"}</T>,
      yesCallback: () => updateData(updateSuccessCallback, null, { overrideData: { ...dataItem, update_type: 'unassign' } })
    });
  };
  const onAccept = (e) => {
    onAcceptRejectCall({ type: ACCEPT, data: dataItem, listUpdateEvent, dispatch });
  };

  const onReject = (e) => {
    onAcceptRejectCall({ type: REJECT, data: dataItem, listUpdateEvent, dispatch });
  };

  const onSendEmail = () => {
    openPopup({
      windowKey: "wndSendEmail",
      title: 'text.send_email',
      type: PopupTypes.EmailForm,
      bodyProps: { record: dataItem },
      fullScreen: false
    });
  };

  const openCreateCallDetail = () => {
    openPopup({
      windowKey: "wndCreateCall",
      title: 'text.duplicate_call',
      type: PopupTypes.CreateCall,
      bodyProps: { record: dataItem, isDuplicate: true, isSat: isSat },
      fullScreen: true
    });
    // history.push(Project.getPageUrl(AppPages.ServiceCallsDetails) + '/create');
  };


  const { accepted_at, is_saved_status_open } = dataItem || {};

  useEffect(() => {
    setValidationFields(accepted_at && is_saved_status_open ? ["appointment_date_at", ...defaultValidationFields] : [...defaultValidationFields]);
  }, [accepted_at, is_saved_status_open]);

  const openDescriptionPopup = (e) => {
    if (dataItem.status == "Open" && e == "5") {
      openPopup({
        windowKey: "wndDescription",
        title: 'text.comment',
        type: PopupTypes.DescriptionForm,
        bodyProps: { record: {...dataItem, status_id: e}, setDataItem, dataItem, onChangeData, updateData, detailRef },
        fullScreen: false,
        //saveCallback: () => updateData(updateSuccessCallback, null, { overrideData: { ...dataItem}})//, description_sat: 'unassign' } })
      });
    }
  }

  const _filterData = { call_id: dataItem?.call_id }
  const filterData = JSON.stringify(_filterData);

  useEffect(() => {
    if (dataItem && (isDispatcher || isSat)) {
      Ajax.post({
        url: ApiUrl.FeeList,
        data: {filterData },
        success: function (response) {
          if (response) {
            const data = response.Data;
            const _feeData = data.filter(d => console.log(d.callData.call_id, dataItem?.call_id) || d.callData.call_id == dataItem?.call_id )[0];
            setFeeData(_feeData);
          }
        },
        error: function () {
         message.error("Error API Fee.")
        }
      });

      Ajax.post({
        url: ApiUrl.SparePartList,
        data: {filterData },
        success: function (response) {
          if (response) {
            const data = response.Data;
            const _sparePart = data.filter(d => console.log(d.callData.call_id, dataItem?.call_id) || d.callData.call_id == dataItem?.call_id )[0];
            setSparePart(_sparePart);
          }
        },
        error: function () {
         message.error("Error API Fee.")
        }
      });
    }
  }, [dataItem]);

  //const skipDocumenConfirmingActions = document => store.getState().role !== UserRoles.DISPATCHER || document.IsChangeRequested || (!hasValidCallData(document) || isDocumentConfirmed(document));
  const isNotValidated = skipDocumenConfirmingActions(feeData);  //store.getState().role !== UserRoles.DISPATCHER || feeData.IsChangeRequested || 
  const isNotValidatedSparePart = skipDocumenConfirmingActions(sparePart);

  const onStatusChange = (e) => {
    //debugger;
    if ((!isNotValidated || !isNotValidatedSparePart) && dataItem?.status_id == '1' && e == 2) {
      message.warning(<T>text.status_validation</T>);
      return; 
    }

    if (dataItem?.fictive_serial_number && e == 1) {
      message.warning(<T>message.status_validation_for_fictive_serial_number</T>);
      return; 
    }

    onChangeData({ target: { name: 'status_id', value: e } })
  }

  return (<>
    {dataItem && <DetailView
      backUrl={Project.getPageUrl(AppPages.ServiceCalls)}
      title={<>
        <text code="" data-weight="500" data-size="small">{dataItem.call_id}</text>
        <separator vertical=""></separator>
        <Badge 
          priority={dataItem.priority}
        />
        {(isAdmin || canCreateCall) && <action right=''>
          <div onClick={openCreateCallDetail} className='button primary'><T>text.duplicate</T></div>
          <div onClick={onSendEmail} className='button primary'><icon>mail</icon><T>text.send_email</T></div>
        </action>}
      </>}
    >
      <div className="form_container">
        <view vertical="">
          <view mini="" scroll_wrapper=''>
            <div className='section_group'>
              <div className='sections'>
                <form>
                  <InputCont
                    label='text.supplier_code'
                    inputProps={{ disabled: true, name: 'supplier_code', value: dataItem.supplier_code, placeholder: "" }}
                  />
                  <InputCont
                    label='text.call_center'
                    inputProps={{ disabled: true, name: 'call_center', value: dataItem.call_center, placeholder: "" }}
                  />
                  {enableServiceContract &&
                    <cont>
                      <Link 
                        style={{ padding: '12px', color: 'var(--main-color)', maxHeight: '40px', minHeight: '40px'}} 
                        to={Project.getPageUrl(AppPages.ServiceContractDetails, dataItem.service_contract_uuid)} target="_blank">
                        {dataItem.service_contract_id}
                      </Link>
                      <decor><label>
                        <text>
                          <line></line>
                          <wrap><span><T>text.contract</T></span></wrap></text>
                        <line></line></label>
                        <border></border>
                      </decor>
                    </cont>
                  }
                  {/* <InputCont
                    label='text.call_type'
                    inputProps={{
                      disabled: !isAdmin,
                      type: isAdmin ? 'select' : "text",
                      valuelist: 'call_type',
                      name: 'call_type_id', value: isDispatcher ? dataItem.call_type_id : dataItem.call_type,
                      placeholder: "",
                      onChange: (e) => onChangeData({ target: { name: 'call_type_id', value: e } })
                    }}
                  /> */}
                  <InputCont
                    label='text.call_type'
                    inputProps={{
                      type: 'select',
                      valuelist: 'call_type',
                      name: 'call_type_id',
                      value: dataItem.call_type_id,
                      disableCach: true,
                      placeholder: "",
                      onChange: (e) => onChangeData({ target: { name: 'call_type_id', value: e } })
                    }}
                  />
                  {/* <InputCont
                    label='text.problem_type'
                    inputProps={{
                      disabled: !isAdmin,
                      type: isAdmin ? 'select' : "text",
                      valuelist: 'problem_type',
                      name: 'problem_type_id', value: isAdmin ? dataItem.problem_type_id : dataItem.problem_type,
                      placeholder: "",
                      onChange: (e) => onChangeData({ target: { name: 'problem_type_id', value: e } })
                    }}
                  /> */}
                  <InputCont
                    label='text.problem_type'
                    inputProps={{
                      type: 'select',
                      valuelist: '_problem_type',
                      name: 'problem_type_id',
                      defaultValue: dataItem.problem_type,
                      //value: dataItem.call_type_id ? dataItem.problem_type : dataItem.problem_type_id,
                      //value: dataItem.problem_type_id,
                      parentValue: dataItem.call_type_id,
                      disabled: dataItem.call_type_id === "",
                      disableCach: true,
                      placeholder: "",
                      onChange: (e) => onChangeData({ target: { name: 'problem_type_id', value: e } })
                    }}
                  />
                  <InputCont
                    label='text.appointment_date'
                    inputProps={{
                      validation,
                      disabled: disabled || !(dataItem.is_saved_status_open && dataItem.accepted_at),
                      allowClear: false, type: 'datetime',
                      name: 'appointment_date_at',
                      dataItem,
                      setDataItem,
                      dataField: 'appointment_date_at',
                      min: new Date()
                    }}
                  />
                  <InputCont
                    label='text.subcontractor'
                    inputProps={{ disabled: true, name: 'subcontractor_name', value: dataItem.subcontractor_name }}
                    icon={(isDispatcher) && dataItem.subcontractor_name && 'close'}
                    iconClick={unassignSubcontractor}
                  />
                  <InputCont
                    label='text.subcontractor_phone'
                    inputProps={{ disabled: true, name: 'subcontractor_phone', value: dataItem.subcontractor_phone }}
                  />
                  <InputCont
                    label='text.technician'
                    inputProps={{
                        name: 'technician',
                        type: 'select',
                        valuelist: 'technicians',
                        additionalData: dataItem.societe_uuid,
                        disableCach: true,
                        value: dataItem.technician,
                        placeholder: "",
                        //validation,
                        disabled: isDispatcher,
                        onChange: (e) => onChangeData({ target: { name: 'technician', value: e } })
                    }}
                  />
                  
                  <InputCont
                    label='text.status'
                    inputProps={{
                      disabled: disabled || (!isAdmin && !dataItem.is_saved_status_open),
                      type: !disabled && (isAdmin || dataItem.is_saved_status_open) ? 'select' : "text",
                      valuelist: isAdmin ? 'call_status' : 'service_call_update_status_technician',
                      name: 'status_id',
                      // value: !disabled && (isAdmin || dataItem.is_saved_status_open) ? dataItem.status_id : dataItem.status,
                      value: !disabled && (isAdmin || dataItem.is_saved_status_open) ? dataItem.status_id : (dataItem.call_status_translation ? dataItem.call_status_translation : dataItem.status),
                      //value: !disabled && (isAdmin || dataItem.is_saved_status_open) ? dataItem.status_id : dataItem.call_status_translation,
                      additionalData: culture,
                      //record: !isNotValidated && dataItem.status_id == "1",
                      disableCach: true,
                      placeholder: "",
                      // onChange: 
                      onChange: (e) => {
                        if (isSat) {
                          openDescriptionPopup(e);
                        }
                        onStatusChange(e);
                      }
                    }}
                  />
                  <InputCont
                    label='text.created_date'
                    inputProps={{
                      disabled: true,
                      name: 'created_at',
                      type: "datetime",
                      dataField: 'created_at_local',
                      dataItem,
                    }}
                  />
                  {isSat && !dataItem.accepted_at && !dataItem.rejected_at && <FieldGroup><DataTip title='text.accept_service_job'><div onClick={onAccept} effect="material" className="button">
                    <text> <T>text.accept</T> </text> <icon>done</icon>
                  </div> </DataTip>
                    <DataTip title='text.reject_service_job'><div onClick={onReject} effect="material" className="button">
                      <text><T>text.reject</T></text>
                      <icon>close</icon>
                    </div>
                    </DataTip></FieldGroup>}
                </form>
              </div>
            </div>
          </view>
          <view>
            <TabStrip onChange={(selected) => setTabIndex(selected)} skipSecondaryTabs={isNew} selectedIndex={tabIndex}>
              <div title='text.customer' >
                <Customer {...useDetailResult} disabled={disabled} />
              </div>
              <div title='text.product'>
                <Product {...useDetailResult} disabled={disabled} />
              </div>
              <div title='text.remark'>
                <Remark {...useDetailResult} disabled={disabled} />
              </div>
              <div title='text.attachments'>
                <Attachments {...useDetailResult} disabled={disabled} />
              </div>
              <div title='text.fees' skip={!(isDispatcher || isSat)}>
                <Fees {...useDetailResult} disabled={disabled} />
              </div>
              <div title='text.spare_parts' skip={!(isDispatcher || isSat)}>
                <SpareParts {...useDetailResult} disabled={disabled} />
              </div>
              <div title='text.defect_code' skip={!(isDispatcher || isSat)}>
                <DefectCodes {...useDetailResult} disabled={disabled} />
              </div>
              <div title='text.dpc' skip={!(isDispatcher || isSat) || networkCode == "YGNITA"}>
                <DPC {...useDetailResult} disabled={disabled} />
              </div>
              <div title='text.history'>
                <History {...useDetailResult} disabled={disabled} />
              </div>
            </TabStrip>
          </view>
        </view>

      </div>
      {!disabled && tabIndex < 3 &&
        <toolbar>
          <wrap>
            <action right=''>
              <div 
                onClick={(e) => updateData(updateSuccessCallback)} 
                className='button'
                //disabled={!isNotValidated && dataItem.status_id == "2"}
              ><icon>save</icon><T>text.save</T></div>
            </action>
          </wrap>
        </toolbar>
      }
    </DetailView>
    }
  </>);
}

export default connect(state => ({
  role: state.role,
  canCreateCall: state.userData.canCreateCall,
  culture: state.culture,
  enableServiceContract: state.enableServiceContract
}), dispatch => ({
  dispatch,
  openPopup: (props) => dispatch(openPopup(props))
}))(ServiceCallsDetails);