import { message } from 'antd';
import 'devextreme-intl';
import Scheduler, { Resource } from 'devextreme-react/scheduler';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { loadMessages, locale } from 'devextreme/localization';
import esMessages from 'devextreme/localization/messages/es.json';
import frMessages from 'devextreme/localization/messages/fr.json';
import ptMessages from 'devextreme/localization/messages/pt.json';
import itMessages from 'devextreme/localization/messages/it.json';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Ajax } from '../../../components/Ajax';
import { T } from '../../../components/Translations';
import { ApiUrl, priorities, UserRoles } from '../../../project/Defines';
import { Project } from '../../../project/Project';
import { EVENT_NAME, useCustomEvent } from '../../../project/utilities';
import InputCont from '../../../Shared/Form/InputCont';
import Appointment from './Appointment';
import AppointmentTooltip from './AppointmentTooltip';


loadMessages(frMessages);
loadMessages(esMessages);
loadMessages(ptMessages);
loadMessages(itMessages);

const views = ['day', 'week', 'agenda'];

const Schedule = (props) => {
    const { setSelected, culture, role } = props;
    const isDispatcher = role === UserRoles.DISPATCHER;
    const isCallCenter = role === UserRoles.CALL_CENTER;
    const [appointments, setAppointments] = useState([]);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [editingProps] = useState({
        allowAdding: false,
        allowDeleting: false,
        allowResizing: !isCallCenter,
        allowDragging: !isCallCenter,
        allowUpdating: !isCallCenter
    });
    const [isMount, setIsMount] = useState(false);
    const [loaded, setLoaded] = useState(true);
    const [scheduleState, setScheduleState] = useState({ currentDate: new Date(), currentView: 'week' });
    const [scrollOffset, setScrollOffset] = useState(null);
    const [subContractors, setSubContractors] = useState([]);

    const loadData = useCallback(() => {
        const timeZoneOffset = scheduleState.currentDate.getTimezoneOffset();
        let startDate, endDate;
        if (scheduleState.currentView === 'day') {
            startDate = new Date(scheduleState.currentDate);
            endDate = new Date(scheduleState.currentDate);
        } else {
            startDate = Project.getMonday(scheduleState.currentDate);
            endDate = Project.getNextSunday(scheduleState.currentDate);
        }
        startDate.setHours(0 - timeZoneOffset / 60, 0 - timeZoneOffset % 60, 0);
        endDate.setHours(23 - timeZoneOffset / 60, 59 - timeZoneOffset % 60, 59);

        Ajax.post({
            url: ApiUrl.ServiceCallList,
            data: {
                request: { Page: 0, PageSize: 0 },
                filterData: JSON.stringify({ startDate, endDate, subContractors }),
            },
            success: function (response) {
                if (response && ref.current) {
                    let data = response.Data;
                    for (let i = 0; i < data.length; i++) {
                        data[i].text = `${data[i].call_id}`;
                    }
                    // let total = response.Total;
                    setAppointments(data);
                    setLoaded(true);
                }
            }
        });
    }, [scheduleState.currentDate, scheduleState.currentView, subContractors]);
    const repaint = useCallback(() => {
        const instance = schedulerRef.current && schedulerRef.current.instance;
        if (instance) {
            const schedulerScrollable = instance._workSpace.getScrollable();
            const offset = schedulerScrollable.scrollOffset();
            setScrollOffset(offset);
        }
    }, []);
    const onContentReady = useCallback(e => {
        if (scrollOffset || isFirstRender) {
            const instance = e.component && e.component.instance();
            if (instance) {
                if (scrollOffset) {
                    instance._workSpace.getScrollable().scrollTo(scrollOffset);
                }
                else {
                    instance.scrollTo(scheduleState.currentDate);
                }
                setIsFirstRender(false);
                setScrollOffset(null);
            }
        }
    }, [scheduleState, isFirstRender, scrollOffset]);
    const onAppointmentUpdated = useCallback((e) => {
        Ajax.post({
            url: ApiUrl.ServiceCallUpdate,
            data: { ...e.appointmentData, update_type: 'planning' },
            success: function (response) {
                message.success(<T>message.data_has_been_successfully_updated</T>);
            }
        });
    }, []);

    useEffect(() => {
        ref.current = true;
        //    loadData();
        return () => {
            ref.current = false;
        };
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        setIsMount(true);
    }, []);
    useEffect(() => {
        locale(culture);
    }, [culture]);
    useEffect(() => {
        loadData();
    }, [scheduleState, subContractors]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (scrollOffset) {
            const instance = schedulerRef.current && schedulerRef.current.instance;;
            instance && instance.repaint();
        }
    }, [scrollOffset]);// eslint-disable-line react-hooks/exhaustive-deps

    const ref = useRef(false);
    const schedulerRef = useRef(null);

    if (!isMount) {
        locale(culture);
    }
    useCustomEvent(EVENT_NAME.APPOINMENT_SCHEDULE_UPDATE, loadData);
    useCustomEvent(EVENT_NAME.APPOINMENT_SCHEDULE_REPAINT, repaint);

    return (
        <view vertical="" adaptive=""><view>
            <toolbar>
                <div className="button">
                    <text><T>text.schedule</T></text>
                </div>
                {(isDispatcher || isCallCenter) &&
                    <InputCont
                        inputProps={{
                            placeholder: 'text.filter_subcontractor',
                            type: 'multiselect',
                            valuelist: 'filter_subconstractors',
                            size: 'large',
                            disableCach: true,
                            value: subContractors,
                            onChange: e => setSubContractors([...e]),
                            searchOptions: {}
                        }} />}
            </toolbar>
            <view scheduler="" style={{ minWidth: '53px' }}>
                {loaded &&
                    <Scheduler
                        //  timeZone="America/Los_Angeles"
                        ref={schedulerRef}
                        dataSource={appointments}
                        startDateExpr='schedule_start_date'
                        endDateExpr='schedule_end_date'
                        appointmentComponent={Appointment}
                        appointmentTooltipComponent={AppointmentTooltip}
                        views={views}
                        defaultCurrentView={scheduleState.currentView}
                        defaultCurrentDate={scheduleState.currentDate}
                        firstDayOfWeek={1}
                        editing={editingProps}
                        onOptionChanged={(e) => {
                            if (e.name === 'currentDate' || e.name === 'currentView') {
                                setScheduleState({ ...scheduleState, [e.name]: e.value });
                            }
                        }}
                        onAppointmentUpdated={onAppointmentUpdated}
                        onAppointmentFormOpening={(data) => { data.cancel = true; }}
                        onAppointmentClick={data => { setSelected && setSelected(data.appointmentData); }}
                        onContentReady={onContentReady}
                    >
                        <Resource
                            dataSource={priorities}
                            fieldExpr='priority'
                            useColorAsDefault
                            colorExpr={'colorT'}
                        />
                    </Scheduler>}
            </view>
        </view>
            <gap></gap>
        </view>
    );
};

export default connect(state => ({ culture: state.culture, role: state.role }))(Schedule);