
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { T } from '../../components/Translations';
import { ApiUrl } from '../../project/Defines';
import InputCont from '../../Shared/Form/InputCont';
import { emailDefaultRegexp } from '../../Shared/Form/validation';
import useDetail from '../../Shared/useDetail';

const defaultValidationFields = [{
    name: 'email',
    rules: [{ type: "required" }, {
        type: "custom",
        validator: (data, value) => (!value || !value.length || value.every(e => emailDefaultRegexp.test(e))),
        message: 'message.all_values_should_be_emails'
    }]
}];
const EmailForm = (props) => {
    const { t } = useTranslation();
    const defaultSubject = t('text.resend_subject');
    const { record, onClose } = props;
    const [emailList, setEmailList] = useState([]);
    const onLoadData = (response, setDataItem) => {
        response && setEmailList([{ text: response }]);
        setDataItem({ call_id: record.ID, subject: defaultSubject.replace("$call_id", record.call_id) });
        return true;
    };

    const [validationFields, setValidationFields] = useState([]);
    const { dataItem, updateData, onChangeData, validation } = useDetail({
        data: { call_id: record.ID },
        updateUrl: ApiUrl.ResendAssignationEmail,
        validationFields: validationFields,
        changingValidation: true,
        dataLoadUrl: ApiUrl.GetSatEmail,
        dataLoadParams: { service_call_id: record.ID },
        onLoadData

    });

    const resend = dataItem && dataItem.resend;
    useEffect(() => {
        setValidationFields(resend ? defaultValidationFields : [...defaultValidationFields, 'subject', 'text']);
    }, [resend]);

    return dataItem &&
        <>
            <div className='form_container'>
                <div className='section_group'>
                    <div className='sections'>
                        <form>
                            <InputCont
                                label="text.email"
                                inputProps={{
                                    textAsValue: true,
                                    items: emailList,
                                    name: 'email',
                                    placeholder: ' ',
                                    validation,
                                    value: dataItem.email,
                                    onChange: (e) => onChangeData({ target: { name: 'email', value: e } }),
                                    type: 'select', mode: 'tags'
                                }} />

                            <label className="checkbox" classic="" style={{ margin: '0px 30px' }}>
                                <input
                                    type="checkbox"
                                    name="resend"
                                    //onChange={setRemember}
                                    onChange={onChangeData}
                                /><box><check></check></box><text><T>text.resend_assignment_email</T></text></label>
                            <div style={{ color: resend ? 'transparent' : '' }}>
                                <InputCont
                                    label="text.subject"
                                    inputProps={{ name: 'subject', disabled: resend, placeholder: ' ', validation, value: dataItem.subject, onChange: onChangeData }} />
                                <InputCont
                                    label="text.text"
                                    inputProps={{ name: 'text', disabled: resend, placeholder: ' ', validation, type: 'textarea', value: dataItem.text, onChange: onChangeData }} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <toolbar>
                <wrap>
                    <action right=''>
                        <div onClick={(e) => updateData((response) => {
                            onClose();
                        })} className='button'><icon>mail</icon><T>text.send</T></div>
                        <div onClick={onClose} className='button'><icon>close</icon><T>text.cancel</T></div>
                    </action>
                </wrap>
            </toolbar>
        </>;

};


export default EmailForm;

