import React, { useState } from 'react';
import { T } from '~/components/Translations';
import InputCont from '../../Shared/Form/InputCont.js';
import { EVENT_NAME, dispatchCustomEvent } from '../../project/utilities.js';
import useDetail from '../../Shared/useDetail/index.js';
import { ApiUrl } from '../../project/Defines.js';

/**
 * @exports DescriptionForm
 */
export default function DescriptionForm(props) {
    const [description, setDescription] = useState();

    const { record, onClose } = props;

    const { dataItem, updateData } = useDetail({
        data: { ...record },
        updateUrl: ApiUrl.ServiceCallUpdate,
        //nameField: 'call_id',
    });
    
    //const listUpdateEvent = EVENT_NAME.SERVICE_CALL_LIST_UPDATE;
   
    return dataItem &&
    <>
        <div className='form_container'>
            <div className='section_group'>
                <div className='sections'>
                    <form>
                        <InputCont
                            label="text.comment"
                            inputProps={{ 
                                name: 'description_sat', 
                                // disabled: true, 
                                placeholder: ' ', 
                                type: 'textarea', 
                                value: dataItem.description_sat,
                                onChange: e => setDescription(e.target.value)
                                //onChange: e => onChangeData({ target: { name: 'description_sat', value: e } })
                            }} 
                        />
                    </form>
                </div>
            </div>
        </div>
        <toolbar>
            <wrap>
                <action right='' //NOSONAR
                >
                    <div //NOSONAR
                        onClick={(e) => updateData((response) => {
                            dispatchCustomEvent(EVENT_NAME.SERVICE_CALL_UPDATED, { action: 'update' });
                            window.location.reload();
                            onClose();
                        }, null, { overrideData: { ...dataItem, description_sat: description } })}
                        //onClick={(e) => updateData(updateSuccessCallback)}
                        className='button'><icon>save</icon><T>text.save</T>
                    </div>
                    <div //NOSONAR
                        onClick={onClose}
                        className='button'><icon>close</icon><T>text.cancel</T>
                    </div>
                </action>
            </wrap>
        </toolbar>
    </>;
}