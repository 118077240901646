import React from 'react';
import { Link } from 'react-router-dom';
import { DataTip, T } from '../../components/Translations';
import { Defines } from '../../project/Defines';
import moment from 'moment';
import { Project } from '../../project/Project';
import { GetColumnSearchProps } from '../Filters/columnSearch';


export const COLUMN_TYPE = {
    TEXT: 'TEXT',
    ACTION: 'ACTION',
    IMAGE: 'IMAGE',
    CUSTOM: 'CUSTOM'
}

export const COLUMN_FORMAT = {
    DATE: 'DATE',
    DATETIME: 'DATETIME',
    NUMBER: 'NUMBER',
    CURRENCY: 'CURRENCY'
}

/**
 * generates columns based on given config acceptable to listview
 * @param {array} configArray
 * @param {func} onEdit
 * @param {func} onDelete
 * @param {object} editingRecord
 * @param {func} setEditingRecord
 * @param {func} onSave
 * @param {array} sorts
 */
export function getColumns(configArray, onEdit, onDelete, editingRecord, setEditingRecord, onSave, sorts, validation) {
    const columns = [];
    let sort = sorts && sorts[0];

    for (let i = 0; i < configArray.length; i++) {
        const { type, field, fixed, text, width, clientSort, sortDirections, sorter, noSort, commands, render, key, editable, editor, onCustomSave, onCustomCancel, onBeforeAction, readOnly, linkAppPage, onRenderLink, children, format, searchProperties, footerTitle } = configArray[i];
        let item;
        switch (type) {
            case COLUMN_TYPE.TEXT:
                item = {
                    title: <wrap><text><T>{text}</T></text></wrap>,
                    width: width,
                    fixed: fixed,
                    dataIndex: field,
                    key: key || field,
                    sortOrder: sort && sort.Member === field && (sort.SortDirection === 0 ? 'ascend' : (sort.SortDirection === 1 ? 'descend' : false)),
                    render: render ||
                        ((text, record) => {
                            if (format) {
                                switch (format) {
                                    case COLUMN_FORMAT.DATE:
                                        return <wrap><text>{record[field] ? moment(record[field], Defines.Format.MomentDate).format(Defines.Format.MomentDate) : ""}</text></wrap>;
                                    case COLUMN_FORMAT.DATETIME:
                                        return <wrap><text>{record[field] ? moment(record[field], Defines.Format.MomentDateTime).format(Defines.Format.MomentDateTime) : ""}</text></wrap>;
                                    case COLUMN_FORMAT.NUMBER:
                                        return <wrap><text>{record[field] ? parseInt(record[field]).toString() : ""}</text></wrap>;
                                    case COLUMN_FORMAT.CURRENCY:
                                        return <wrap><text>{record[field] ? parseFloat(record[field]).toLocaleString('fr-FR', {
                                            style: 'currency',
                                            currency: 'EUR',
                                        }) : ""}</text></wrap>;
                                    default:
                                        break;
                                }
                            }
                            return editable ? text
                                : (linkAppPage || onRenderLink) ? <wrap>
                                    <Link to={onRenderLink ? onRenderLink(record) : Project.getPageUrl(linkAppPage, record.ID)} style={{ color: "var(--main-color)" }}>
                                        <text>{text}</text>
                                    </Link>
                                </wrap>
                                    : <wrap title={record[field] ? record[field].toString() : ""} >
                                        <text>{text}</text>
                                    </wrap>;
                        }),
                    children,
                    footerTitle
                }
                if (!noSort) {
                    item.sorter = clientSort ? sorter || defaultSorter : () => { }
                }
                if (sortDirections) {
                    item.sortDirections = sortDirections
                }
                if (editable || (editingRecord && editingRecord.ID))
                    item.onCell = record => ({
                        editingRecord,
                        setEditingRecord,
                        record,
                        readOnly,
                        editable: editable,
                        dataIndex: field,
                        title: text,
                        validation,
                        ...editor
                    });
                if (searchProperties) {
                    const { filterDropdown, filterIcon, onFilter, onFilterDropdownVisibleChange } = GetColumnSearchProps(searchProperties);

                    item.filterDropdown = filterDropdown;
                    item.filterIcon = filterIcon;
                    item.onFilter = onFilter;
                    item.onFilterDropdownVisibleChange = onFilterDropdownVisibleChange;
                }
                break;

            case COLUMN_TYPE.IMAGE:
                item = {
                    title: <wrap><text><T>{text}</T></text></wrap>,
                    width: width,
                    fixed: fixed,
                    dataIndex: field,
                    key: key || field,
                    render: render ||
                        ((text, record) => {
                            return <wrap><img style={{ width: '200px', objectFit: 'contain', padding: '10px' }} src={text} alt="" /></wrap>;
                        }),
                    children
                }
                break;

            case COLUMN_TYPE.CUSTOM:
                item = {
                    title: <wrap><text><T>{text}</T></text></wrap>,
                    width: width,
                    fixed: fixed,
                    dataIndex: field,
                    key: key || field,
                    sortOrder: sort && sort.Member === field && (sort.SortDirection === 0 ? 'ascend' : (sort.SortDirection === 1 ? 'descend' : false)),
                    render: (render && ((text, record, index) => { return render(text, record, index, { onEdit: onEdit, onDelete: onDelete, editingRecord: editingRecord, setEditingRecord: setEditingRecord, onSave: onSave }) })) ||
                        ((text, record) => {
                            if (format) {
                                return <wrap><text>{record[field] ? moment(record[field], Defines.Format.MomentDate).format(format) : ""}</text></wrap>;
                            }
                            return editable ? text : onRenderLink ? <wrap><Link to={onRenderLink(record)} style={{ color: "var(--main-color)" }}><text>{text}</text></Link></wrap> : <wrap><text>{text}</text></wrap>;
                        }),
                    children,
                    footerTitle
                }
                if (searchProperties) {
                    const { filterDropdown, filterIcon, onFilter, onFilterDropdownVisibleChange } = GetColumnSearchProps(searchProperties);

                    item.filterDropdown = filterDropdown;
                    item.filterIcon = filterIcon;
                    item.onFilter = onFilter;
                    item.onFilterDropdownVisibleChange = onFilterDropdownVisibleChange;
                }
                break;

            case COLUMN_TYPE.ACTION:
                // debugger;
                const getOnClick = cmd => {
                    if (cmd.onClick) {
                        return cmd.onClick;
                    }
                    switch (cmd.command) {
                        case 'edit':
                            return onEdit;
                        case 'delete':
                            return onDelete;
                        default:
                            return null;
                    }
                };

                let commandList = commands;
                let editingCommands = [];
                if (!commandList) {
                    commandList = [];
                    if (!editingRecord) {
                        if (onEdit) { commandList.push({ command: 'edit', onClick: onEdit }) };
                        if (onDelete) { commandList.push({ command: 'delete', onClick: onDelete }) };
                    }
                }
                if (onEdit) {
                    editingCommands.push({
                        command: 'done', onClick: (record) => {
                            onCustomSave ? onCustomSave({ editingRecord, record, setEditingRecord, validation }) : onSave({ editingRecord, record });
                        }
                    })
                    editingCommands.push({ command: 'cancel', onClick: onCustomCancel ? () => onCustomCancel(setEditingRecord) : () => setEditingRecord(null) })
                };
                if (commandList.length || editingCommands.length) {
                    item = {
                        title: <wrap><text><T> {text || 'text.action'}</T></text></wrap>,
                        key: field || 'action',
                        width: width || Math.max((commandList.length || editingCommands.length) * 40, 64),
                        fixed: fixed || '',
                        render: (render && ((text, record) => { return render(text, record, commandList, editingCommands, editingRecord); })) || ((text, record) => (
                            <>
                                <elastic>
                                    <action hover="">
                                        {((editingRecord && (editingRecord.ID === record.ID) && editingCommands) || commandList).map((cmd) => {
                                            return record && !record['skip' + cmd.command] && (!cmd.skipCommandForRecord || !cmd.skipCommandForRecord(record)) ? <DataTip key={cmd.key ||cmd.command} title={cmd.tooltip}>
                                                <div
                                                command={cmd.command} className="button extra" onClick={() => {
                                                    if (onBeforeAction && !onBeforeAction(record, cmd.command)) {
                                                        return;
                                                    }
                                                    const onClick = getOnClick(cmd);
                                                    onClick && onClick(record);
                                                }}>
                                                {cmd.noIcon ? cmd.command : <icon edit="">{cmd.icon || cmd.command}</icon>}
                                                </div></DataTip> : <div key={cmd.key||cmd.command } ></div>
                                        })}
                                    </action>
                                </elastic>
                            </>
                        )),
                        children
                    };
                }
                break;
            default:
                break;
        }
        item && columns.push(item);
    }
    return columns;
}

const defaultSorter = (a, b, sortOrder) => {
    return a.nom.toLowerCase() > b.nom.toLowerCase() ? 1 : (a.nom.toLowerCase() < b.nom.toLowerCase() ? -1 : 0)
}