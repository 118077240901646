import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { PopupClasses, PopupTypes } from '../../../components/Popup';
import { T } from '../../../components/Translations';
import { ApiUrl, AppPages, Defines, UserRoles } from '../../../project/Defines';
import { Project } from '../../../project/Project';
import { EVENT_NAME } from '../../../project/utilities';
import ListView from '../../../Shared/ListView';
import { COLUMN_FORMAT, COLUMN_TYPE } from '../../../Shared/ListView/helper';
import { openPopup } from '../../../store/actions';
import moment from 'moment';


/**
 * @exports ServiceCalls
 */
function ServiceCalls(props) {
  const windowKey = 'wndSocietePicker';
  const { dispatch, unassigned, role, canCreateCall, dataItem } = props;
  const isDispatcher = role === UserRoles.DISPATCHER;
  const isCallCenter = role === UserRoles.CALL_CENTER;
  // if (props.unassigned) {
  //     console.log('unassigned')
  // }
  // else {
  //     console.log('assigned')
  // }

  // debugger;
  // console.log(props, dataItem.status_id);

  const history = useHistory();
  const _data = { internal_serial_number: dataItem.internal_serial_number };

  const openPicker = useCallback(record => {
    dispatch(openPopup({
      windowKey,
      title: <T>text.assign_subcontractor</T>,
      type: PopupTypes.SocietePicker,
      bodyProps: {
        ...{ ID: record.ID, data: record, isConnectAs: false }
        // updateData: (record) => { updateData(updateSuccessCallback, null, { overrideData: { ID: dataItem.ID, societe_uuid: record.uuid } }) }
      },
      className: PopupClasses.Large,
      fullScreen: false
    }));
  }, [dispatch]);

  const columns = [
    {
      text: 'text.call_id',
      // noSort: 'true',
      field: 'call_id',
      width: 80,
      fixed: 'left',
      type: COLUMN_TYPE.TEXT,
      linkAppPage: AppPages.ServiceCallsDetails
    },
    {
      text: 'text.subject',
      // noSort: 'true',
      field: 'subject',
      width: 300,
      fixed: 'left',
      render: (text, record) => {
        return (
          <wrap title={text}>
            <text light=''>{text}</text>
          </wrap>
        );
      },
      type: COLUMN_TYPE.TEXT
    },
    // {
    //   text: 'text.call_center',
    //   // noSort: 'true',
    //   field: 'call_center',
    //   width: 120,
    //   fixed: 'left',
    //   type: COLUMN_TYPE.TEXT
    // },
    {
      text: 'text.status',
      // noSort: 'true',
      field: 'status',
      width: 150,
      fixed: 'left',
      render: (text, record) => {
        return (
          <wrap>
            <group compact="">
              <badge outline="">{text}</badge>
            </group>
          </wrap>
        );
      },
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.code_product',
      // noSort: 'true',
      field: 'code_product',
      width: 140,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.internal_sn',
      // noSort: 'true',
      field: 'internal_serial_number',
      width: 120,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.item_name',
      // noSort: 'true',
      field: 'item_name',
      width: 160,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.call_type',
      // noSort: 'true',
      field: 'call_type',
      width: 100,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.problem_type',
      // noSort: 'true',
      field: 'problem_type',
      width: 130,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.zip',
      // noSort: 'true',
      field: 'code_postal',
      width: 60,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.city',
      // noSort: 'true',
      field: 'ville',
      width: 110,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.phone',
      // noSort: 'true',
      field: 'phone',
      width: 120,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.created_date',
      // noSort: 'true',
      field: 'created_at',
      render: (text) => <wrap> <text>{moment.utc(text).local().format(Defines.Format.MomentDateTime)}</text></wrap>,
      width: 140,
      format: COLUMN_FORMAT.DATETIME,
      type: COLUMN_TYPE.TEXT
    },
    // {
    //     text: 'text.priority',
    //     noSort: 'true',
    //     field: 'priority',
    //     render: (text, record) => {
    //         return (
    //             <wrap>
    //                 <text>
    //                     <Badge priority={record.priority} />
    //                 </text>
    //             </wrap>
    //         );
    //     },
    //     type: COLUMN_TYPE.TEXT
    // },
    {
      text: 'text.appointment_date',
      // noSort: 'true',
      field: 'appointment_date_at',
      width: 140,
      format: COLUMN_FORMAT.DATETIME,
      type: COLUMN_TYPE.TEXT
    },
    {
      text: 'text.subcontractor',
      // noSort: 'true',
      field: 'subcontractor_name',
      width: 140,
      type: COLUMN_TYPE.TEXT
    },
    {
      type: COLUMN_TYPE.ACTION,
      fixed: 'right',
      commands: unassigned && [
        { command: 'edit' },
        { command: 'assign', icon: 'wrench', tooltip: "text.assign_manually", onClick: (record) => openPicker(record) }
      ]
    }
  ];

  //const exportData = () => {
  //    showLoader(loaderName);
  //    Ajax.download({
  //        url: ApiUrl.ServiceCallExport,
  //        data: {},
  //        //autoDownload: false,
  //        success: (blob) => {
  //            FileSaver.saveAs(blob, "ServiceCalls.xlsx");
  //            hideLoader(loaderName);
  //        },
  //        error: () => {
  //            hideLoader(loaderName);
  //        }
  //    });
  //};


  const onNewServiceCall = () => {
    history.push('/servicecallsdetails/create', { _data });

    // debugger;
    // const windowKey = 'createCallWnd';
    // dispatch(openPopup({
    //     // component: CreateCall,
    //     windowKey,
    //     // title: <T>text.</T>,
    //     type: PopupTypes.CreateCall,
    //     fullScreen: true,
    //     bodyProps: { windowKey }
    // }));
  };

  return (
    <>
      { //{<toolbar>
        //    <action right="">
        //        <div
        //            className='button primary'
        //            title="text.export"
        //            onClick={exportData}
        //        >
        //            <icon>download</icon><text>Export</text>
        //        </div>
        //    </action>
        //</toolbar>}
      }
      <ListView
        {...props}
        skipNewButton={dataItem.status_id !== "A" || (!(isDispatcher || isCallCenter) && !canCreateCall) || unassigned}
        className='scroll_fix'
        columnConfig={columns}
        apiUrl={ApiUrl.ServiceCallList}
        detailUrl={Project.getPageUrl(AppPages.ServiceCallsDetails)}
        listUpdateEvent={EVENT_NAME.SERVICE_CALL_LIST_UPDATE}

        //listName={AppPages.ServiceCalls}
        staticFilter={{
          unassigned,
          serviceContractProduit_uuid: dataItem?.produit_uuid,
          serviceContract_internalSerialNumber: dataItem?.internal_serial_number
        }}

        onNewClick={onNewServiceCall}
      // exportUrl={ApiUrl.ServiceCallExport}
      />
    </>
  );
}


export default connect(state => ({
  role: state.role,
  canCreateCall: state.userData.canCreateCall
}))(ServiceCalls);