import React, { useCallback, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { connect } from "react-redux";
import DPCDetail from '../pages/Documents/DPC/detail';
import FeeDetail from '../pages/Documents/Fee/detail';
import RejectDocument from '../pages/Documents/RejectDocument';
import SparePartDetail from '../pages/Documents/SparePart/detail';
import AcceptRejectCall from '../pages/ServiceCall/AcceptRejectCall';
import AttachmentDetails from '../pages/ServiceCall/Attachment/detail';
import ConnectAsPicker from '../pages/ConnectAs/ConnectAsPicker';
import ServiceCallsDetails from '../pages/ServiceCall/detail';
import EquipmentCardPicker from '../pages/ServiceCall/Create/EquipmentCardPicker';
import EquipmentCardEdit from '../pages/ServiceCall/Create/EquipmentCardEdit';
import SocietePicker from '../pages/ServiceCall/SocietePicker';
import InvoiceHistory from '../pages/Invoice/invoiceHistory';
import EmailForm from '../pages/ServiceCall/EmailForm';
import SupplierInvoice from '../pages/Invoice/SupplierInvoice';
import { TemplateDetails, TemplateServiceCallDetails } from '../pages/Template';
import { closePopup } from './../store/actions';
import { T } from './Translations';
import CreateCall from '../pages/ServiceCall/Create/CreateCall';
import ServiceContractDetails from '../pages/ServiceContract/detail';
import CreateContract from '../pages/ServiceContract/Create/CreateContract';
import ContractAttachmentDetails from '../pages/ServiceContract/Attachment/detail';
import EquipmentCardPickerForContract from '../pages/ServiceContract/Create/EquipmentCardPicker';
import EquipmentCardEditForContract from '../pages/ServiceContract/Create/EquipmentCardEdit';
import DescriptionForm from '../pages/ServiceCall/DescriptionForm';

/**
 * specifies all the popups without navigation
 */
export const PopupTypes = {
  Confirm: "Confirm",
  Prompt: "Prompt",
  ServiceCallsDetails: "ServiceCallsDetails",
  EquipmentCardPicker: "EquipmentCardPicker",
  EquipmentCardEdit: "EquipmentCardEdit",
  EquipmentCardPickerForContract: "EquipmentCardPickerForContract",
  EquipmentCardEditForContract: "EquipmentCardEditForContract",
  SocietePicker: "SocietePicker",
  InvoiceHistory: "InvoiceHistory",
  ConnectAsPicker: "ConnectAsPicker",
  FeeDetail: "FeeDetail",
  SparePartDetail: "SparePartDetail",
  AttachmentDetails: "AttachmentDetails",
  DPCDetail: "DPCDetail",
  RejectDocument: "RejectDocument",
  AcceptRejectCall: "AcceptRejectCall",
  AcceptJob: "AcceptJob",
  RejectJob: "RejectJob",
  EmailForm: "EmailForm",
  DescriptionForm: "DescriptionForm",
  CreateCall: "CreateCall",
  SupplierInvoice: "SupplierInvoice",
  ServiceContractDetails: "ServiceContractDetails",
  CreateContract: "CreateContract",
  TemplateDetails: "TemplateDetails",
  TemplateServiceCallDetails: "TemplateServiceCallDetails",
  ContractAttachmentDetails: "ContractAttachmentDetails",
  ConfirmFictiveSerialNumber: "ConfirmFictiveSerialNumber"
};

export const PopupClasses = {
  Small: "small",
  Medium: "medium",
  Large: "large",
};

/**
 * the main function generating all opened popups
 * @param {object} props  contains props for fulscreen, class, all opened popups info
 */
function Popups(props) {
  let { popups, fullScreen } = props;
  popups = (popups && popups.filter(item => (item.fullScreen || false) === (fullScreen || false))) || [];
  let window_container = (popups.length > 0) ? "window_container open" : "window_container";
  return <div className={window_container}>
    {
      popups.map((item, i) => {
        let closeOutside = false;
        let ComponentVar;
        const { type, bodyProps, className } = item;
        let open_class = type + (item.fullScreen ? " open fullscreen" : " open");
        const onClose = (e) => props.closePopup(item.windowKey);
        let skipCloseClassNames = '';
        switch (type) {
          case PopupTypes.Confirm:
            return <InnerPopup key={type + i} className="open" onClose={onClose} closeOnOutsideClick={true}>
              <Confirm {...item} onClose={onClose} />
            </InnerPopup>;

          case PopupTypes.Prompt:
            return <InnerPopup key={type + i} className="open" onClose={onClose} closeOnOutsideClick={false}>
              <Prompt {...item} onClose={onClose} />
            </InnerPopup>;

          case PopupTypes.ConfirmFictiveSerialNumber:
            return <InnerPopup key={type + i} className="open" onClose={onClose} closeOnOutsideClick={false}>
              <Confirm {...item} onClose={onClose} />
            </InnerPopup>;

          case PopupTypes.ServiceCallsDetails:
            ComponentVar = ServiceCallsDetails;
            break;

          case PopupTypes.EquipmentCardPicker:
            ComponentVar = EquipmentCardPicker;
            break;

          case PopupTypes.EquipmentCardEdit:
            ComponentVar = EquipmentCardEdit;
            break;

          case PopupTypes.EquipmentCardPickerForContract:
            ComponentVar = EquipmentCardPickerForContract;
            break;

          case PopupTypes.EquipmentCardEditForContract:
            ComponentVar = EquipmentCardEditForContract;
            break;

          case PopupTypes.SocietePicker:
            ComponentVar = SocietePicker;
            break;

          case PopupTypes.InvoiceHistory:
            ComponentVar = InvoiceHistory;
            break;

          case PopupTypes.SupplierInvoice:
            ComponentVar = SupplierInvoice;
            break;

          case PopupTypes.ConnectAsPicker:
            ComponentVar = ConnectAsPicker;
            break;

          case PopupTypes.FeeDetail:
            ComponentVar = FeeDetail;
            break;

          case PopupTypes.AttachmentDetails:
            ComponentVar = AttachmentDetails;
            break;

          case PopupTypes.DPCDetail:
            ComponentVar = DPCDetail;
            break;

          case PopupTypes.SparePartDetail:
            ComponentVar = SparePartDetail;
            break;

          case PopupTypes.RejectDocument:
            ComponentVar = RejectDocument;
            break;

          case PopupTypes.AcceptRejectCall:
            ComponentVar = AcceptRejectCall;
            break;

          case PopupTypes.EmailForm:
            ComponentVar = EmailForm;
            break;

          case PopupTypes.DescriptionForm:
            // return <InnerPopup key={type + i} className="open" onClose={onClose} closeOnOutsideClick={true}>
            //   <DescriptionForm {...item} onClose={onClose} />
            // </InnerPopup>;
            ComponentVar = DescriptionForm;
            break;

          case PopupTypes.CreateCall:
            ComponentVar = CreateCall;
            break;

          case PopupTypes.ServiceContractDetails:
            ComponentVar = ServiceContractDetails;
            break;

          case PopupTypes.CreateContract:
            ComponentVar = CreateContract;
            break;

          case PopupTypes.ContractAttachmentDetails:
            ComponentVar = ContractAttachmentDetails;
            break;

          case PopupTypes.TemplateDetails:
            ComponentVar = TemplateDetails;
            break;
          case PopupTypes.TemplateServiceCallDetails:
            ComponentVar = TemplateServiceCallDetails;
            break;
          default:
            return <div key={i}></div>;
        }
        return <InnerPopup
          key={type + i}
          className={`${open_class} ${className || ''}`}
          onClose={onClose}
          skipCloseClassNames={skipCloseClassNames}
          closeOnOutsideClick={i === popups.length - 1 && closeOutside}>
          <PupupWrapper {...item} onClose={onClose}>
            <ComponentVar {...bodyProps} onClose={onClose} />
          </PupupWrapper>
        </InnerPopup>;
      })
    }
  </div>;
}
export default connect(state => ({
  popups: state.popups
}),
  dispatch => ({
    closePopup: (key) => dispatch(closePopup(key))
  })
)(Popups);

/**
 *custom hook for running logic on outer click of specified element with given ref
 * @param {func} onOuterClick
 * @param {object} innerRef the ref of element
 * @param {skipCloseClassNames} skipCloseClassNames
 * @param {bool} enable
 */
function useOuterClickNotifier(onOuterClick, innerRef, skipCloseClassNames, enable) {
  useEffect(
    () => {
      if (innerRef.current && enable) {
        document.addEventListener("click", handleClick);
      }

      return () => document.removeEventListener("click", handleClick);

      function handleClick(e) {
        innerRef.current &&
          (innerRef.current === e.target || !innerRef.current.contains(e.target)) && !(document.getElementsByClassName(skipCloseClassNames)[0] && document.getElementsByClassName(skipCloseClassNames)[0].contains(e.target)) &&
          onOuterClick(e);
      }
    },
    [enable, innerRef, skipCloseClassNames, onOuterClick] // invoke again, if inputs have changed
  );
}

/**
 * the outer warpper of all popups (for each one)
 * @param {any} props className, children, onClose, skipCloseClassNames, closeOnOutsideClick
 */
function InnerPopup(props) {
  const { className, children, onClose, skipCloseClassNames, closeOnOutsideClick } = props;
  const innerRef = useRef(null);

  useOuterClickNotifier(
    e => { onClose(); },
    innerRef,
    skipCloseClassNames,
    closeOnOutsideClick
  );

  return (
    <div ref={innerRef} className={className}>
      {children}
    </div>
  );
};

/**
 *custom confirm popup
 * @param {object} props contains title, text, yesCallback, noCallback, buttonYesText, buttonNoText, onClose
 */
function Confirm(props) {
  //debugger;
  const { title, text, description, yesCallback, noCallback,  buttonYesText, buttonNoText, buttons, onClose } = props;

  let onYesButtonClick = useCallback(function (e) {
    yesCallback && yesCallback(e);
    onClose();
  }, [yesCallback, onClose]);

  let onNoButtonClick = useCallback(function (e) {
    noCallback && noCallback();
    onClose();
  }, [noCallback, onClose]);

  return <div>
    <div>
      <div className="message_box">
        <div className="message_container">
          <icon large="">question</icon>
          <h3>{title}</h3>
          <p>{text}</p>
          {description && <i>{description}</i>}
        </div>
        <panel>
          <button type="button" effect="material" command="yes" className="button primary" onClick={onYesButtonClick}>
            <text>{buttonYesText}</text>
          </button>
          <separator vertical=""></separator>
          <button type="button" effect="material" command="no" className="button accent" onClick={onNoButtonClick}>
            <text>{buttonNoText}</text>
          </button>
          {buttons?.map((b, i) => <button key={b.text + i} type="button" effect="material" command="previous" className="button accent" onClick={() => b.callback && b.callback(onClose)}>
            <text>{b.text}</text>
          </button>)}
        </panel>
      </div>
    </div>
  </div>;
};

Confirm.defaultProps = {
  title: "",
  text: "",
  htmlText: false,
  buttonYesText: "OK",
  buttonNoText: "Cancel"
};

/**
 *custom prompt popup
 * @param {object} props contains title, text, yesCallback, noCallback, buttonYesText, buttonNoText, onClose
 */
function Prompt(props) {
  const { title, isPassword, placeholder, yesCallback, noCallback, buttonYesText, buttonNoText, onClose } = props;
  const [, setUpdating] = useState(false);
  const [value, setValue] = useState('');

  let onYesButtonClick = useCallback(function (e) {
    setUpdating(true);
    yesCallback && yesCallback(e, value);
    onClose();
  }, [yesCallback, onClose, value]);

  let onNoButtonClick = useCallback(function (e) {
    noCallback && noCallback();
    onClose();
  }, [noCallback, onClose]);

  return <div>
    <div>
      <div className="message_box">
        <div className="message_container">
          <h3>{title}</h3>

          <form>
            <div className="form_container">
              <div className="section_group">
                <div className="sections ">
                  <div className="form_fields">
                    <input type={isPassword ? "password" : "text"} value={value} placeholder={placeholder} onChange={(e) => setValue(e.target.value)} />
                  </div>
                </div>
              </div>
            </div>
          </form>

        </div>
        <panel>
          <button type="button" effect="material" command="yes" className="button primary" onClick={onYesButtonClick}>
            <text>{buttonYesText}</text>
          </button>
          <separator vertical=""></separator>
          <button type="button" effect="material" command="no" className="button accent" onClick={onNoButtonClick}>
            <text>{buttonNoText}</text>
          </button>
        </panel>
      </div>
    </div>
  </div>;
};

Prompt.defaultProps = {
  title: "",
  isPassword: false,
  placeholder: '',
  buttonYesText: "OK",
  buttonNoText: "Cancel"
};

/**
 * default carcas for all popups except confirm
 * @param {any} props
 */
function PupupWrapper(props) {
  const { title, fullScreen, showHeader, children, cancelCallback, onClose } = props;
  let onCancel = useCallback(function (e) {
    cancelCallback && cancelCallback(e);
    onClose();
  }, [onClose, cancelCallback]);

  //  const dragable = !fullScreen;

  let innerContent =
    <div className="box no-cursor">
      {showHeader &&
        <header>
          {
            fullScreen &&
            <>
              <div effect='material' className='button white mini back' command='close' onClick={onCancel}>
                <icon>left</icon>
              </div>
              <separator vertical=""></separator>
            </>
          }

          <div style={{ width: 'calc(100% - 20px)' }}>
            <strong className="cursor"><div><p><T>{title}</T></p></div></strong>

          </div>

          {
            !fullScreen &&
            <div effect="material" className="button white mini close" command="close" onClick={onCancel}>
              <icon>close</icon>
            </div>
          }
        </header>
      }
      <div>
        <div className="window_cont">
          {children}
        </div>
      </div>
    </div>;

  if (!fullScreen) {

    innerContent = <Draggable handle="strong">
      {innerContent}
    </Draggable>;
  }
  return innerContent;
}

PupupWrapper.defaultProps = {
  showHeader: true,
  fullScreen: true,
};