import React from 'react';
import { priorities } from '../../../project/Defines';

export default function Appointment(model) {
    const { appointmentData } = model.data;
    let priorityItem = priorities.find(p => p.id === appointmentData.priority) || { text:""}
    return (
        <view empty="" priority={priorityItem && priorityItem.text.toLowerCase()} >
            <appointment_details>
                {appointmentData.accepted_at && <icon status="">done</icon>}

                <text code="" data-weight="500">
                    {appointmentData.call_id}
                </text>
                <text data-weight="500" style={{ whiteSpace: 'break-spaces' }} >
                    {appointmentData.call_type}
                </text>
            </appointment_details>
            </view >
    );
}


