import React from 'react';
import { priorities } from '../../../project/Defines';
import { Project } from '../../../project/Project';

export default function AppointmentTooltip(model) {
    const { appointmentData } = model.data;
    let priorityItem = priorities.find(p => p.id === appointmentData.priority) || {}
    return <div className="dx-tooltip-appointment-item">
        <div className="dx-tooltip-appointment-item-marker">
            <div className="dx-tooltip-appointment-item-marker-body" style={{ background: priorityItem.color }}><icon>{priorityItem.arrow}</icon></div>
        </div>

        <div className="dx-tooltip-appointment-item-content">
            <div className="dx-tooltip-appointment-item-content-subject">{appointmentData.call_id}</div>

            <div test="" className="dx-tooltip-appointment-item-content-date">
                <group compact=""><badge>{appointmentData.accepted_at && <icon green="">done</icon>}<text>{Project.formatTime(appointmentData.schedule_start_date)} - {Project.formatTime(appointmentData.schedule_end_date)}</text></badge></group>
            </div>
        </div>
    </div>
}


