import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { T } from '~/components/Translations';
import { ApiUrl, priorities } from '../../project/Defines.js';
import { dispatchCustomEvent, EVENT_NAME } from '../../project/utilities.js';
import ListView from '../../Shared/ListView';
import { COLUMN_TYPE } from '../../Shared/ListView/helper';
import { PriorityBlock } from '../../Shared/Local/PriorityBlock.js';
import useDetail from '../../Shared/useDetail/index.js';




/**
 * the societe List
 * @exports SocietePicker
 */
export default function SocietePicker(props) {
    const listUpdateEvent = EVENT_NAME.SERVICE_CALL_LIST_UPDATE;
    const { t } = useTranslation("defines");
    const windowKey = 'wndSocietePicker';
    const { data, updateCallback } = props;

    const { updateData, detailRef, closePopup } = useDetail({
        data: data,
        updateUrl: ApiUrl.ServiceCallUpdate,
        listUpdateEvent,
        windowKey
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            closePopup(windowKey)
        }
        if (updateCallback) {
            updateCallback(data);
        } else {
            dispatchCustomEvent(listUpdateEvent, { action: 'update' });
            dispatchCustomEvent(EVENT_NAME.CALL_ASSIGNED);
        }
    }, [detailRef, closePopup, listUpdateEvent, data, updateCallback]);

    const priority = data && priorities.find(x => x.id === data.priority);
    const columnConfig = [
        {
            text: 'text.name',
            field: 'raison_sociale',
            noSort: 'true',
            render: (text, record) => {
                return (
                    <wrap>
                        <elastic>
                            {record.skill_count > 0 ? <><text green=''>{text}</text>  <badge private='' filled=''><b>{record.skill_count}</b>
                                <T> text.skills</T></badge></> : <text>{text}</text>}

                        </elastic>
                        {/* <text style={{color: record.skill_count > 0 ? 'green' : ''}} >{text}</text> */}
                    </wrap>
                );
            },
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.distance',
            field: 'raison_sociale',
            noSort: 'true',
            render: (text, record) => {
                return (
                    <wrap>
                        <elastic>
                            {record.distance != null ? <text>{record.distance.text}</text> : <text>n/a</text>}

                        </elastic>
                        {/* <text style={{color: record.skill_count > 0 ? 'green' : ''}} >{text}</text> */}
                    </wrap>
                );
            },
            type: COLUMN_TYPE.TEXT,
        },
        {
            type: COLUMN_TYPE.ACTION,
            width: t('width.action_select'),
            render: (text, record) => <elastic>
                <action hover="">
                    <div
                        effect="material"
                        className="button accent"
                        onClick={() => updateData(updateSuccessCallback, null, { overrideData: { id: data.ID, societe_uuid: record.ID, update_type: 'assign' } })}
                    >
                        <text><T>text.select</T></text>
                    </div>
                </action>
            </elastic>
        },
    ];

    return (
        <>
            <toolbar transparent="">
                <text code=""
                    priority={priority && priority.text.toLowerCase()}
                    data-size="large"
                    data_weight="500">
                    {data.call_id}</text>
                <action right="">
                    <PriorityBlock priority={data.priority} />
                </action>
            </toolbar>
            <ListView
                {...props}
                isSimple
                search={{ field: 'search' }}
                skipNewButton
                columnConfig={columnConfig}
                apiUrl={ApiUrl.SocieteList}
                staticFilter={{ call_id: data.ID }}
                noPaging
            />
        </>
    );
}