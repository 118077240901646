import React from 'react';
import { priorities } from '../../project/Defines';
import { useTranslation } from 'react-i18next';

export default function Badge(props) {
    const { priority } = props;
    const { t } = useTranslation();

    const priorityItem = priorities.find(p => p.id === priority) || {};

    console.log(priorityItem.text);

    return (
        <group>
            <badge style={{backgroundColor: priorityItem.color}} filled="">{t('priority.' + priorityItem.text)}</badge>
            {/* <badge {...{ [text.toLowerCase()]: '' }} filled="">{priorityItem.text}</badge> */}
        </group>
    );
}