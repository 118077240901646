import React from 'react';
import { connect } from 'react-redux';
import store from 'store';
import { logout } from '../store/actions/index.js';
import { useEffect } from 'react';
import { Ajax } from '../components/Ajax';
import { ApiUrl, AppPages, StoreKeys } from '../project/Defines';
import { Project } from '../project/Project.js';
//import { Project } from '../project/Project';

/**
 * the Logout Component
 * @exports Logout
 */
function Logout(props) {
    useEffect(() => {
        Ajax.post({
            url: ApiUrl.Logout,
            data: {},
            success: function (response) {
                let history = props.history;
                store.remove(StoreKeys.UserToken);
                props.dispatch(logout());
                if (response.redirectUrl) {
                    window.location.href = response.redirectUrl;
                } else {
                    history.push(Project.getPageUrl(AppPages.Login));
                }
            }
        })
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return <div />;
}

export default connect()(Logout);