import React, { useCallback, useEffect, useRef } from 'react';
import { message, Spin } from 'antd';
import { AppPages, ApiUrl } from '../project/Defines.js';
import { Project } from '../project/Project.js';
import { Ajax } from '../components/Ajax';
import { connect } from 'react-redux';
import { login, logout, clearListStates } from '../store/actions/index.js';
import { T } from '../components/Translations.js';

function getParams() {
  return new URLSearchParams(window.location.search);
}

/**
 * the LoginCallback Component where user authorization occurs
 * @exports LoginCallback
 */
function LoginCallback(props) {
  const { history } = props;
  const query = getParams();
  const code = query.get("code") || "";
  const state = query.get("state") || "";
  const session_state = query.get("session_state") || "";
  const ref = useRef(false);

    useEffect(() => {
        ref.current = true;
        doLogin();
        return () => {
            ref.current = false;
        }

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const doLogin = useCallback(() => { //NOSONAR
      if (code){
        Ajax.post({
            url: ApiUrl.LoginByCode,
            data: {
                code: code,
                state: state,
                session_state: session_state,
            },
            success: function (response) {
                if (response && ((response.status && response.status === 200) || response.redirectUrl)) {
              const { userToken, enableServiceContract, userName, culture, role, networkCode, canCreateCall, accessToken, logoutUrl, redirectUrl } = response;
                    props.dispatch(clearListStates());
                    props.dispatch(login({
                      userToken,
                      userName,
                      culture,
                      role,
                      enableServiceContract,
                      networkCode,
                      userData: {
                        userName: userName,
                        canCreateCall: canCreateCall,
                        accessToken, logoutUrl
                      }
                    }));
                    if (redirectUrl && redirectUrl !== Project.getPageUrl(AppPages.Login)) {
                      history.push(redirectUrl);
                    }
                    else {
                      history.push(Project.getPageUrl(AppPages.Home));
                    }
                } else {
                    if (response) {
                        message.error(response.title || <T>message.error_api</T>)
                    } else {
                        message.error(<T>message.error_api</T>)
                    }

                }
            },
            error: function (response) {
                if (response) {
                    message.error(response.message || <T>message.error_api</T>)
                } else {
                    message.error(<T>message.error_api</T>)
                }

                if (response.redirectUrl) {
                    window.location.href = response.redirectUrl;
                }

            }
        })
      } else {
        props.dispatch(logout());
        history.push(Project.getPageUrl(AppPages.Login));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, history, code]);




    return <><div preloader="" style={{ "margin": "25% auto" }}><Spin size="large" /></div></>;
}

export default connect(state => ({ isLoggedIn: state.isLoggedIn, networks: state.networks, network: state.network, culture: state.culture }), null)(LoginCallback);