import { message } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { T } from '~/components/Translations';
import { Ajax } from '../../components/Ajax.js';
import { ApiUrl, AppPages } from '../../project/Defines.js';
import { Project } from '../../project/Project.js';
import ListView from '../../Shared/ListView';
import { COLUMN_TYPE } from '../../Shared/ListView/helper';
import { hideLoader, showLoader } from '../../Shared/Loader/index.js';
import { clearListStates, closeAllPopups, login } from '../../store/actions/index.js';


/**
 * the societe List
 * @exports ConnectAsPicker
 */
function ConnectAsPicker(props) {
    const { t } = useTranslation("defines");
    const history = useHistory();
    const { login, clearListStates, closeAllPopups } = props;
    const columnConfig = [
        {
            text: 'text.name',
            field: 'raison_sociale',
            noSort: 'true',
            render: (text, record) => {
                return (
                    <wrap>
                        <elastic>
                            {record.skill_count > 0 ? <><text green=''>{text}</text>  <badge private='' filled=''><b>{record.skill_count}</b>
                                <T> text.skills</T></badge></> : <text>{text}</text>}

                        </elastic>
                    </wrap>
                );
            },
            type: COLUMN_TYPE.TEXT,
        },
        {
            type: COLUMN_TYPE.ACTION,
            width: t('width.action_connect_as'),
            render: (text, record) => <elastic>
                <action hover="">
                    <div
                        effect="material"
                        className="button accent"
                        onClick={() => {
                            const connectAsLoader = 'CONNECT_AS_LOADER';
                            showLoader(connectAsLoader);
                            Ajax.post({
                                url: ApiUrl.ConnectAsUser,
                                data: { societe_uuid: record.ID },
                                success: function (response) {
                                    if (response && ((response.status && response.status === 200) || response.redirectUrl)) {
                                        const redirectUrl = response.redirectUrl;
                                        clearListStates();
                                        closeAllPopups();
                                        login({
                                          userToken: response.userToken,
                                          userName: response.userName,
                                          culture: response.culture,
                                          role: response.role,
                                          enableServiceContract: response.enableServiceContract,
                                          networkCode: response.networkCode,
                                          userData: {
                                            userName: response.userName,
                                            canCreateCall: response.canCreateCall,
                                            portalName: response.portalName,
                                            portalUrl: response.portalUrl,
                                            internalConnectAs: response.internalConnectAs
                                          }
                                        });
                                        if (redirectUrl && redirectUrl !== Project.getPageUrl(AppPages.Login)) {
                                            history.push(redirectUrl);
                                        }
                                        else {
                                            history.push(Project.getPageUrl(AppPages.Home));
                                        }
                                    } else {
                                        if (response) {
                                            message.error(response.title || <T>message.error_api</T>)
                                        } else {
                                            message.error(<T>message.error_api</T>)
                                        }
                                    }
                                    hideLoader(connectAsLoader)
                                },
                                error: function () { hideLoader(connectAsLoader); }
                            });
                        }
                        }
                    >
                        <text><T>text.connect_as</T></text>
                    </div>
                </action>
            </elastic>
        },
    ];

    return (
        <ListView
            {...props}
            isSimple
            search={{ field: 'search' }}
            skipNewButton
            columnConfig={columnConfig}
            apiUrl={ApiUrl.SocieteList}
        />
    );
}


export default connect(null,
    dispatch => ({
        clearListStates: () => dispatch(clearListStates()),
        closeAllPopups: () => dispatch(closeAllPopups()),
        login: payload => dispatch(login(payload))
    }))(ConnectAsPicker);