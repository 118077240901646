import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Ajax } from '../../../components/Ajax';
import { T } from '../../../components/Translations';
import { ApiUrl, UserRoles } from '../../../project/Defines';
import { dispatchCustomEvent, EVENT_NAME } from '../../../project/utilities';
import InputCont from '../../../Shared/Form/InputCont';
import useDetail from '../../../Shared/useDetail';
import { isDocumentReadOnly } from '../commonFunctions';

const defaultValidationFields = ['return_type', 'dpc_user_type'];
const listUpdateEvent = EVENT_NAME.DPC_LIST_UPDATE;
/**
 * @exports DPCDetail
 */
export default function DPCDetail(props) {
    const isDispatcher = useSelector(s => s.role) === UserRoles.DISPATCHER;
    const { data } = props;
    const { windowKey } = props.detailProps;
    const [isTypeFirstLoad, setIsTypeFirstLoad] = useState(true);
    const [readonly, setReadonly] = useState(true);
    const [validationFields, setValidationFields] = useState(defaultValidationFields);
    const [satAddressData, setSatAddressData] = useState(null);
    const { callData } = data;
    const { dataItem, setDataItem, updateData, closePopup, onChangeData, validation } = useDetail({
        data: callData,
        windowKey,
        dataLoadUrl: ApiUrl.DPCDetail,
        listUpdateEvent,
        dataLoadParams: { service_job_uuid: callData.ID, ...data },
        updateUrl: ApiUrl.UpdateDPC,
        additionalData: { callID: callData.ID, ...data },
        validationFields,
        changingValidation: true,
        onLoadData: (response, setDataItem) => {
            if (response && response.questions && response.questions.length > 0) {
                setValidationFields([...defaultValidationFields, ...response.questions.map((q, i) => {
                    q.name = `questions[${i}].answer`;
                    return q.name;
                })]);
            }
        }
    });

    const updateSuccessCallback = useCallback((response) => {
        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        closePopup();
    }, [closePopup]);

    const setSatAddress = useCallback(() => {
        (satAddressData && setDataItem({ ...dataItem, ...satAddressData })) ||
            Ajax.get({
                url: ApiUrl.GetSatAddress,
                data: {
                    service_call_id: callData.ID
                },
                success: function (response) {
                    if (!response.HasError) {
                        setSatAddressData(response);
                        setDataItem({ ...dataItem, ...response })
                    }
                }
            })
    }, [dataItem, setDataItem, satAddressData, callData.ID]);

    const lineInfo = (dataItem && dataItem.lines && dataItem.lines.length > 0 && dataItem.lines[0]) || {};

    useEffect(() => {
        if (dataItem) {
            if (!isTypeFirstLoad) {
                switch (dataItem.dpc_user_type) {
                    case 'SAT':
                        setSatAddress();
                        break;
                    case 'Client':
                        setDataItem({
                            ...dataItem, ...{
                                adresse: callData.adresse,
                                adresse2: callData.adresse_complement,
                                customer_name: callData.customer_name,
                                ville: callData.ville,
                                pays: callData.pays,
                                code_postal: callData.code_postal,
                                phone: callData.phone
                            }
                        });
                        break;
                    default:
                        setDataItem({
                            ...dataItem, ...{
                                adresse: '',
                                adresse2: '',
                                customer_name: '',
                                ville: '',
                                pays: '',
                                code_postal: '',
                                phone: ''
                            }
                        });
                        return;

                }
            } else {
                setIsTypeFirstLoad(false);
            }
        }
    }, [dataItem && dataItem.dpc_user_type]);// eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        setReadonly(isDocumentReadOnly(data));
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        {dataItem &&
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections '>
                            <form>
                                    <InputCont
                                        label='text.request_id'
                                        inputProps={{
                                            name: 'DocNum',
                                            value: data.DocNum,
                                            onChange: onChangeData,
                                            disabled: true
                                        }}
                                    />
                            </form>
                        </div>
                    </div>


                    <div className='section_group'>
                        <div className='sections wide'>
                            <form>
                                {dataItem.questions && dataItem.questions.map((i, index) => {
                                    const isSelect = i.possible_answers && i.possible_answers.length > 0;
                                    const name = i.name;
                                    return <InputCont
                                        key={i.ID}
                                        label={i.question}
                                        skipTranslate
                                        inputProps={{
                                            name,
                                            type: isSelect ? 'select' : 'text',
                                            items: (isSelect && i.possible_answers.map(pv => ({ text: pv, value: pv }))) || '',
                                            value: i.answer,
                                            onChange: isSelect ? e => onChangeData({ target: { value: e, name } }) : onChangeData,
                                            disabled: readonly,
                                            maxLength: !isSelect ? 255 : null,
                                            validation
                                        }}
                                    />
                                })}
                            </form>
                        </div>
                    </div>

                <div className='section_group'><div className='sections extra'><div className="form_fields"><div className="form_separator"></div></div></div></div>

                    <div className='section_group'>
                        <div className='sections '>
                            <form>
                                <InputCont
                                    label='text.return_type'
                                    inputProps={{
                                        name: 'return_type',
                                        type: 'select',
                                        valuelist: 'dpc_return_types',
                                        value: dataItem.return_type,
                                        onChange: e => onChangeData({ target: { name: 'return_type', value: e } }),
                                        validation,
                                        disabled: readonly
                                    }}
                                />
                                <InputCont
                                    label='text.customer_name'
                                    inputProps={{
                                        name: 'customer_name',
                                        value: dataItem.customer_name,
                                        onChange: onChangeData,
                                        disabled: readonly
                                    }}
                                />
                                <InputCont
                                    label='text.street'
                                    inputProps={{
                                        name: 'adresse',
                                        value: dataItem.adresse,
                                        onChange: onChangeData,
                                        disabled: readonly
                                    }}
                                />
                                <InputCont
                                    label='text.street_2'
                                    inputProps={{
                                        name: 'adresse2',
                                        value: dataItem.adresse2,
                                        onChange: onChangeData,
                                        disabled: readonly
                                    }}
                                />
                                <InputCont
                                    label='text.street_3'
                                    inputProps={{
                                        name: 'adresse3',
                                        value: dataItem.adresse3,
                                        onChange: onChangeData,
                                        disabled: readonly
                                    }}
                                />
                                </form>
                        </div>

                        <div className='sections '>
                            <form>
                                    {(dataItem.return_type && <InputCont
                                        label={dataItem.return_type}
                                        inputProps={{
                                            name: 'dpc_user_type',
                                            type: 'select',
                                            valuelist: 'dpc_user_types',
                                            value: dataItem.dpc_user_type,
                                            disableCach: true,
                                            additionalData: dataItem.return_type,
                                            onChange: e => onChangeData({ target: { name: 'dpc_user_type', value: e } }),
                                            validation,
                                            restrictCustomValue: true,
                                            disabled: readonly
                                        }}
                                    />) }
                                    <InputCont
                                        label='text.code_postal'
                                        inputProps={{
                                            name: 'code_postal',
                                            value: dataItem.code_postal,
                                            onChange: onChangeData,
                                            disabled: readonly
                                        }}
                                    />
                                    <InputCont
                                        label='text.city'
                                        inputProps={{
                                            name: 'ville',
                                            value: dataItem.ville,
                                            onChange: onChangeData,
                                            disabled: readonly
                                        }}
                                    />
                                    <InputCont
                                        label='text.country'
                                        inputProps={{
                                            name: 'pays',
                                            type: 'select',
                                            valuelist: 'pays',
                                            value: dataItem.pays,
                                            disableCach: true,
                                            onChange: e => onChangeData({ target: { name: 'pays', value: e } }),
                                            searchOptions: { noAccent: true },
                                            disabled: readonly
                                        }}
                                    />
                                    <InputCont
                                        label='text.telephone'
                                        inputProps={{
                                            name: 'phone',
                                            value: dataItem.phone,
                                            onChange: onChangeData,
                                            disabled: readonly
                                        }}
                                    />
                                </form>
                        </div>
                    </div>

                <div className='section_group'><div className='sections extra'><div className="form_fields"><div className="form_separator"></div></div></div></div>

                    {isDispatcher ? <div className='section_group'>
                        <div className='sections '>
                                <form>
                                    <InputCont
                                        label='text.account'
                                        inputProps={{
                                            name: 'Account',
                                            value: lineInfo.Account,
                                            disabled: true
                                        }}
                                    />
                                    <InputCont
                                        label='text.business_unit'
                                        inputProps={{
                                            name: 'BusinessUnit',
                                            value: lineInfo.BusinessUnit,
                                            disabled: true
                                        }}
                                    />
                                </form>
                        </div>
                        <div className='sections '>
                            <form>
                                <InputCont
                                    label='text.cost_center'
                                    inputProps={{
                                        name: 'CostCenter',
                                        value: lineInfo.CostCenter,
                                        disabled: true
                                    }}
                                />

                                <InputCont
                                    label='text.destination'
                                    inputProps={{
                                        name: 'Destination',
                                        value: lineInfo.Destination,
                                        disabled: true
                                    }}
                                />
                                </form>
                        </div>
                    </div> : <></>}
                </div>

                {!readonly && < toolbar >
                    <wrap>
                        <action right=''>
                            <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>

                        </action>
                    </wrap>
                </toolbar>}
            </form>
        }
    </>);
}

