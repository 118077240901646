import { message } from "antd";
import React, { useCallback } from "react";
import { Ajax } from "../../components/Ajax";
import { PopupTypes } from "../../components/Popup";
import { T } from "../../components/Translations";
import { ApiUrl, UserRoles } from "../../project/Defines";
import { dispatchCustomEvent } from "../../project/utilities";
import { hideLoader, showLoader } from "../../Shared/Loader";
import store from "../../store";

export const isDocumentConfirmed = document => document.Confirmed === 'Y';

export const isDocumentReadOnly = document => {
    const { callData } = document;
    return isDocumentConfirmed(document) || !callData || !callData.is_saved_status_open || !callData.societe_uuid || !callData.accepted_at;
};
export const isLineReadOnly = line => {
    return line.LineStatus === 'C';
};

export const hasValidCallData = document => !!document?.callData;

export const skipDocumenConfirmingActions = document => { 
    // debugger;
    return store.getState().role !== UserRoles.DISPATCHER || 
        document?.IsChangeRequested || 
        (!hasValidCallData(document) || isDocumentConfirmed(document))
};

export function useConfirmActions(props) {
    const { openPopup, type, listUpdateEvent, docType } = props;

    const onConfirm = useCallback((record) => {
        const confirmLoaderName = 'DocumentConfirm';
        openPopup({
            windowKey: 'wndConfirmDocumentConfirm',
            type: PopupTypes.Confirm,
            title: <T args={[record.DocNum]}>message.approve_document</T>,
            text: <T>message.delete_confirm</T>,
            buttonYesText: <T>text.approve</T>,
            buttonNoText: <T>text.cancel</T>,
            yesCallback: function () {
                showLoader(confirmLoaderName);
                Ajax.post({
                    url: ApiUrl.ConfirmDocument,
                    data: { ...record, type, callID: record.callData.ID, docType },
                    success: function (response) {
                        if (response) {
                            dispatchCustomEvent(listUpdateEvent);
                            document.location.reload();
                        }
                        hideLoader(confirmLoaderName);
                    },
                    error: function (response) {
                        if (response.message) {
                            message.error(response.message);
                        }
                        hideLoader(confirmLoaderName);
                    }
                })


            }
        });
    }, [openPopup, listUpdateEvent, type, docType]);

    const onReject = useCallback((record) => {
        openPopup({
            windowKey: "wndRejectDocument",
            title: 'text.reject_document',
            type: PopupTypes.RejectDocument,
            bodyProps: { record, type, docType, listUpdateEvent },
            fullScreen: false
        });
    }, [openPopup, listUpdateEvent, type, docType]);


    return { onConfirm, onReject };
}