import React from 'react';
import InputCont from '../../../Shared/Form/InputCont';

export default function Customer(props) {
    const { dataItem, onChangeData, disabled, validation } = props;

    return (
        <div className='section_group'>
            <div className='sections '>
                <form>
                    <InputCont
                        label='text.customer_name'
                        inputProps={{ disabled, name: 'customer_name', value: dataItem.customer_name, placeholder: "", onChange: onChangeData }}
                    />
                    <cont>
                        <separator horizontal=""></separator>
                    </cont>
                    <InputCont
                        label='text.street'
                        inputProps={{ disabled, name: 'adresse', value: dataItem.adresse, placeholder: "", onChange: onChangeData }}
                    />
                    <InputCont
                        label='text.street_number'
                        inputProps={{ name: 'street_number', value: dataItem?.street_number, placeholder: "", onChange: onChangeData, validation }}
                    />
                    <InputCont
                        label='text.floor_door_number'
                        inputProps={{ name: 'door_number', value: dataItem?.door_number, placeholder: "", onChange: onChangeData, validation }}
                    />
                    <InputCont
                        label='text.city'
                        inputProps={{ disabled, name: 'ville', value: dataItem.ville, placeholder: "", onChange: onChangeData }}
                    />
                    <InputCont
                        label='text.zip'
                        inputProps={{ disabled, name: 'code_postal', value: dataItem.code_postal, placeholder: "", onChange: onChangeData }}
                    />
                </form>
            </div>
            <div className='sections '>
                <form>
                    <InputCont
                        label='text.email'
                        inputProps={{
                            validation,
                            disabled,
                            name: 'email',
                            value: dataItem.email,
                            placeholder: "",
                            onChange: onChangeData
                        }}
                    />
                    <InputCont
                        label='text.phone_1'
                        inputProps={{ disabled, name: 'phone', value: dataItem.phone, placeholder: "", onChange: onChangeData }}
                    />
                    <InputCont
                        label='text.phone_2'
                        inputProps={{ disabled, name: 'phone2', value: dataItem.phone2, placeholder: "", onChange: onChangeData }}
                    />
                </form>
            </div>
        </div>
    );
}