import { message } from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { T } from '~/components/Translations.js';
import { Ajax } from '../../components/Ajax';
import { ApiUrl, AppPages } from '../../project/Defines.js';
import { Project } from '../../project/Project.js';
import { hideLoader, showLoader } from '../../Shared/Loader';
import { clearListStates, login } from '../../store/actions/index.js';



function getParams() {
  return new URLSearchParams(window.location.search);
}

/**
 * the ConnectAs where user authorization occurs
 * @exports Login
 */
function ConnectAs(props) {
  const { history, login, clearListStates } = props;
  const query = getParams();
  const params = useParams();
  const redirectUrl = query.get("redirectUrl") || "";

  useEffect(() => {
    doLogin();

  }, []);// eslint-disable-line react-hooks/exhaustive-deps



  const doLogin = () => {
    const connectAsLoader = 'CONNECT_AS_LOADER';
    showLoader(connectAsLoader);
    Ajax.get({
      url: ApiUrl.ConnectAs + "/" + params.token,
      data: {
        ReturnUrl: redirectUrl
      },
      success: function (response) {
        if (response && ((response.status && response.status === 200) || response.redirectUrl)) {
          clearListStates();
          login({
            userToken: response.userToken,
            userName: response.userName,
            culture: response.culture,
            role: response.role,
            enableServiceContract: response.enableServiceContract,
            networkCode: response.networkCode,
            userData: {
              userName: response.userName,
              canCreateCall: response.canCreateCall,
              portalName: response.portalName,
              portalUrl: response.portalUrl
            }
          });

          if (redirectUrl && redirectUrl !== Project.getPageUrl(AppPages.Login)) {
            history.push(redirectUrl);
          }
          else {
            history.push(Project.getPageUrl(AppPages.Home));
          }
        } else {
          if (response) {
            message.error(response.title || <T>message.error_api</T>);
          } else {
            message.error(<T>message.error_api</T>);
          }
        }
        hideLoader(connectAsLoader);
      },
      error: function () { hideLoader(connectAsLoader); }
    });

  };


  return <></>;

};

export default connect(state => ({ isLoggedIn: state.isLoggedIn }),
  dispatch => ({
    clearListStates: () => dispatch(clearListStates()),
    login: payload => dispatch(login(payload))
  }))(ConnectAs);