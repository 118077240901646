import React, { useEffect, useRef, useState } from 'react';
import { ApiUrl } from '../../../project/Defines.js';
import { T } from '../../../components/Translations';
import { Defines } from '../../../project/Defines';
import InputCont from '../../../Shared/Form/InputCont';
import useDetail from '../../../Shared/useDetail';
import { dispatchCustomEvent } from '../../../project/utilities.js';
import Autocomplete from "react-google-autocomplete";
import { Ajax } from '../../../components/Ajax.js';
import { connect } from 'react-redux';
import { openPopup } from '../../../store/actions/index.js';

const NETWORKCODE = "networkCode";
const ATLANTICIBERICA = "ATLIBE"; // ES
const YGNISItALIA = "YGNITA"; // IT
const GAPORTUGAL = "ATLPOR"; // PT

/**
 * the societe List
 * @exports EquipmentCardCreate
 */
function EquipmentCardEdit(props) {
  const { detailProps, onClose, data } = props;
  const { internal_serial_number, listUpdateEvent } = detailProps;
  const [searchValue, setSearchValue] = useState('');
  const [place, setPlace] = useState(null);
  const [extraItems, setExtraItems] = useState(null);
  const timeOut = useRef(null);

  const networkCode = JSON.parse(localStorage.getItem(NETWORKCODE));
  let defaultCountry = "";

  const { dataItem, setDataItem, updateData, onChangeData, validation } = useDetail({
    data: {
      ...data, 
      InternalSN: data.InternalSN || (data?.IsProduct ? "" : internal_serial_number), 
      StartDate: data.StartDate && !data.StartDate.startsWith("0001") ? data.StartDate : null,
      EndDate: data.EndDate && !data.EndDate.startsWith("0001") ? data.EndDate : null,
      ExtensionDate: data.ExtensionDate && !data.ExtensionDate.startsWith("0001") ? data.ExtensionDate : null,
      InsId: data?.IsProduct ? data.InsId : null
    },// { ID: 0, internal_serial_number },
    updateUrl: ApiUrl.UpdateEquipmentCard,
    additionalData: data?.IsProduct ? { serviceCallID: data?.service_call_id, IsProduct: data?.IsProduct } : { serviceCallID: null, IsProduct: false },
    //additionalData: { serviceCallID: data?.service_call_id, IsProduct: true }, 
    validationFields: [
      'OwnerName',
      'CodeCom',
      'Country',
      { name: 'email', rules: [{ type: 'email' }] },
      { name: 'OwnerPhone', rules: [
        { type: 'required' },
        //{ type: 'regexp', regexp: /^\d{9}$/, message: 'message.should_be_9_digit_number' }
      ] },
      //{ name: 'OwnerPhone2', rules: [{ type: 'regexp', regexp: /^\d{9}$/, message: 'message.should_be_9_digit_number' }] }
    ]
  });

  const updateSearchValue = (v) => {
    timeOut.current && clearTimeout(timeOut.current);
    timeOut.current = setTimeout(() => { setSearchValue(v); }, 300);
  };

  useEffect(() => {
    if (!data?.IsProduct) {
      if (networkCode === ATLANTICIBERICA) {
        defaultCountry = "ES";
      }
      if (networkCode === YGNISItALIA) {
        defaultCountry = "IT";
      }
      if (networkCode === GAPORTUGAL) {
        defaultCountry = "PT";
      }
      setDataItem({...data, Country: defaultCountry, InternalSN: data.InternalSN || (data?.IsProduct ? "" : internal_serial_number) })
    }

    // if (detailProps?.isPickupEdit) {
    //   setDataItem({...data })
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataItem && !dataItem.InsId) {
      let _internal_serial_number = data?.IsProduct ? dataItem?.InternalSN : internal_serial_number
      Ajax.get({
        url: ApiUrl.GetItemCodesBySerialNumber,
        data: {
          internal_serial_number: _internal_serial_number
          // internal_serial_number
        },
        success: function (response) {
          setExtraItems(response);
        }
      });
    }

    if (dataItem && data?.IsProduct) {
      let _internal_serial_number = dataItem?.InternalSN
      Ajax.get({
        url: ApiUrl.GetItemCodesBySerialNumber,
        data: {
          internal_serial_number: _internal_serial_number
          // internal_serial_number
        },
        success: function (response) {
          setExtraItems(response);
        }
      });
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataItem, dataItem?.InsId, dataItem?.InternalSN]);

  //console.log({dataItem});
  useEffect(() => {
    if (place != null) {
      setDataItem({ 
        ...dataItem, 
        City: place.city, 
        Zip: place.zipCode || "", 
        Street: (place.street || "") + " " + (place.streetNumber || ""),
        StreetNo: place.streetNumber || ""
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [place]);


  const getPlaceObject = (place, isStreet) => {
    const placeObj = {};
    if (place) {
      place.address_components &&
        place.address_components.forEach((address) => {
          if (address.types.indexOf("country") !== -1) {
            Object.assign(placeObj, {
              //countryId: countryIds[address.short_name],
              countryCode: address.short_name,
            });
          }

          if (address.types.indexOf("postal_code") !== -1) {
            Object.assign(placeObj, { zipCode: address.short_name });
          }

          if (address.types.indexOf("locality") !== -1) {
            Object.assign(placeObj, { city: address.long_name });
          }

          if (
            address.types.indexOf("administrative_area_level_3") !== -1 &&
            !placeObj.city
          ) {
            Object.assign(placeObj, { city: address.long_name });
          }

          if (address.types.indexOf("administrative_area_level_1") !== -1) {
            Object.assign(placeObj, {
              state: address.short_name
            });

          }

          if (address.types.indexOf("street_number") !== -1) {
            Object.assign(placeObj, {
              streetNumber: address.short_name
            });
          }

          if (isStreet && address.types.indexOf("route") !== -1) {
            Object.assign(placeObj, {
              street: address.short_name
            });

          }

        });

      Object.assign(placeObj, {
        latitude: place.geometry?.location.lat(),
        longitude: place.geometry?.location.lng(),
      });
    }
    //    debugger;
    setPlace({ ...placeObj });

    return placeObj;
  };

  return dataItem &&
    <view>
      <div className="form_container">
        <div className='section_group'>
          <div className='sections '>
            <InputCont
              label='text.customer_name'
              inputProps={{ name: 'OwnerName', value: dataItem.OwnerName, placeholder: "", onChange: onChangeData, validation }}
            />
            <InputCont
              label='text.email'
              inputProps={{ name: 'OwnerEmail', value: dataItem.OwnerEmail, placeholder: "", onChange: onChangeData, validation }}
            />
            <InputCont
              label='text.country'
              inputProps={{
                type: 'select',
                searchOptions: true,
                valuelist: 'country_codes',
                disableCach: true,
                name: 'Country',
                value: dataItem.Country,
                placeholder: "",
                onChange: (e) => {
                  onChangeData({
                    target: { name: 'Country', value: e }
                  });
                },
                validation
              }}
            />
            {/* <InputCont
                            label='text.zip'
                            inputProps={{ name: 'Zip', value: dataItem.Zip, placeholder: "", onChange: onChangeData }}
                        /> */}
            <cont>
              <Autocomplete
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                onPlaceSelected={(place) => {
                  //console.log({place});
                  getPlaceObject(place, true);
                }}
                name='Zip'
                value={dataItem.Zip}
                inputAutocompleteValue={dataItem.Zip}
                onChange={(e) => {
                  onChangeData(e);
                }}
                options={{
                  // types: ["(regions)"],
                  //types: ["geocode"],
                  // types: ["establishment"],
                  types: ["address"],
                  componentRestrictions: { country: dataItem?.Country || '' },
                }}
                type="text"
                placeholder=""
                //disabled={typeof (dataItem?.Country) == 'undefined'}
              />
              <decor>
                <label>
                  <text>
                    <line></line>
                    <wrap><span><T>text.zip</T></span></wrap>
                  </text>
                  <line></line>
                </label>
                <border></border>
              </decor>
              <icon></icon>
            </cont>
            <cont>
              <Autocomplete
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                onPlaceSelected={(place) => {
                  // console.log(place);
                  getPlaceObject(place, false);
                }}
                name='City'
                value={dataItem.City}
                inputAutocompleteValue={dataItem.City}
                onChange={(e) => {
                  onChangeData(e);
                }}
                options={{
                  //types: ["(regions)"],
                  componentRestrictions: { country: dataItem?.Country || '' },
                }}
                type="text"
                placeholder=""
                //disabled={typeof (dataItem?.Country) == 'undefined'}
              />
              <decor>
                <label>
                  <text>
                    <line></line>
                    <wrap><span><T>text.city</T></span></wrap>
                  </text>
                  <line></line>
                </label>
                <border></border>
              </decor>
              <icon></icon>
            </cont>
            {/* <InputCont
                            label='text.city'
                            inputProps={{ name: 'City', value: dataItem.City, placeholder: "", onChange: onChangeData }}
                        /> */}
            <cont>
              <Autocomplete
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                onPlaceSelected={(place) => {
                  //console.log(place);
                  getPlaceObject(place, true);
                }}
                name='Street'
                value={dataItem.Street}
                inputAutocompleteValue={dataItem.Street}
                onChange={(e) => {
                  onChangeData(e);
                }}
                options={{
                  // types: ["address"],
                  // bounds: { latitude: place?.latitude, longitude: place?.longitude },
                  types: ["geocode"],
                  componentRestrictions: {
                    country: dataItem?.Country || ""
                    //postalCode: dataItem?.Zip || "" 
                  }
                }}
                type="text"
                placeholder=""
                //disabled={typeof (dataItem?.Country) == 'undefined'}
              />
              <decor>
                <label>
                  <text>
                    <line></line>
                    <wrap><span><T>text.street</T></span></wrap>
                  </text>
                  <line></line>
                </label>
                <border></border>
              </decor>
              <icon></icon>
            </cont>
            {/* <InputCont
                            label='text.street'
                            inputProps={{ name: 'Street', value: dataItem.Street, placeholder: "", onChange: onChangeData }}
                        /> */}

            <cont>
              <Autocomplete
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                onPlaceSelected={(place) => {
                  console.log(place);
                  getPlaceObject(place, true);
                }}
                name='StreetNo'
                value={dataItem.StreetNo}
                inputAutocompleteValue={dataItem.StreetNo}
                onChange={(e) => {
                  onChangeData(e);
                }}
                options={{
                  // types: ["address"],
                  // bounds: { latitude: place?.latitude, longitude: place?.longitude },
                  types: ["geocode"],
                  componentRestrictions: {
                    country: dataItem?.Country || ""
                    //postalCode: dataItem?.Zip || "" 
                  }
                }}
                type="text"
                placeholder=""
                disabled={typeof (dataItem?.Country) == 'undefined'}
              />
              <decor>
                <label>
                  <text>
                    <line></line>
                    <wrap><span><T>text.street_number</T></span></wrap>
                  </text>
                  <line></line>
                </label>
                <border></border>
              </decor>
              <icon></icon>
            </cont>
            {/* <InputCont
              label='text.street_number'
              inputProps={{ 
                name: 'StreetNumber', 
                value: dataItem?.StreetNumber, 
                placeholder: "", 
                onChange: onChangeData,
                validation,
                //disabled: typeof (dataItem?.Country) == 'undefined'
              }}
            /> */}
            <InputCont
              label='text.floor_door_number'
              inputProps={{ 
                name: 'Block', 
                value: dataItem?.Block, 
                placeholder: "", 
                onChange: onChangeData, 
                validation,
                //disabled: typeof (dataItem?.Country) == 'undefined'
              }}
            />

          </div>
          <div className='sections'>
          <InputCont
              label='text.phone_1'
              inputProps={{ name: 'OwnerPhone', value: dataItem.OwnerPhone, placeholder: "", onChange: onChangeData, validation }}
            />
            <InputCont
              label='text.phone_2'
              inputProps={{ name: 'OwnerPhone2', value: dataItem.OwnerPhone2, placeholder: "", onChange: onChangeData }}
            />
            <InputCont
              label='text.serial_number'
              inputProps={{ 
                disabled: !data?.IsProduct, 
                // disabled: true, 
                name: 'InternalSN', 
                value: dataItem.InternalSN, 
                placeholder: "", 
                onChange: onChangeData 
              }}
            />
            <InputCont
              label='text.product_code'
              inputProps={{
                type: 'select',
                searchOptions: true,
                valuelist: 'SalesNetworkProducts',
                additionalData: searchValue,
                disableCach: true,
                name: 'CodeCom',
                value: dataItem.CodeCom,
                placeholder: "",
                onSearch: updateSearchValue,
                searchServerSide: true,
                validation,
                extraItems,
                disabled: !!dataItem.InsId && !dataItem.IsProduct,
                //disabled: detailProps?.isPickupEdit,
                onChange: (e) => onChangeData({ target: { name: 'CodeCom', value: e } })
              }}
            />
            <cont>
              <separator horizontal=""></separator>
            </cont>
            <InputCont
              label='text.warranty_start_date'
              inputProps={{
                name: 'StartDate', type: 'date', dataItem, dataField: 'StartDate', format: Defines.Format.MomentDate, placeholder: "", setDataItem
              }}
            />
            <InputCont
              label='text.warranty_end_date'
              inputProps={{
                name: 'EndDate', type: 'date', dataItem, dataField: 'EndDate', format: Defines.Format.MomentDate, placeholder: "", setDataItem
              }}
            />
            <InputCont
              label='text.warranty_extension_date'
              inputProps={{ name: 'ExtensionDate', type: 'date', dataItem, dataField: 'ExtensionDate', format: Defines.Format.MomentDate, placeholder: "", setDataItem }}
            />
          </div>
        </div>
      </div>
      <toolbar>
        <wrap>
          <action right=''>
            <div onClick={(e) => updateData((response) => {
              dispatchCustomEvent(listUpdateEvent);
              data?.IsProduct && window.location.reload();
              onClose();
            })} className='button'><icon>save</icon><T>text.save</T></div>
          </action>
        </wrap>
      </toolbar>
    </view>;


}

export default connect(null, dispatch => ({ openPopup: (props) => dispatch(openPopup(props)) }))(EquipmentCardEdit);

