import React, { useState, useCallback, useRef } from 'react';
import uuidv1 from 'uuid/v1';
// import uuidv1 from 'uuidv1';

import { T } from '../../components/Translations';
import Scroll from '../Scroll/Scroll';
import { useEffect } from 'react';

/**
 * custom tabstrip allowing additional functionality
 * @param {object} props  onChange, skipSecondaryTabs, selectedIndex
 */

export function TabStrip(props) {
    const { onChange, skipSecondaryTabs, selectedIndex } = props;
    const [selected, setSelected] = useState(0);
    const [uuid] = useState(uuidv1());
    const ref = useRef(false);
    const handleSelect = useCallback((e) => {
        if (onChange) {
            onChange(e.currentTarget.value || 0);
        }
        setSelected(e.currentTarget.value || 0);
    }, [onChange]);

    useEffect(() => {
        ref.current = true;
        return () => { ref.current = false; };
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (ref.current) {
            setSelected(selectedIndex || 0);
        }
    }, [selectedIndex]);

    // useEffect(() => {
    //     if (document.getElementById(uuid)) {
    //         new iScroll(document.getElementById(uuid), { scrollX: true, scrollY: false, mouseWheel: true, preventDefault: false, });
    //     }
    // }, []);// eslint-disable-line react-hooks/exhaustive-deps

    let children = ((!skipSecondaryTabs && props.children) || (props.children.filter && props.children.filter(child => child && child.props && child.props.primary)) || props.children).filter((c) => !c?.props?.skip);
    return <>
        <div className="tab_strip">
            <wrap id={uuid}>
                <Scroll>
                    <ul>
                        {
                            React.Children.map(children, (child, index) => <li
                                onClick={handleSelect}
                                value={index}
                                effect="material"
                                className={selected === index ? "selected" : ""}
                            >
                                {child.props.icon && <icon>{child.props.icon}</icon>}

                                {child.props.title && <text><T>{child.props.title}</T></text>}

                            </li>)
                        }
                    </ul>
                </Scroll>
            </wrap>
            <div>
                {
                    React.Children.map(children, (child, index) => {
                        return <div className={selected === index ? "selected" : ""}>
                            {child.props.children}
                        </div>;
                    })
                }

                {/*props.children*/}
            </div>
        </div>
    </>;
}