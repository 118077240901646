import React, { useCallback, useEffect, useRef } from 'react';
import { useParams /*, useNavigate*/ } from 'react-router-dom';
import { message, Spin } from 'antd';
import { AppPages, ApiUrl } from '../project/Defines.js';
import { Project } from '../project/Project.js';
import { Ajax } from '../components/Ajax';
import { connect } from 'react-redux';
import { login, /*logout,*/ clearListStates } from '../store/actions/index.js';
import { T } from '../components/Translations.js';


/**
 * the LoginRedirect Component where user authorization occurs
 * @exports LoginRedirect
 */
function LoginRedirect(props) {
    const redirectUrl = "";
    const { code } = useParams();
    const { history } = props;
    const ref = useRef(false);

    //let navigate = useNavigate();


    useEffect(() => {
        ref.current = true;
        doLogin();
        return () => {
            ref.current = false;
        }

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const doLogin = useCallback(() => { //NOSONAR
        Ajax.post({
            url: ApiUrl.LoginByCode,
            data: {
                code: code,
            },
            success: function (response) {
              if (response && ((response.status && response.status === 200) || response.redirectUrl)) {
                const { userToken, userName, culture, role, enableServiceContract, networkCode, canCreateCall, accessToken, logoutUrl, redirectUrl } = response;
                props.dispatch(clearListStates());
                props.dispatch(login({
                  userToken,
                  userName,
                  culture,
                  role,
                  enableServiceContract,
                  networkCode,
                  userData: {
                    userName: userName,
                    canCreateCall: canCreateCall,
                    accessToken, logoutUrl
                  }
                }));
                if (redirectUrl && redirectUrl !== Project.getPageUrl(AppPages.Login)) {
                  history.push(redirectUrl);
                }
                else {
                  history.push(Project.getPageUrl(AppPages.Home));
                }
            } else {
                if (response) {
                    message.error(response.title || <T>message.error_api</T>)
                } else {
                    message.error(<T>message.error_api</T>)
                }

            }
            },
            error: function (response) {
                if (response) {
                    message.error(response.message || <T>message.error_api</T>)
                } else {
                    message.error(<T>message.error_api</T>)
                }

                if (response.redirectUrl) {
                    window.location.href = response.redirectUrl;
                }

            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, history /*navigate*/, code, redirectUrl]);




    return <><div preloader="" style={{ "margin": "25% auto" }}><Spin size="large" /></div></>;
}

export default connect(state => ({ isLoggedIn: state.isLoggedIn, culture: state.culture }), null)(LoginRedirect);