import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { message } from 'antd';
import { AppPages, ApiUrl } from '../project/Defines.js';
import { Project } from '../project/Project.js';
import { Ajax } from '../components/Ajax';
import { connect } from 'react-redux';
import { login, clearListStates } from '../store/actions/index.js';
import { validate, ValidationInput } from '../Shared/Form/validation.js';
import { T } from '../components/Translations';
import { showLoader, hideLoader } from '../Shared/Loader';
import moment from 'moment';

const validationFields = [
  { name: 'Email', rules: [{ type: 'required', message: 'text.fill_user_name' }] },
  { name: 'Password', rules: [{ type: 'required', message: 'text.fill_password' }] }
];

const loaderName = 'LOGIN_LOADER';
const loaderMaintenance = 'MAINTENANCE_LOADER';

function getParams() {
  return new URLSearchParams(window.location.search);
}

/**
 * the Login Component where user authorization occurs
 * @exports Login
 */
function Login(props) {
  const { isLoggedIn, history, culture } = props;
  const query = getParams();
  const redirectUrl = query.get("redirectUrl") || "";
  const ref = useRef(false);

  //const [state, setState] = useState({
  //    Email: '',
  //    Password: '',
  //    //PasswordShow: false,
  //    Remember: false
  //});
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);

  const [validation, setValidation] = useState({});
  const [allowValidation, setAllowValidation] = useState(true);
  const [windowFocused, setWindowFocused] = useState(false);
  const [loginButtonClicked, setLoginButtonClicked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [dataMaintenance, setDataMaintenance] = useState();

  useEffect(() => {
    // setInProcess(true);
    showLoader(loaderMaintenance);
    Ajax.get({
        url: "/api/common/GetMaintenance",
        data: {},
        success: function (response) {
          // debugger
          if (response) {
            setDataMaintenance(response.Data[0]);
            hideLoader(loaderMaintenance)
             
            }
            // setInProcess(false);
        },
        error: function (response) {
          hideLoader(loaderMaintenance);
        }
    })
  }, []);

  useEffect(() => {
    ref.current = true;
    let f = () => {
      setTimeout(() => {
        if (ref.current) {
          setWindowFocused(true);
        }
      }, 300);
    };
    document.documentElement.addEventListener('click', f);
    //if (props.isLoggedIn && !redirectUrl) {
    //    props.history.push(Project.getPageUrl(AppPages.Home));
    //}
    return () => {
      ref.current = false;
      document.documentElement.removeEventListener('click', f);
    };

  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (allowValidation) {
      setValidation(validate({ data: getValidationData(), validationFields }));
    }
    return () => { };
  }, [/*state,*/ email, password, remember, allowValidation]);//eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isLoggedIn) {
      if (redirectUrl && redirectUrl !== Project.getPageUrl(AppPages.Login)) {
        history.push(redirectUrl);
      }
      else {
        history.push(Project.getPageUrl(AppPages.Home));
      }

    }
    return () => { };
  }, [isLoggedIn, history, redirectUrl]);

  const getValidationData = useCallback(() => {
    return {
      Email: email,
      Password: password,
      //PasswordShow: false,
      Remember: remember
    };
  }, [email, password, remember]);

  const doLogin = useCallback(() => {
    if (!loginButtonClicked) {
      setLoginButtonClicked(true);
      return;
    }
    const validationData = validate({ data: getValidationData(), validationFields });
    setValidation(validationData);
    if (validationData.isValid) {
      showLoader(loaderName);
      Ajax.post({
        url: ApiUrl.Login,
        data: {
          UserName: email, //state.Email,
          Password: password, //state.Password,
          Remember: remember, //state.Remember,
          ReturnUrl: redirectUrl
        },
        success: function (response) {
          hideLoader(loaderName);
          if (response && ((response.status && response.status === 200) || response.redirectUrl)) {
            props.dispatch(clearListStates());
            props.dispatch(login({
              userToken: response.userToken,
              userName: response.userName,
              culture: response.culture,
              role: response.role,
              enableServiceContract: response.enableServiceContract,
              networkCode: response.networkCode,
              userData: {
                userName: response.userName,
                canCreateCall: response.canCreateCall
              },
              dataMaintenance: dataMaintenance
            }));
            if (redirectUrl && redirectUrl !== Project.getPageUrl(AppPages.Login)) {
              history.push(redirectUrl);
            }
            else {
              history.push(Project.getPageUrl(AppPages.Home));
            }
          } else {
            if (response) {
              message.error(response.title || <T>message.error_api</T>);
            } else {
              message.error(<T>message.error_api</T>);
            }
          }
        },
        error: function (response) {
          hideLoader(loaderName);
        }
      });
    } else {
      setAllowValidation(true);
    }

  }, [/*state,*/ email, password, remember, getValidationData, props, loginButtonClicked, history, redirectUrl]);

  useEffect(() => {
    if (windowFocused && loginButtonClicked) {
      doLogin();
    }
  }, [windowFocused, loginButtonClicked]);//eslint-disable-line react-hooks/exhaustive-deps

  const submitForm = useCallback((event) => {
    if (event.key === "Enter") {
      doLogin();
    }
  }, [doLogin]);

  //const setEmail = useCallback((event) => {
  //    const newState = { ...state, Email: event.target.value }
  //    setState(newState);
  //}, [state]);

  //const setPassword = useCallback((event) => {
  //    const newState = { ...state, Password: event.target.value }
  //    setState(newState);
  //}, [state]);

  //const setRemember = useCallback((event) => {
  //    setState({ ...state, Remember: event.target.checked })
  //}, [state]);

  const onShowPassword = useCallback((event) => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const doAction = useCallback((url, openNewWindow) => {
    if (ref.current) {
        Ajax.post({
            url: url,
            data: {},
            success: function (response) {
                if (response && response.redirectUrl) {
                    if (openNewWindow) {
                        window.open(response.redirectUrl, '_blank');
                    } else {
                        window.location.href = response.redirectUrl;
                    }
                } else {
                    // notification.warning({
                    //     description: 'Please check sso configuration',
                    //     placement: 'top',
                    //     duration: 3
                    // });
                }
            }
        })
    }
}, [])

const doConnect = useCallback(() => {
  doAction(ApiUrl.Connect, false);
}, [doAction])

let currentDate = Math.floor(Date.parse(new Date())/1000);
let start_date = dataMaintenance?.disabled_login_start && Math.floor(Date.parse(moment.utc(dataMaintenance?.date_start).local().format())/1000);
let end_date = dataMaintenance?.disabled_login_end && Math.floor(Date.parse(moment.utc(dataMaintenance?.date_end).local().format())/1000);
let _disabled_login_start = dataMaintenance?.disabled_login_start && Math.floor(Date.parse(moment.utc(dataMaintenance?.disabled_login_start).local().format())/1000);
let _disabled_login_end = dataMaintenance?.disabled_login_end && Math.floor(Date.parse(moment.utc(dataMaintenance?.disabled_login_end).local().format())/1000);

console.log(dataMaintenance);
// console.log(currentDate);
// console.log(start_date);
// console.log(end_date);
// console.log(_disabled_login_start);
// console.log(_disabled_login_end);

  if (!props.isLoggedIn) {
    return <view intro="">
        <login>
          <cont>
            { dataMaintenance?.active && start_date < currentDate && currentDate < end_date &&
              <div className="login_message">
                {/* <text className=""><b><T>text.login_maintenance_message</T></b></text> */}
                {culture === 'es' && <text className=""><b>{dataMaintenance?.messages?.es ? dataMaintenance?.messages?.es : dataMaintenance?.messages?.fr}</b></text>}
                {culture === 'pt' && <text className=""><b>{dataMaintenance?.messages?.pt ? dataMaintenance?.messages?.pt : dataMaintenance?.messages?.fr}</b></text>}
                {culture === 'en' && <text className=""><b>{dataMaintenance?.messages?.en ? dataMaintenance?.messages?.en : dataMaintenance?.messages?.fr}</b></text>}
                {culture === 'fr' && <text className=""><b>{dataMaintenance?.messages?.fr}</b></text>}
                {culture === 'it' && <text className=""><b>{dataMaintenance?.messages?.it ? dataMaintenance?.messages?.it : dataMaintenance?.messages?.fr}</b></text>}
              </div>
            }
            <wrap id="container" multiple="multiple">
              <info>
                <text className="info"><b><T>text.sign_in</T></b></text>
                <text className="info"><b><T>text.use_your_account</T></b></text>
              </info>
              <form defaultbutton="[command='login']" autoComplete="off">
                <wrap space="">
                  <div className="input_wrap">
                    <div className="wrap">
                      <ValidationInput
                        validation={validation}
                        name={'Email'}
                        //value={state.Email || ''}
                        value={email || ''}
                        maxLength="50"
                        placeholder=" "
                        type="text"
                        //onChange={setEmail}
                        onChange={(event) => setEmail(event.target.value)}
                        onKeyPress={submitForm}
                        //onBlur={setEmail}
                        onBlur={(event) => setEmail(event.target.value)}
                        disabled={dataMaintenance?.active && _disabled_login_start < currentDate && currentDate < _disabled_login_end && dataMaintenance?.disabled_login}
                      />
                      <decor></decor>
                      <name><T>text.username</T></name>
                    </div>
                    <info><message></message></info>
                  </div>
                  <div className="input_wrap">
                    <div className="wrap">
                      <ValidationInput
                        validation={validation}
                        name='Password'
                        //value={state.Password || ''}
                        value={password || ''}
                        autoCapitalize="off"
                        autoComplete="off"
                        maxLength="50"
                        placeholder=" "
                        type={showPassword ? "text" : "password"}
                        //onChange={setPassword}
                        onChange={(event) => setPassword(event.target.value)}
                        onKeyPress={submitForm}
                        disabled={dataMaintenance?.active && _disabled_login_start < currentDate && currentDate < _disabled_login_end && dataMaintenance?.disabled_login}
                      />
                      <decor></decor>
                      <name><T>text.password</T></name>
                      <div className="button shrink" effect="material" onClick={onShowPassword}><text><T>{showPassword ? "text.hide" : "text.show"} </T></text></div>

                    </div>
                    <info><message></message></info>
                  </div>
                </wrap>
                <toolbar none="">
                  <label className="checkbox" classic="">
                    <input
                      type="checkbox"
                      name="Remember"
                      //onChange={setRemember}
                      onChange={(event) => setRemember(event.target.checked)}
                    /><box><check></check></box><text><T>text.remember_me</T></text></label>
                  <Link className="button link right" to="/"><text><T>text.forgot_password</T></text></Link>
                </toolbar>
                <toolbar none="">
                  <button
                    className="button large right extra accent white"
                    type="button"
                    command="login"
                    onClick={doLogin}
                    disabled={dataMaintenance?.active && _disabled_login_start < currentDate && currentDate < _disabled_login_end && dataMaintenance?.disabled_login}
                  ><text><T>text.log_in</T></text></button>
                </toolbar>
                <div style={{marginTop: 30}}>
                  <info style={{alignItems: "center"}}>
                    <text className="info"><b><center><T>text.login_or</T></center></b></text>
                  </info>
                </div>
                <toolbar none="">
                  <button 
                    className="button large right extra accent white"
                    type="button"
                    command="login"
                    onClick={doConnect}
                    disabled={dataMaintenance?.active && _disabled_login_start < currentDate && currentDate < _disabled_login_end && dataMaintenance?.disabled_login}
                  ><text><T>text.connect</T></text></button>
                </toolbar>
              </form>
            </wrap>
          </cont>
        </login>
      </view>
  } else {
    return <></>;
  }
};

export default connect(state => ({ isLoggedIn: state.isLoggedIn, culture: state.culture }), null)(Login);